import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  keyframes,
  Modal,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
// import { Link } from 'react-router-dom'
import { Suspense, useEffect, useRef, useState } from "react";
import {
  backgroundColor,
  headingColor,
  whiteColor,
  blackColor,
  greyShade66,
  backgroundColor01,
} from "../../configs/styles/muiThemes";
import Sidebar from "./Sidebar";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import ModalCard from "../ModalCard";
import { useDispatch, useSelector } from "react-redux";
import {
  checkExistingUser,
  checkUserLogin,
  clearLoginData,
  getProfileDetails,
  setLoginData,
  updateIsLogin,
} from "redux/authRedux/authSlice";

import {
  getAccessToken,
  getPhoneNumber,
  getProfileId,
  getUserName,
  removeSessionInfo,
} from "utils/authHelpers";
import { getAccountDetails } from "redux/dashboard/dashboardSlice";
import { clearCartData, getCartDetails } from "redux/addToCart/AddToCartSlice";
import {
  addActivityWithLogin,
  addActivityWithOutLogin,
} from "redux/addActivity/AddActivitySlice";
import {
  checkCartOrderProducts,
  clearLocalCartData,
  createCart,
  updateCartDetails,
} from "redux/cart/CartSlice";
import { createCartHelper, updateMergeCartHelper } from "utils/cartHelper";
import {
  activeMsg,
  applyImg,
  applyMsg,
  approvedImg,
  approvedMsg,
  dueImg,
  dueMsg,
  incompleteImg,
  incompleteMsg,
  LATITUDE,
  LONGITUDE,
  navBar_BuyOnlineMsg,
  navBar_MenuCatalog,
  navBar_MenuHome,
  overDueImg,
  overDueMsg,
  rejectedImg,
  rejectedMsg,
} from "configs/Constants";
import { getCatalogAllProducts } from "redux/subCatergory/subCategorySlice";
import {
  addSearchInfoAPI,
  clearSearchData,
  searchProducts,
  setSearchQuery,
} from "redux/searchProduct/SearchProductSlice";
import { getIpAddress } from "services/api_calls/header/HeaderApi";
import {
  allowOnlyEnglish,
  getBrowser,
  getOperatingSystem,
} from "utils/utilities";

import AuthLayoutNew from "pages/Auth/newUi/AuthLayoutNew";
import MobileNumberScreen from "pages/Auth/newUi/MobileNumberScreen";
import PasswordScreen from "pages/Auth/newUi/PasswordScreen";
import ResetPasswordNew from "pages/Auth/newUi/ResetPasswordNew";
import OTPScreen from "pages/Auth/newUi/OTPScreen";
import CreateAccountNew from "pages/Auth/newUi/CreateAccountNew";
import {
  clearRewardPointsData,
  fintechStatusChange,
} from "redux/checkout/CheckoutSlice";
import { clearOwnReviewData } from "redux/reviews/reviewsSlice";
import FinboxStatusCard from "components/FinboxStatusCard";
import {
  checkFinboxUserExists,
  checkFinvervUserExists,
  finboxPartnerPush,
  getFinboxBanner,
  getFinboxCreditLine,
  getFinboxEligibility,
  getFinboxTransactions,
  getFinboxUrl,
  getFinvervApplicationStatus,
  getFinvervLines,
  getFinvervUrl,
  registeFinboxUser,
  registeFinvervUser,
} from "services/api_calls/checkout/checkoutApi";
import LoginAlertCard from "components/LoginAlertCard";
import moment from "moment";
import ModalCard1 from "components/ModalCard1";
import { clearResellerDetails } from "redux/affiliate/AffiliateSlice";
import { navBarBorderColor } from "../../configs/styles/muiThemes";
import TopBar from "./TopBar";
import NavigationBar from "./NavigationBar";
import { clearHeaderDetails } from "redux/header/headerSlice";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import MultiBranchSelectionPopup from "pages/MultiBranchSelection/MultiBranchSelectionPopup";
import { getOverviewDetails } from "redux/overview/overviewSlice";
import {
  getBranchInfoAPI,
  getMainBranchInfoApi,
} from "services/api_calls/multiBranch/multiBranchApi";
import { getActiveBusinessDataApi } from "services/api_calls/overview_api/OverviewApi";
import { navigateTo } from "utils/urlHelper";

const marqueX = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(30%);
  }`;

const MobileNavbar = ({
  value,
  onMenuClick,
  onLoginClick,
  cartCount,
  searchQuery,
  onChangeText,
  onSearch,
  isLoggedIn,
  businessName,
  allProducts,
  commercialDetails,
  isBusinessClosed,
  dealsCouponsDetails,
  businessAffiliateData,
  rellerProfileData,
  hideAppBar,
  isMultiBranch,
  onChangeBranch,
}) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const textRef = useRef(null);
  const [textSize, setTextSize] = useState(0);
  const navigate = useNavigate();
  const [searchVisible, setSearchVisible] = useState(false);
  let businessFullName = sessionStorage.getItem("businessFullName");
  const businessInfo = JSON.parse(sessionStorage.getItem("businessInfo"));
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    // if (window.scrollY > 100) {
    //   setSearchVisible(false)
    // }
  };
  useEffect(() => {
    console.log("testRef", textRef?.current?.offsetWidth);

    setTextSize(textRef?.current?.offsetWidth);
  }, []);

  return (
    !hideAppBar && (
      <>
        <AppBar
          sx={{
            background: "transparent",
            boxShadow: "none !important",
            borderBottom: `1px solid rgba(0,0,0,0.1)`,
          }}
        >
          <Box sx={styles.topBar} display={"flex"} gap={2}>
            <Box
              component="img"
              src={`https://storage.googleapis.com/bodefaults/shopweb/ondc-logo.svg`}
              width={{
                xs: "55px",
                md: "72px",
              }}
              height="auto"
              alt={businessFullName + " CostBo"}
            />
            <Box sx={{ overflow: "hidden", width: "76%" }}>
              <Typography
                ref={textRef}
                component="p"
                textAlign="center"
                color={whiteColor}
                sx={{
                  width: textSize > 0 ? `${textSize}px` : "100%",
                  whiteSpace: "nowrap",
                  fontSize: 12,
                  fontWeight: 500,
                  lineHeight: "15px",
                  p: "6px 5px 6px 0px",
                  animation: `${marqueX} 30s linear infinite`,
                  animationDirection: "reverse",
                  display: "inline-block",
                  // clipPath: 'inset(0 20px)',
                }}
              >
                {navBar_BuyOnlineMsg}
                {commercialDetails?.info?.rewardPointPercent
                  ? ` • ${commercialDetails?.info?.rewardPointPercent}% Cashback on
                    all orders!`
                  : ""}{" "}
                {commercialDetails?.info?.immediateDiscount
                  ? ` • Free Shipping Above ₹${commercialDetails?.info?.immediateDiscount}!`
                  : ""}
                {dealsCouponsDetails?.length > 0
                  ? ` • Coupons ${dealsCouponsDetails?.map(
                      (item) => item.resellerCode
                    )}`
                  : ""}
              </Typography>
            </Box>
          </Box>

          <Box sx={styles.headerContainer()}>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              sx={{ height: "54px", position: "relative" }}
            >
              <Box display="flex" alignItems="center">
                <IconButton
                  sx={{ color: blackColor, p: 0 }}
                  onClick={() => {
                    onMenuClick();
                  }}
                >
                  <MenuRoundedIcon sx={{ fontSize: "22px" }} />
                </IconButton>

                {isMultiBranch && (
                  <Button sx={styles.changeBranchBtn} onClick={onChangeBranch}>
                    Change Branch
                  </Button>
                )}
              </Box>

              {!matches && (
                <Box
                  component="img"
                  src={businessInfo?.[0].logoURL}
                  sx={{
                    width: "40px",
                    height: "40px",
                    backgroundColor: whiteColor,
                    border: `1px solid rgba(0, 0, 0, 0.12)`,
                    borderRadius: "8px",
                    position: "absolute",
                    left: "calc(50% - 20px)",
                    top: "5px",
                  }}
                />
              )}

              <Box display="flex" alignItems="center" gap={2}>
                {value != "home" && (
                  <IconButton
                    sx={{
                      background: whiteColor,
                      height: "24px",
                      weight: "24px",
                      p: "0px",
                      borderRadius: "50%",
                      "&.Mui-disabled": {
                        bgcolor: `${backgroundColor} !important`,
                        border: "none !important",
                      },
                    }}
                    onClick={() => setSearchVisible(!searchVisible)}
                    disabled={allProducts?.productList?.length === 0}
                  >
                    {!searchVisible ? (
                      <Box
                        component="img"
                        src="https://storage.googleapis.com/bodefaults/shopweb/home/search_2.svg"
                        sx={{ width: "24px", height: "24px" }}
                      />
                    ) : (
                      <CloseRoundedIcon
                        sx={{ fontSize: "24px", color: blackColor }}
                      />
                    )}
                  </IconButton>
                )}

                <MobileCart
                  cartCount={cartCount}
                  businessName={businessName}
                  disabled={
                    commercialDetails?.info?.commerceEnable == false ||
                    allProducts?.productList?.length == 0 ||
                    isBusinessClosed
                  }
                />
              </Box>
            </Box>

            {searchVisible || value == "home" ? (
              <Grid xs={12} item mt={0.5} mb={0.5}>
                <Grid xs={12} item>
                  <Box>
                    <SearchBar
                      value={searchQuery}
                      onChangeText={(text) => {
                        onChangeText(text);
                      }}
                      onSearch={onSearch}
                      // disabled={
                      //   commercialDetails?.info?.commerceEnable == false ||
                      //   allProducts?.productList?.length == 0
                      // }
                    />
                  </Box>
                </Grid>
              </Grid>
            ) : null}
          </Box>
        </AppBar>

        <Box
          sx={{
            background: whiteColor,
            zIndex: 4,
            p: "8px 0px",
            width: "100%",

            position: "fixed",
            bottom: 0,
            left: 0,
            boxShadow: "0 -5px 15px 0 rgba(0, 0, 0, 0.1)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "5px",
              px: "18px",
              justifyContent: "space-between",
            }}
          >
            <BottomTab
              title={navBar_MenuHome}
              activeIcon={`https://storage.googleapis.com/bodefaults/shopweb/home/home-fill.svg`}
              icon="https://storage.googleapis.com/bodefaults/shopweb/home/home_2.svg"
              active={value === "home"}
              onClick={() => navigateTo(navigate, ``)}
            />
            <BottomTab
              title={navBar_MenuCatalog}
              activeIcon={`https://storage.googleapis.com/bodefaults/shopweb/home/categories-fill_2.svg`}
              icon="https://storage.googleapis.com/bodefaults/shopweb/home/categories_2.svg"
              active={value === "catalog"}
              onClick={() =>
              {
                if(allProducts?.productList?.length !== 0){
                  navigateTo(navigate, `catalog`)
                }
              }
                
              }
            />
            <BottomTab
              title="Overview"
              activeIcon={`https://storage.googleapis.com/bodefaults/shopweb/home/about-fill_2.svg`}
              icon="https://storage.googleapis.com/bodefaults/shopweb/home/about_2.svg"
              active={value === "overview"}
              onClick={() => navigateTo(navigate, `overview`)}
            />
            {commercialDetails?.info?.costboPowered?.affiliateEnabled &&
              businessAffiliateData?.businessResellers?.length > 0 && (
                <BottomTab
                  title="Share & Earn"
                  activeIcon={`https://storage.googleapis.com/bodefaults/shopweb/home/share-earn-fill.svg`}
                  icon="https://storage.googleapis.com/bodefaults/shopweb/home/share-earn-fill.svg"
                  active={value === "home"}
                  onClick={() =>
                  {
                    if(rellerProfileData?.details){
                      navigateTo(navigate, `affiliateHome`)
                    }else{
                      navigateTo(navigate, `affiliates`)
                    }
                  }
                  }
                />
              )}
            <BottomTab
              title="Profile"
              activeIcon={`https://storage.googleapis.com/bodefaults/shopweb/profile-new.svg`}
              icon="https://storage.googleapis.com/bodefaults/shopweb/home/profile-fill_2.svg"
              active={value === "dashboard"}
              onClick={() => {
                if (isLoggedIn) {
                  navigateTo(navigate, `dashboard`)
                } else {
                  onLoginClick();
                }
              }}
            />
          </Box>
        </Box>
      </>
    )
  );
};

function Navbar(props) {
  const { productId } = useParams();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const branchSelected = searchParams?.get("branchSelected");

  const [query, setQuery] = useState("");

  const { accountDetails } = useSelector((state) => state.dashboard);
  const { cartDetails } = useSelector((state) => state.cart);
  const { allProducts } = useSelector((state) => state.subCategory);
  const { businessId } = useSelector((state) => state.rootSlice);

  const [open, setOpen] = useState(false);
  const [login, setLogin] = useState(false);
  const [emailId, SetEmailId] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [idToken, setIdToken] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [currentPage, setCurrentPage] = useState("mobile-number");
  // const [currentPage, setCurrentPage] = useState('registerDetails')
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const [showSearchBox, setShowSearchBox] = useState(false);

  const [userType, setUserType] = useState("");

  const { loading } = useSelector((state) => state.auth);

  const [totalQty, setTotalQty] = useState(0);
  const { updateQty, localCartData, createCartRes } = useSelector(
    (state) => state.cartupdateadd
  );
  const [commerceInfo, setCommerceInfo] = useState(null);

  const { loginDetails } = useSelector((state) => state.auth);
  const { commercialDetails } = useSelector((state) => state.product);

  const { dealsCouponsDetails } = useSelector((state) => state.coupons);

  const { businessDetails } = useSelector((state) => state.header);
  const [finvervModal, setFinvervModal] = useState({ show: false, url: "" });

  const businessName = sessionStorage.getItem("businessName");
  let businessFullName = sessionStorage.getItem("businessFullName");

  const [isFinvervAvailable, setIsFinvervAvailable] = useState("");
  const [finAvailValue, setFinAvailValue] = useState(null);
  const [finPercentComplete, setFinPercentComplete] = useState(null);
  const [finboxAvlAmt, setFinboxAvlAmt] = useState(null);
  const [loginPopUp, setLoginPopUp] = useState(false);
  const [finboxOverdueDetails, setFinboxOverdueDetails] = useState(null);
  const [finvervModal1, setFinvervModal1] = useState({ show: false, url: "" });

  const [isBusinessClosed, setIsBusinessClosed] = useState(
    commercialDetails?.info?.hyperlocalStore
      ? !commercialDetails?.info?.hyperlocalStore?.storeOpenStatus
      : false
  );

  const { rellerProfileData, businessAffiliateData } = useSelector(
    (state) => state.affiliate
  );

  // Multi branch
  const [isMultiBranch, setIsMultiBranch] = useState(false);
  const [showBranchPopup, setShowBranchPopup] = useState(false);
  const [branchInfo, setBranchInfo] = useState(null);

  const [multiBranchLoader, setMultiBranchLoader] = useState(false);

  const { overviewDetails } = useSelector((state) => state.overview);

  let navigate = useNavigate();

  const [isLoggedIn, setIsLoggedIn] = useState(
    getUserName().length === 0 && getAccessToken() === null ? false : true
  );

  useEffect(() => {
    dispatch(checkUserLogin());
  }, []);

  const getFinboxBannerData = () => {
    getFinboxBanner().then((res) => {
      dispatch(fintechStatusChange(res));
      if (res?.data?.banner) {
        setIsFinvervAvailable(res?.data?.banner);
        if (res?.data?.banner === "ACTIVE") {
          getFinboxCreditLine().then((resp) => {
            if (resp?.data?.availableLimit) {
              setFinboxAvlAmt(resp?.data?.availableLimit);
            }
          });
        }
        if (res?.data?.banner === "OVERDUE") {
          getFinboxTransactions().then((resp) => {
            if (resp?.data?.transactions) {
              const overdueList = resp?.data?.transactions?.filter(
                (item) => item?.txnStatus === "OVERDUE"
              );
              const overdueAmt = overdueList?.reduce(
                (total, cur) => total + cur?.emis?.[0]?.totalPayable,
                0
              );
              let overdueDate = [];
              overdueList?.forEach((element) => {
                overdueDate.push(moment(element?.emis?.[0]?.dueDate));
              });
              const minDate = moment.min(overdueDate);
              console.log(
                "overdueAmt",
                overdueAmt,
                overdueList,
                minDate,
                overdueDate
              );
              setFinboxOverdueDetails({ amt: overdueAmt, date: minDate });
            }
          });
        }
      }
    });
  };

  const getFinvervLinesData = () => {
    getFinvervLines()
      .then((resp) => {
        dispatch(fintechStatusChange(resp));
        if (resp) {
          if (!resp?.line_id || !resp?.line_status) {
            setIsFinvervAvailable("notCompleted");
          }
        }

        if (resp?.line_status === "Under Process" && resp?.line_id) {
          console.log("ssclcmslcmlc");
          setIsFinvervAvailable("notCompleted");
          setFinAvailValue(resp);
        }
        if (
          (resp?.line_status === "Rejected" ||
            resp?.line_status === "Blocked") &&
          resp?.line_id
        ) {
          console.log("ssclcmslcmlc");
          setIsFinvervAvailable("rejected");
          setFinAvailValue(resp);
        }
        if (resp?.line_status === "Approved") {
          setIsFinvervAvailable("balanceAvailable");
          setFinAvailValue(resp);
        }
      })
      .catch(() => setIsFinvervAvailable(""));
  };

  // handle multi-branch change
  const handleChangeBranch = async () => {
    const isMainBranch =
      overviewDetails?.mainBranch && !overviewDetails?.mainBranchId;
    const isSubBranch =
      !overviewDetails?.mainBranch && overviewDetails?.mainBranchId;

    setMultiBranchLoader(true);
    setShowBranchPopup(true);

    const currentBusinessId = businessId?.businessId || "";
    const mainBranchId = overviewDetails?.mainBranchId || "";

    let masterBranchInfo = null;

    if (isSubBranch) {
      const masterBranchRes = await getMainBranchInfoApi(currentBusinessId);

      if (masterBranchRes?.details?.masterBranchId) {
        masterBranchInfo = {
          ...masterBranchRes?.details,
          branchId: masterBranchRes?.details?.masterBranchId || "",
          businessName: masterBranchRes?.details?.masterBranchName || "",
          logo: masterBranchRes?.details?.logoURL || "",
          costBoWebUrl: masterBranchRes?.details?.costboWebURL || "",
          loc: masterBranchRes?.details?.loc || null,
          status: "active",
          // branchId: mainBranchData?.businessId || '',
          // businessName: mainBranchData?.businessName || '',
          // logo: mainBranchData?.logoURL || "",
          // costBoWebUrl: mainBranchData?.costboWebURL || "",
        };
      }
    } else {
      masterBranchInfo = {
        // businessArea: businessDetails?.businessInfo?.[0]?.businessArea || [],
        businessArea: overviewDetails?.businessArea || [],
        address: overviewDetails?.address || "",
        branchId: overviewDetails?.businessId || "",
        businessName: overviewDetails?.businessName || "",
        logo: overviewDetails?.logoURL || "",
        costBoWebUrl: overviewDetails?.costboWebURL || "",
        loc: overviewDetails?.loc || null,
        status: "active",
      };
    }

    const bId = isMainBranch ? currentBusinessId : mainBranchId;
    const branchInfoRes = await getBranchInfoAPI(bId);

    if (branchInfoRes?.details) {
      const branchData = branchInfoRes?.details || null;

      if (masterBranchInfo) {
        const allBranchDetails = {
          ...branchData,
          branchInfo: [masterBranchInfo, ...branchData?.branchInfo],
        };
        setBranchInfo(allBranchDetails || null);
      } else {
        setBranchInfo(branchData || null);
      }
    }

    setMultiBranchLoader(false);
    return;
  };

  // Check if current business is main or sub-branch
  const chechIsMultiBranch = () => {
    const isMainBranch =
      overviewDetails?.mainBranch && !overviewDetails?.mainBranchId;
    const isSubBranch =
      !overviewDetails?.mainBranch && overviewDetails?.mainBranchId;

    if (isMainBranch || isSubBranch) {
      setIsMultiBranch(true);

      const isBranchSelected = sessionStorage.getItem("branchSelected") || "";

      if (branchSelected != "true" && isBranchSelected != "true") {
        handleChangeBranch();
        return;
      } else {
        sessionStorage.setItem("branchSelected", "true");
      }
    } else {
      setIsMultiBranch(false);
      setShowBranchPopup(false);
    }
  };

  useEffect(() => {
    if (props.showLoginForm) {
      if (!login) setLogin(true);
    }
  }, [props.showLoginForm]);

  useEffect(() => {
    if (loginDetails) {
      dispatch(getAccountDetails());
    }
  }, [loginDetails]);

  useEffect(() => {
    if (createCartRes) {
      dispatch(getCartDetails());
    }
  }, [createCartRes]);

  useEffect(() => {
    const finboxRes = JSON.parse(sessionStorage.getItem("finboxStatus"));
    if (finboxRes?.data?.banner) {
      setIsFinvervAvailable(finboxRes?.data?.banner);
      if (finboxRes?.data?.banner === "OVERDUE") {
        getFinboxTransactions().then((resp) => {
          if (resp?.data?.transactions) {
            const overdueList = resp?.data?.transactions?.filter(
              (item) => item?.txnStatus === "OVERDUE"
            );
            const overdueAmt = overdueList?.reduce(
              (total, cur) => total + cur?.emis?.[0]?.totalPayable,
              0
            );
            let overdueDate = [];
            overdueList?.forEach((element) => {
              overdueDate.push(moment(element?.emis?.[0]?.dueDate));
            });
            const minDate = moment.min(overdueDate);
            console.log(
              "overdueAmt",
              overdueAmt,
              overdueList,
              minDate,
              overdueDate
            );
            setFinboxOverdueDetails({ amt: overdueAmt, date: minDate });
          }
        });
      }
    }
    const finbox = sessionStorage.getItem("finboxUser");
    if (finbox === "notRegisteredFinbox") {
      setIsFinvervAvailable("notRegisteredFinbox");
    }
    const finvervRes = JSON.parse(sessionStorage.getItem("finvervStatus"));
    const finverv = sessionStorage.getItem("finvervUser");
    if (finverv === "notRegistered") {
      setIsFinvervAvailable("notRegistered");
    }
    if (finverv === "notCompleted") {
      setIsFinvervAvailable("notCompleted");
    }
    if (finvervRes) {
      if (!finvervRes?.line_id || !finvervRes?.line_status) {
        getFinvervApplicationStatus().then((res) => {
          console.log("fffff", res);
          setFinPercentComplete(res?.data?.percent_complete);
        });
        setIsFinvervAvailable("notCompleted");
      }
    }

    if (finvervRes?.line_status === "Under Process" && finvervRes?.line_id) {
      console.log("ssclcmslcmlc");
      setIsFinvervAvailable("notCompleted");
      setFinAvailValue(finvervRes);
    }
    if (
      (finvervRes?.line_status === "Rejected" ||
        finvervRes?.line_status === "Blocked") &&
      finvervRes?.line_id
    ) {
      console.log("ssclcmslcmlc");
      setIsFinvervAvailable("rejected");
      setFinAvailValue(finvervRes);
    }
    if (finvervRes?.line_status === "Approved") {
      setIsFinvervAvailable("balanceAvailable");
      setFinAvailValue(finvervRes);
    }
  }, []);
  useEffect(() => {
    window.addEventListener("storage", () => {
      const finboxRes = JSON.parse(sessionStorage.getItem("finboxStatus"));
      if (finboxRes?.data?.banner) {
        setIsFinvervAvailable(finboxRes?.data?.banner);
        if (finboxRes?.data?.banner === "OVERDUE") {
          getFinboxTransactions().then((resp) => {
            if (resp?.data?.transactions) {
              const overdueList = resp?.data?.transactions?.filter(
                (item) => item?.txnStatus === "OVERDUE"
              );
              const overdueAmt = overdueList?.reduce(
                (total, cur) => total + cur?.emis?.[0]?.totalPayable,
                0
              );
              let overdueDate = [];
              overdueList?.forEach((element) => {
                overdueDate.push(moment(element?.emis?.[0]?.dueDate));
              });
              const minDate = moment.min(overdueDate);
              console.log(
                "overdueAmt",
                overdueAmt,
                overdueList,
                minDate,
                overdueDate
              );
              setFinboxOverdueDetails({ amt: overdueAmt, date: minDate });
            }
          });
        }
      }
      const finvervRes = JSON.parse(sessionStorage.getItem("finvervStatus"));
      if (finvervRes) {
        if (!finvervRes?.line_id || !finvervRes?.line_status) {
          getFinvervApplicationStatus().then((res) => {
            console.log("fffff", res);
            setFinPercentComplete(res?.data?.percent_complete);
          });
          setIsFinvervAvailable("notCompleted");
        }
      }
      if (finvervRes?.line_status === "Under Process" && finvervRes?.line_id) {
        console.log("ssclcmslcmlc");
        setIsFinvervAvailable("notCompleted");
        setFinAvailValue(finvervRes);
      }
      if (
        (finvervRes?.line_status === "Rejected" ||
          finvervRes?.line_status === "Blocked") &&
        finvervRes?.line_id
      ) {
        console.log("ssclcmslcmlc");
        setIsFinvervAvailable("rejected");
        setFinAvailValue(finvervRes);
      }
      if (finvervRes?.line_status === "Approved") {
        setIsFinvervAvailable("balanceAvailable");
        setFinAvailValue(finvervRes);
      }
    });
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getOverviewDetails());
      dispatch(setLoginData());

      if (!cartDetails && !props.disableCartDetails) {
        console.log("Hit____10003");
        dispatch(getCartDetails(businessId)).then((carRes) => {
          sessionStorage.setItem(
            "cartData",
            JSON.stringify(carRes?.payload?.detail)
          );
        });
      }
    }
  }, [businessId]);

  useEffect(() => {
    if (localCartData) {
      setTotalQty(localCartData?.totalQuantity);
    }
  }, [localCartData]);

  useEffect(() => {
    console.log("Hit____10005", businessId);
    const currentOS = getOperatingSystem(window);
    const currentBrowser = getBrowser(window);
    businessFullName = sessionStorage.getItem("businessFullName");
    const busId = sessionStorage.getItem("businessId");

    // const lat = 12.9634
    // const long = 77.5855

    const lat = localStorage.getItem("lat") || LATITUDE;
    const long = localStorage.getItem("long") || LONGITUDE;

    if (lat && long) {
      if (isLoggedIn) {
        const profileId = getProfileId();
        if (!cartDetails) {
          dispatch(getCartDetails(businessId));
        }

        if (busId && !productId) {
          dispatch(
            addActivityWithLogin({
              profileId: profileId,
              businessId: busId,
              activityName: "viewActiveBusiness",
              browserType: currentBrowser,
              deviceOs: currentOS,
              latitude: lat,
              longitude: long,
              productId: null,
              topicId: null,
              validActivity: 1,
            })
          );
        }
      } else {
        let bid = busId;
        getIpAddress().then((res) => {
          if (bid && !productId) {
            dispatch(
              addActivityWithOutLogin({
                profileId: `WithoutLoginUser${res?.ip}${bid}`,
                businessId: bid,
                activityName: "viewActiveBusiness",
                browserType: currentBrowser,
                deviceOs: currentOS,
                latitude: lat,
                longitude: long,
                productId: null,
                topicId: null,
                validActivity: 1,
              })
            );
          }
        });

        if (!allProducts) {
          dispatch(
            getCatalogAllProducts({
              pageNo: 1,
              discountHightToLow: false,
              priceLowoHig: false,
              highPrice: false,
              business: false,
            })
          );
        }
      }
    }

    if (props.value !== "catalog") {
      dispatch(clearSearchData());
    }
  }, [businessId]);

  useEffect(() => {
    let count = 0;

    const sessionCartData = sessionStorage.getItem("cartData");
    const cartProductData =
      sessionCartData === "undefined" ? null : JSON.parse(sessionCartData);

    if (cartProductData && cartProductData.productList) {
      cartProductData.productList.forEach((item) => {
        count = count + item.currentQuantity;
      });
    }
    setTotalQty(count);
  }, [cartDetails, updateQty]);

  useEffect(() => {
    const commerceData = JSON.parse(sessionStorage.getItem("commerceInfo"));
    if (commerceData) {
      setCommerceInfo(commerceData);
      setIsBusinessClosed(
        commerceData?.info?.hyperlocalStore
          ? !commerceData?.info?.hyperlocalStore?.storeOpenStatus
          : false
      );
    }
  }, [commercialDetails]);

  useEffect(() => {
    if (overviewDetails) {
      chechIsMultiBranch();
    }

    return () => {};
  }, [overviewDetails]);

  const handleSearchQuery = (value) => {
    setQuery(value);
    dispatch(setSearchQuery(value));
  };

  const handleSearch = () => {
    dispatch(
      searchProducts({
        key: query,
        pageNo: 0,
      })
    ).then(() => {
      const currentOS = getOperatingSystem(window);
      // const lat = 12.9634
      // const long = 77.5855

      const lat = localStorage.getItem("lat") || LATITUDE;
      const long = localStorage.getItem("long") || LONGITUDE;

      if (isLoggedIn) {
        const profileId = getProfileId();
        getIpAddress().then((res) => {
          dispatch(
            addSearchInfoAPI({
              profileId: profileId,
              deviceId: "44544646fgy54rthgr",
              ip: res?.ip,
              searchText: query,
              deviceOS: currentOS,
              deviceName: "lap",
              sessionId: "e4454",
              latitude: lat,
              longitude: long,
            })
          );
        });
      } else {
        getIpAddress().then((res) => {
          dispatch(
            addSearchInfoAPI({
              profileId: `WithoutLoginUser${res?.ip}`,
              deviceId: "44544646fgy54rthgr",
              ip: res?.ip,
              searchText: query,
              deviceOS: currentOS,
              deviceName: "lap",
              sessionId: "e4454",
              latitude: lat,
              longitude: long,
            })
          );
        });
      }
    });
    navigateTo(navigate, 'catalog')
    // navigate(`/${businessName}/catalog`);
  };

  const handleChatClick = () => {
    if (!commercialDetails) return;

    const whatsAppNumber =
      commercialDetails?.info?.costboPowered?.whatsAppNumber?.replace("+", "");

    if (whatsAppNumber) {
      window.open(
        // `https://api.whatsapp.com/send/?phone=${whatsAppNumber}&text=Hi+${businessDetails?.businessInfo?.[0]?.businessName}+team,+I+have+a+query+about+the+products+in+your+webstore+${window.location.href}`,
        `https://api.whatsapp.com/send/?phone=${whatsAppNumber}&text=Hi+${businessDetails?.businessInfo?.[0]?.businessName}+team,+I+have+a+query+about+the+products+in+your+webstore`,
        "_blank"
      );
    } else {
      window.fcWidget.open();
      window.fcWidget.show();
    }
  };

  return allProducts && commercialDetails ? (
    <Suspense fallback={<p></p>}>
      <Box sx={{ position: "sticky", top: 0, zIndex: 10 }}>
        <Modal open={open} onClose={() => setOpen(false)} anchor="left">
          <Sidebar
            onCloseClick={() => setOpen(false)}
            isLoggedIn={isLoggedIn}
            onLoginClick={() => {
              setLogin(true);
            }}
            onLogOut={() => {
              setLogin(false);
              setIsLoggedIn(false);
              setIsFinvervAvailable("");
              removeSessionInfo();

              dispatch(clearCartData());
              dispatch(clearRewardPointsData());
              dispatch(clearOwnReviewData());
              dispatch(clearLoginData());
              dispatch(updateIsLogin());
              dispatch(clearResellerDetails());
              navigateTo(navigate, '')
              // navigate(`/${businessName}`);

              // window.location.replace(
              //   `${window.location.origin}/${businessName}`
              // )
              // navigate(0)
            }}
            // onSubCatSelected={props.onSubCatSelected}
            onSubCatSelected={props.onSubCatSelected}
            productCount={allProducts?.productList?.length}
            isBusinessClosed={isBusinessClosed}
          />
        </Modal>

        <ModalCard
          open={login}
          handleClose={() => {
            setLogin(false);
            setCurrentPage("mobile-number");
            setErrorMsg("");
          }}
          width="27%"
        >
          <AuthLayoutNew>
            {currentPage === "mobile-number" ? (
              <MobileNumberScreen
                onContinue={(data) => {
                  setCurrentPage(data.page);
                  setPhoneNo(data.phone);
                }}
                setUserType={(data) => setUserType(data)}
              />
            ) : null}
            {currentPage === "password" ? (
              <PasswordScreen
                mobileNumber={phoneNo}
                onChangeNumber={() => setCurrentPage("mobile-number")}
                onForgotClick={(email) => {
                  setCurrentPage("reset");
                  SetEmailId(email);
                }}
                onLoginSuccess={() => {
                  setIsLoggedIn(true);
                  dispatch(setLoginData());
                  dispatch(updateIsLogin());
                  dispatch(getProfileDetails()).then((response) => {
                    if (response?.payload?.status === 200) {
                      dispatch(getCartDetails(businessId)).then((res) => {
                        if (!res?.payload?.detail) {
                          let localCart = JSON.parse(
                            sessionStorage.getItem("cartData")
                          );
                          if (localCart) {
                            let newCartData = createCartHelper(localCart);
                            dispatch(createCart(newCartData));
                          }
                        } else {
                          let localCart = JSON.parse(
                            sessionStorage.getItem("cartData")
                          );
                          if (!localCart) {
                            sessionStorage.setItem(
                              "cartData",
                              JSON.stringify(res?.payload?.detail)
                            );
                          } else {
                            const updatedCart = updateMergeCartHelper(
                              res?.payload?.detail,
                              localCart
                            );
                            dispatch(updateCartDetails(updatedCart)).then(
                              (upRes) => {
                                if (upRes?.payload?.success) {
                                  dispatch(getCartDetails(businessId)).then(
                                    (carRes) => {
                                      sessionStorage.setItem(
                                        "cartData",
                                        JSON.stringify(carRes?.payload?.detail)
                                      );

                                      dispatch(
                                        checkCartOrderProducts(
                                          carRes?.payload?.detail?.productList
                                        )
                                      );
                                      console.log(
                                        "CART_MERGE___complete",
                                        carRes?.payload?.detail?.productList
                                      );
                                    }
                                  );
                                  console.log("CART_MERGE___complete");
                                  dispatch(clearLocalCartData());
                                }
                              }
                            );
                          }
                        }
                      });
                      if (
                        commercialDetails?.info?.fintech?.active &&
                        commercialDetails?.info?.fintech?.partners?.[0]
                          ?.name === "finbox"
                      ) {
                        const phoneNumber = getPhoneNumber();
                        console.log("Fintech", phoneNumber?.replace("+91", ""));
                        dispatch(
                          checkExistingUser({
                            phone: phoneNumber?.replace("+91", ""),
                          })
                        ).then((response) => {
                          if (response.payload) {
                            if (response.payload.status === 200) {
                              if (response.payload.data.sr === false) {
                                checkFinboxUserExists().then((res) => {
                                  console.log("checkFinboxUserExists", res);
                                  if (res?.exist == false) {
                                    sessionStorage.setItem(
                                      "finboxUser",
                                      "notRegisteredFinbox"
                                    );
                                    console.log("checkFinboxUserExists1", res);
                                    setIsFinvervAvailable(
                                      "notRegisteredFinbox"
                                    );
                                    dispatch(fintechStatusChange(res));
                                  } else {
                                    getFinboxBannerData();
                                  }
                                });
                              }
                            }
                          }
                        });
                      }
                      if (
                        commercialDetails?.info?.fintech?.active &&
                        commercialDetails?.info?.fintech?.partners?.[0]
                          ?.name === "finverv"
                      ) {
                        checkFinvervUserExists().then((res) => {
                          console.log("checkFinvervUserExists", res);
                          if (res?.exist == false) {
                            console.log("checkFinvervUserExists1", res);
                            setIsFinvervAvailable("notRegistered");
                            sessionStorage.setItem(
                              "finvervUser",
                              "notRegistered"
                            );
                            dispatch(fintechStatusChange(res));
                          } else {
                            getFinvervApplicationStatus().then((resp) => {
                              if (resp?.data?.percent_complete == 100) {
                                getFinvervLinesData();
                              } else {
                                setIsFinvervAvailable("notCompleted");
                                sessionStorage.setItem(
                                  "finvervUser",
                                  "notCompleted"
                                );
                                dispatch(fintechStatusChange(res));
                              }
                              // console.log('fffff', res)
                              // setFinPercentComplete(res?.data?.percent_complete)
                            });
                          }
                        });
                      }
                    }
                  });

                  setLogin(false);
                  setCurrentPage("mobile-number");
                  // props.handleNext()
                }}
              />
            ) : null}
            {currentPage === "reset" ? (
              <ResetPasswordNew
                email={emailId}
                phone={phoneNo}
                onForgotpasswordSuccess={() => {
                  dispatch(getProfileDetails());
                  setLogin(false);
                  setIsLoggedIn(true);
                  setCurrentPage("mobile-number");
                  dispatch(setLoginData());
                }}
              />
            ) : null}
            {currentPage === "otp" ? (
              <OTPScreen
                mobileNumber={phoneNo}
                userType={userType}
                onLoginSuccess={() => {
                  setIsLoggedIn(true);
                  dispatch(setLoginData());
                  dispatch(updateIsLogin());
                  dispatch(getProfileDetails()).then((response) => {
                    if (response?.payload?.status === 200) {
                      dispatch(getCartDetails(businessId)).then((res) => {
                        if (!res?.payload?.detail) {
                          let localCart = JSON.parse(
                            sessionStorage.getItem("cartData")
                          );
                          if (localCart) {
                            let newCartData = createCartHelper(localCart);
                            dispatch(createCart(newCartData));
                          }
                        } else {
                          let localCart = JSON.parse(
                            sessionStorage.getItem("cartData")
                          );
                          if (!localCart) {
                            sessionStorage.setItem(
                              "cartData",
                              JSON.stringify(res?.payload?.detail)
                            );
                          } else {
                            const updatedCart = updateMergeCartHelper(
                              res?.payload?.detail,
                              localCart
                            );
                            dispatch(updateCartDetails(updatedCart)).then(
                              (upRes) => {
                                if (upRes?.payload?.success) {
                                  dispatch(getCartDetails(businessId)).then(
                                    (carRes) => {
                                      sessionStorage.setItem(
                                        "cartData",
                                        JSON.stringify(carRes?.payload?.detail)
                                      );
                                    }
                                  );
                                }
                              }
                            );
                          }
                        }
                      });
                    }
                  });
                  setLogin(false);
                  setCurrentPage("mobile-number");
                  // props.handleNext()
                }}
                onChangeNumber={() => setCurrentPage("mobile-number")}
                onCreateAccount={(token) => {
                  setCurrentPage("create-account");
                  setIdToken(token);
                }}
              />
            ) : null}
            {currentPage === "create-account" ? (
              <CreateAccountNew
                phone={phoneNo}
                token={idToken}
                userType={userType}
                onSuccess={() => {
                  dispatch(getProfileDetails()).then((response) => {
                    if (response?.payload?.status === 200) {
                      dispatch(getCartDetails(businessId)).then((res) => {
                        if (!res?.payload?.detail) {
                          let localCart = JSON.parse(
                            sessionStorage.getItem("cartData")
                          );
                          if (localCart) {
                            let newCartData = createCartHelper(localCart);
                            dispatch(createCart(newCartData));
                          }
                        } else {
                          let localCart = JSON.parse(
                            sessionStorage.getItem("cartData")
                          );
                          if (!localCart) {
                            sessionStorage.setItem(
                              "cartData",
                              JSON.stringify(res?.payload?.detail)
                            );
                          } else {
                            const updatedCart = updateMergeCartHelper(
                              res?.payload?.detail,
                              localCart
                            );
                            dispatch(updateCartDetails(updatedCart)).then(
                              (upRes) => {
                                if (upRes?.payload?.success) {
                                  dispatch(getCartDetails(businessId)).then(
                                    (carRes) => {
                                      sessionStorage.setItem(
                                        "cartData",
                                        JSON.stringify(carRes?.payload?.detail)
                                      );
                                    }
                                  );
                                }
                              }
                            );
                          }
                        }
                      });
                    }
                  });
                  dispatch(setLoginData());
                  setLogin(false);
                  setIsLoggedIn(true);
                  setCurrentPage("mobile-number");
                }}
              />
            ) : null}
          </AuthLayoutNew>
        </ModalCard>

        <ModalCard1
          handleClose={() => {
            getFinboxBannerData();
            setFinvervModal({ show: false, url: "" });
          }}
          open={finvervModal.show}
          width="30%"
          height="80vh"
        >
          <Box
            width="100%"
            minHeight="80vh"
            sx={{ borderRadius: "12px", backgroundColor: whiteColor }}
          >
            <iframe
              src={finvervModal.url.slice(0, -5) + "true"}
              style={{
                border: "none",
                borderRadius: "12px",
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                width: "100%",
                height: "100%",
              }}
            />
          </Box>
        </ModalCard1>

        {/* Finverv Modal */}
        <ModalCard1
          handleClose={() => {
            getFinvervLinesData();
            setFinvervModal1({ show: false, url: "" });
          }}
          open={finvervModal1.show}
          width="30%"
          height="80vh"
        >
          <Box
            width="100%"
            minHeight="80vh"
            sx={{ borderRadius: "12px", backgroundColor: whiteColor }}
          >
            <iframe
              src={finvervModal1.url}
              style={{
                border: "none",
                borderRadius: "12px",
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                width: "100%",
                height: "100%",
              }}
            />
          </Box>
        </ModalCard1>

        <ModalCard
          open={loginPopUp}
          handleClose={() => {
            setLoginPopUp(false);
          }}
          width="23%"
        >
          <LoginAlertCard
            handleClose={() => {
              setLoginPopUp(false);
            }}
            onLoginClick={() => {
              setLoginPopUp(false);
              setLogin(true);
            }}
          />
        </ModalCard>

        {/* Multi-Branch selection popup */}

        {branchInfo && (
          <MultiBranchSelectionPopup
            isModelOpen={showBranchPopup}
            setModelOpen={setShowBranchPopup}
            loader={multiBranchLoader}
            branchInfo={branchInfo}
          />
        )}

        {matches ? (
          <AppBar
            sx={{
              boxShadow: 1,
              background: whiteColor,
              boxShadow: "0 1px 8px rgb(87 87 101 / 7%)",
              borderBottom: `1px solid ${navBarBorderColor}`,
            }}
            position="static"
          >
            <Grid container>
              <Grid item xs={12}>
                <TopBar
                  commercialDetails={commercialDetails}
                  dealsCouponsDetails={dealsCouponsDetails}
                  businessFullName={businessFullName}
                  onMenuClick={() => {
                    setOpen(true);
                  }}
                  businessDetails={businessDetails}
                  businessName={businessName}
                  businessAffiliateData={businessAffiliateData}
                  rellerProfileData={rellerProfileData}
                  setLogin={setLogin}
                  followStatus={props?.followStatus}
                  isMultiBranch={isMultiBranch}
                  onChangeBranch={handleChangeBranch}
                />
              </Grid>

              <Container
                sx={{
                  maxWidth: "1350px !important",
                  px: "15px !important",
                }}
              >
                <NavigationBar
                  activeMenu={props.value}
                  logoUrl={props.logoUrl}
                  commercialDetails={commercialDetails}
                  businessName={businessName}
                  businessFullName={businessFullName}
                  showSearchBox={showSearchBox}
                  setShowSearchBox={setShowSearchBox}
                  query={query}
                  handleSearchQuery={handleSearchQuery}
                  onSearch={handleSearch}
                  clearSearch={() => {
                    dispatch(clearSearchData());
                  }}
                  cartCount={totalQty}
                  cartDisabled={
                    commerceInfo?.info?.commerceEnable == false ||
                    allProducts?.productList?.length == 0 ||
                    isBusinessClosed
                  }
                  businessAffiliateData={businessAffiliateData}
                  rellerProfileData={rellerProfileData}
                  //cart info
                  allProducts={allProducts}
                  // profile info
                  loading={loading}
                  isLoggedIn={isLoggedIn}
                  setLogin={setLogin}
                  onLogOut={() => {
                    setLogin(false);
                    setIsLoggedIn(false);
                    setIsFinvervAvailable("");
                    removeSessionInfo();
                    dispatch(clearCartData());
                    dispatch(clearRewardPointsData());
                    dispatch(clearOwnReviewData());
                    dispatch(clearLoginData());
                    dispatch(updateIsLogin());
                    dispatch(clearResellerDetails());
                    dispatch(clearHeaderDetails());
                    navigateTo(navigate, '')
                    

                    // window.location.replace(
                    //   `${window.location.origin}/${businessName}`
                    // )
                    // navigate(0)
                  }}
                />
              </Container>

              <Grid item xs={12}>
                {!isLoggedIn && commercialDetails?.info?.fintech?.active ? (
                  <FinboxStatusCard
                    msg={applyMsg}
                    img={applyImg}
                    btnName="Apply Here"
                    onBtnClick={() => setLoginPopUp(true)}
                  />
                ) : null}
                {isLoggedIn ? (
                  <>
                    {/* Finbox */}
                    {isFinvervAvailable === "APPLY" ? (
                      <FinboxStatusCard
                        msg={applyMsg}
                        img={applyImg}
                        btnName="Apply Here"
                        onBtnClick={() => {
                          getFinboxUrl({
                            redirectURL: window.location.href,
                          }).then((resp) => {
                            console.log("resprespresp", resp);
                            if (resp?.data?.url) {
                              setFinvervModal({
                                show: true,
                                url: resp?.data?.url,
                              });
                            }
                          });
                        }}
                      />
                    ) : null}
                    {isFinvervAvailable === "notRegisteredFinbox" ? (
                      <FinboxStatusCard
                        msg={applyMsg}
                        img={applyImg}
                        btnName="Apply Here"
                        onBtnClick={() => {
                          const phoneNumber = getPhoneNumber();
                          const customerID = getProfileId();
                          const name = accountDetails?.account?.name;
                          const email = accountDetails?.account?.email;
                          console.log(
                            "Fintech",
                            phoneNumber?.replace("+91", "")
                          );
                          registeFinboxUser({
                            customerID,
                            mobile: phoneNumber?.replace("+91", ""),
                          }).then(() => {
                            finboxPartnerPush({ customerID, name, email }).then(
                              () => {
                                getFinboxEligibility().then(() => {
                                  getFinboxUrl({
                                    redirectURL: window.location.href,
                                  }).then((resp) => {
                                    console.log("resprespresp", resp);
                                    if (resp?.data?.url) {
                                      setFinvervModal({
                                        show: true,
                                        url: resp?.data?.url,
                                      });
                                    }
                                  });
                                });
                              }
                            );
                          });
                        }}
                      />
                    ) : null}
                    {isFinvervAvailable === "INCOMPLETE" ? (
                      <FinboxStatusCard
                        msg={incompleteMsg}
                        img={incompleteImg}
                        btnName="Continue here"
                        onBtnClick={() => {
                          getFinboxUrl({
                            redirectURL: window.location.href,
                          }).then((resp) => {
                            console.log("resprespresp", resp);
                            if (resp?.data?.url) {
                              setFinvervModal({
                                show: true,
                                url: resp?.data?.url,
                              });
                            }
                          });
                        }}
                      />
                    ) : null}
                    {isFinvervAvailable === "APPROVED" ? (
                      <FinboxStatusCard
                        msg={approvedMsg}
                        img={approvedImg}
                        btnName="Continue here"
                        onBtnClick={() => {
                          getFinboxUrl({
                            redirectURL: window.location.href,
                          }).then((resp) => {
                            console.log("resprespresp", resp);
                            if (resp?.data?.url) {
                              setFinvervModal({
                                show: true,
                                url: resp?.data?.url,
                              });
                            }
                          });
                        }}
                      />
                    ) : null}
                    {isFinvervAvailable === "REJECTED" ? (
                      <FinboxStatusCard
                        msg={rejectedMsg}
                        img={rejectedImg}
                        btnName="Click here for details"
                        onBtnClick={() => {
                          getFinboxUrl({
                            redirectURL: window.location.href,
                          }).then((resp) => {
                            console.log("resprespresp", resp);
                            if (resp?.data?.url) {
                              setFinvervModal({
                                show: true,
                                url: resp?.data?.url,
                              });
                            }
                          });
                        }}
                      />
                    ) : null}
                    {isFinvervAvailable === "ACTIVE" ? (
                      <FinboxStatusCard
                        msg={activeMsg}
                        img={applyImg}
                        btnName="Click here for details"
                        onBtnClick={() => {
                          getFinboxUrl({
                            redirectURL: window.location.href,
                          }).then((resp) => {
                            console.log("resprespresp", resp);
                            if (resp?.data?.url) {
                              setFinvervModal({
                                show: true,
                                url: resp?.data?.url,
                              });
                            }
                          });
                        }}
                      />
                    ) : null}
                    {isFinvervAvailable === "DUE" ? (
                      <FinboxStatusCard
                        msg={dueMsg}
                        img={dueImg}
                        btnName="Click here for details"
                        onBtnClick={() => {
                          getFinboxUrl({
                            redirectURL: window.location.href,
                          }).then((resp) => {
                            console.log("resprespresp", resp);
                            if (resp?.data?.url) {
                              setFinvervModal({
                                show: true,
                                url: resp?.data?.url,
                              });
                            }
                          });
                        }}
                      />
                    ) : null}
                    {isFinvervAvailable === "OVERDUE" &&
                    finboxOverdueDetails?.amt ? (
                      <FinboxStatusCard
                        msg={`${overDueMsg}. Amount:  ₹${
                          finboxOverdueDetails?.amt
                        }    Due date: ${moment(
                          finboxOverdueDetails?.date
                        ).format("DD-MM-YYYY")}`}
                        img={overDueImg}
                        btnName="Click here for details"
                        onBtnClick={() => {
                          getFinboxUrl({
                            redirectURL: window.location.href,
                          }).then((resp) => {
                            console.log("resprespresp", resp);
                            if (resp?.data?.url) {
                              setFinvervModal({
                                show: true,
                                url: resp?.data?.url,
                              });
                            }
                          });
                        }}
                      />
                    ) : null}
                    {isFinvervAvailable === "notRegistered" ? (
                      <FinboxStatusCard
                        msg={applyMsg}
                        img={applyImg}
                        btnName="Apply Here"
                        onBtnClick={() => {
                          const phoneNumber = getPhoneNumber();
                          console.log(
                            "Fintech",
                            phoneNumber?.replace("+91", "")
                          );
                          registeFinvervUser({
                            mobile: phoneNumber?.replace("+91", ""),
                          }).then(() => {
                            getFinvervUrl().then((resp) => {
                              if (resp?.redirect_url) {
                                setFinvervModal1({
                                  show: true,
                                  url: resp?.redirect_url,
                                });
                              }
                            });
                          });
                        }}
                      />
                    ) : null}
                    {isFinvervAvailable === "notCompleted" ? (
                      <FinboxStatusCard
                        msg={incompleteMsg}
                        img={incompleteImg}
                        btnName="Continue here"
                        onBtnClick={() => {
                          getFinvervUrl().then((resp) => {
                            if (resp?.redirect_url) {
                              setFinvervModal1({
                                show: true,
                                url: resp?.redirect_url,
                              });
                            }
                          });
                        }}
                      />
                    ) : null}
                    {isFinvervAvailable === "balanceAvailable" ? (
                      <FinboxStatusCard
                        msg={activeMsg}
                        img={applyImg}
                        btnName="Click here for details"
                        onBtnClick={() => {
                          getFinvervUrl().then((resp) => {
                            if (resp?.redirect_url) {
                              setFinvervModal1({
                                show: true,
                                url: resp?.redirect_url,
                              });
                            }
                          });
                        }}
                      />
                    ) : null}
                    {isFinvervAvailable === "rejected" ? (
                      <FinboxStatusCard
                        msg={rejectedMsg}
                        img={rejectedImg}
                        btnName="Click here for details"
                        onBtnClick={() => {
                          getFinvervUrl().then((resp) => {
                            if (resp?.redirect_url) {
                              setFinvervModal1({
                                show: true,
                                url: resp?.redirect_url,
                              });
                            }
                          });
                        }}
                      />
                    ) : null}
                  </>
                ) : null}
              </Grid>
            </Grid>
          </AppBar>
        ) : (
          <MobileNavbar
            hideAppBar={props.hideAppBar}
            value={props.value}
            onMenuClick={() => setOpen(true)}
            onLoginClick={() => {
              setLogin(true);
            }}
            searchQuery={query}
            onChangeText={(text) => {
              handleSearchQuery(text);
            }}
            logoUrl={
              props.logoUrl
                ? props.logoUrl
                : JSON.parse(sessionStorage.getItem("businessInfo"))?.[0]
                    ?.logoURL
            }
            cartCount={totalQty}
            businessName={businessName}
            onSearch={handleSearch}
            isLoggedIn={isLoggedIn}
            allProducts={allProducts}
            commercialDetails={commerceInfo}
            isBusinessClosed={isBusinessClosed}
            rewardpoints={props.rewardpoints}
            dealsCouponsDetails={dealsCouponsDetails}
            onLogOut={() => {
              setLogin(false);
              setIsLoggedIn(false);
              setIsFinvervAvailable("");
              removeSessionInfo();
              dispatch(clearCartData());
              dispatch(clearLoginData());
              dispatch(clearRewardPointsData());
              dispatch(clearOwnReviewData());
              dispatch(updateIsLogin());
              dispatch(clearResellerDetails());
              navigateTo(navigate, '')
              // window.location.replace(
              //   `${window.location.origin}/${businessName}`
              // )
              // navigate(0)
            }}
            handleChatClick={handleChatClick}
            setFinvervModal={(data) => setFinvervModal(data)}
            finboxOverdueDetails={finboxOverdueDetails}
            isFinvervAvailable={isFinvervAvailable}
            accountDetails={accountDetails}
            setLoginPopUp={(data) => setLoginPopUp(data)}
            setFinvervModal1={(data) => setFinvervModal1(data)}
            businessAffiliateData={businessAffiliateData}
            rellerProfileData={rellerProfileData}
            isMultiBranch={isMultiBranch}
            onChangeBranch={handleChangeBranch}
          />
        )}
      </Box>
    </Suspense>
  ) : null;
}

const SearchBar = ({ value, onChangeText, onSearch, disabled }) => {
  return (
    <form>
      <Box
        sx={{
          background: backgroundColor01,
          borderRadius: "8px",
          border: {
            xs: `0px solid rgb(232, 232, 232)`,
            md: "1px solid #f0f0f0",
          },
          display: "flex",
          alignItems: "center",
          p: "0 4px 0 12px",
          height: "40px",
          // minWidth: { lg: '450px', xs: '300px' },
          minWidth: { lg: "400px", xs: "300px" },
          mb: { xs: 1, md: 0 },
        }}
      >
        <TextField
          value={value}
          placeholder="Search Products"
          variant="standard"
          sx={{
            flex: 1,
            fontSize: 12,
            fontWeight: 600,
            "& .MuiInputBase-input::placeholder": {
              opacity: 1,
              color: "black", // Change this to your desired color
            },
          }}
          InputProps={{
            disableUnderline: true,
            autoComplete: "off",
          }}
          onChange={({ target }) => {
            const finalValue = allowOnlyEnglish(target.value);
            onChangeText(finalValue);
          }}
          disabled={disabled}
        />
        <IconButton
          onClick={(e) => {
            e.preventDefault();

            onSearch();
          }}
          disabled={disabled}
          type="submit"
          sx={{ width: 30, height: 30 }}
        >
          {/* <SearchIcon sx={{ color: navHeaderColor }} /> */}
          <Box
            component="img"
            sx={{ fontSize: "18px", color: blackColor }}
            src="https://storage.googleapis.com/bodefaults/shopweb/home/search_2.svg"
          />
        </IconButton>
      </Box>
    </form>
  );
};

const MobileCart = ({ cartCount, businessName, disabled }) => {
  const navigate = useNavigate()
  return (
    <IconButton
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "6px",
        background: { md: backgroundColor, xs: headingColor },
        p: "6.5px 9px",
        borderRadius: "15px",
      }}
      // component={Link}
      // to={disabled ? "#" : `/${businessName}/cart`}
      onClick={()=>{
        navigateTo(navigate, 'cart')
      }}
    >
      <Box
        component="img"
        src="https://storage.googleapis.com/bodefaults/shopweb/home/cart.svg"
        sx={{ width: "18px", height: "18px" }}
      />

      {cartCount !== 0 && (
        <Typography
          fontSize={12}
          fontWeight={600}
          color={whiteColor}
          lineHeight="12px"
        >
          {cartCount}
        </Typography>
      )}
    </IconButton>
  );
};

const BottomTab = ({ title, icon, active, activeIcon, onClick }) => {
  return (
    <Stack sx={{ cursor: "pointer" }} onClick={onClick} alignItems="center">
      <Box
        component="img"
        src={active ? activeIcon : icon}
        alt={title}
        sx={{
          width: "22px",
          height: "22px",
          objectFit: "contain",
          color: whiteColor,
        }}
      />
      <Typography variant="subtitle2" sx={styles.bottomTabLabel(active)}>
        {title}
      </Typography>
    </Stack>
  );
};

export default Navbar;

const styles = {
  topBar: {
    position: "relative",
    background: blackColor,
    overflowX: "hidden",
    width: "100%",
    paddingLeft: "7px",
    paddingRight: "15px",
  },

  headerContainer: (width) => ({
    background: { xs: whiteColor, md: "" },
    p: { xs: width ? "0 0 28px 16px" : "5px 15px 2px 15px", md: "0px 16px" },
    // borderRadius: { xs: '0 0 25px 25px', md: 'none' },
    position: "static",
  }),

  bottomTabLabel: (isActive) => ({
    fontSize: "11.5px",
    fontWeight: isActive ? 600 : 500,
    color: isActive ? blackColor : greyShade66,
    mt: "2px",
    textAlign: "center",
  }),

  changeBranchBtn: {
    fontSize: 12,
    fontWeight: 500,
    color: blackColor,
    lineHeight: "10px",
    textDecoration: "underline",
    p: "0 5.5px 2.5px 0",
    textWrap: "nowrap",
    textTransform: "unset",
    ml: 1,
  },
};

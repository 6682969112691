import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAccountDetails,
  getAllCompletedOrderDetails,
  getOrdersCount,
} from "redux/dashboard/dashboardSlice";
import FooterMui from "../components/FooterMui";
import CompletedOrdersContent from "./CompletedOrders/CompletedOrdersContent";
import { useNavigate, useParams } from "react-router-dom";
import { getUserName } from "utils/authHelpers";
import TopComponent from "./TopComponent";
import { Box, Container, useMediaQuery } from "@mui/material";
import { backgroundColor01, whiteColor } from "configs/styles/muiThemes";
import BreadcrumbsSection from "components/Breadcrumbs";
import { getBusinessIdDetails } from "redux/rootSlice/RootSlice";
import { getCommercialDetails } from "redux/productDescription/ProductDescriptionSlice";
import HeaderLoader from "components/skeletonLoaders/HeaderLoader";
import { navigateTo, resolveDomainUrl } from 'utils/urlHelper'

function CompletedOrders({isDomainUrl}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const { businessName } = useParams();

  const dispatch = useDispatch();
  const { accountDetails, completedOrderDetails, loading, ordersCountData } =
    useSelector((state) => state.dashboard);

  const isLoggedIn = getUserName().length === 0 ? false : true;

  let navigate = useNavigate();

  const [pageNo, setPageNo] = useState(1);

  const { businessId } = useSelector((state) => state.rootSlice);
  const { commercialDetails } = useSelector((state) => state.product);

  const getBusinessData = () => {
    const localBusinessName = sessionStorage.getItem("businessName");
    if (businessName !== localBusinessName) {
      sessionStorage.setItem("cartData", "null");
    }

    if (!businessId || businessName !== localBusinessName)
      dispatch(getBusinessIdDetails(businessName));
    sessionStorage.setItem("businessName", businessName);
  };

  useEffect(() => {
    if (isDomainUrl) {
      resolveDomainUrl(dispatch);
      sessionStorage.setItem("businessName", businessName);
      return;
    }
    getBusinessData();
  }, [businessName]);

  useEffect(() => {
    if (businessId) {
      if (isLoggedIn) {
        if (!commercialDetails) {
          dispatch(getCommercialDetails());
        }

        if (accountDetails == null) {
          dispatch(getAccountDetails());
        }
        if (completedOrderDetails == null) {
          dispatch(
            getAllCompletedOrderDetails({
              businessId: businessId?.businessId,
              pageNo: 1,
            })
          );
        }

        dispatch(getOrdersCount(businessId?.businessId));
      } else {
        navigateTo(navigate, '')
      }
    }
  }, [businessId]);

  const handleOrderPagination = (pageNumber) => {
    dispatch(
      getAllCompletedOrderDetails({
        businessId: businessId?.businessId,
        pageNo: pageNumber,
      })
    );
    setPageNo(pageNumber);
  };

  return (
    <Box backgroundColor={{ xs: backgroundColor01 }}>
      {/* <Navbar value='' /> */}

      <Box
        sx={{
          position: "sticky",
          top: { md: 0, xs: 0 },
          width: "100%",
          zIndex: 100,
          backgroundColor: { xs: whiteColor, md: backgroundColor01 },
          pb: 1,
        }}
      >
        <Box minHeight={{ xs: "auto", md: "126.7px" }} bgcolor={whiteColor}>
          {businessId !== null && commercialDetails ? (
            <TopComponent value="dashboard" disableBanners />
          ) : (
            <HeaderLoader hideBanners />
          )}
        </Box>

        {matches && (
          <Container
            sx={{
              maxWidth: "1350px !important",
              mt: { xs: 0, md: "15px" },
              paddingX: "15px !important",
            }}
            backgroundColor={whiteColor}
          >
            <BreadcrumbsSection
              links={[
                { label: "All Orders", to: `/AllOrders` },
                { label: "Completed Orders", to: `/CompletedOrders` },
              ]}
            />
          </Container>
        )}
      </Box>
      <Container
        sx={{
          maxWidth: "1350px !important",
          mt: { xs: commercialDetails ? 0 : "-92px", md: 0 },
          paddingX: { md: "15px !important", xs: 0 },
        }}
      >
        <CompletedOrdersContent
          orderData={completedOrderDetails}
          accountDetails={accountDetails}
          completedOrderDetails={completedOrderDetails}
          loading={loading}
          businessId={businessId}
          ordersCountData={ordersCountData}
          handleOrderPagination={handleOrderPagination}
          pageNo={pageNo}
        />
      </Container>
      {matches && <FooterMui />}
    </Box>
  );
}

export default CompletedOrders;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAccountDetails,
  getAllOpenOrderDetails,
  getAllOrderDetails,
  getCashbackAvailabel,
  getOrdersCount,
} from "redux/dashboard/dashboardSlice";
import FooterMui from "../components/FooterMui";
import AllOrdersContent from "./AllOrders/AllOrdersContent";
import { useNavigate, useParams } from "react-router-dom";
import { getUserName } from "utils/authHelpers";
import TopComponent from "./TopComponent";
import BreadcrumbsSection from "components/Breadcrumbs";
import { Box, Container, useMediaQuery } from "@mui/material";
import {
  backgroundColor,
  backgroundColor01,
  whiteColor,
} from "configs/styles/muiThemes";
import { getBusinessIdDetails } from "redux/rootSlice/RootSlice";
import HeaderLoader from "components/skeletonLoaders/HeaderLoader";
import { getActiveBusiness } from "redux/header/headerSlice";
import { getCommercialDetails } from "redux/productDescription/ProductDescriptionSlice";
import { navigateTo, resolveDomainUrl } from 'utils/urlHelper'

function AllOrders({isDomainUrl}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const { businessName } = useParams();
  const businessIdData = sessionStorage.getItem("businessId");
  const [orderList, setOrderList] = useState([]);

  const dispatch = useDispatch();
  const {
    accountDetails,
    orderDetails,
    openOrderDetails,
    cashbackData,
    loading,
    ordersCountData,
  } = useSelector((state) => state.dashboard);

  const { businessId } = useSelector((state) => state.rootSlice);
  const { commercialDetails } = useSelector((state) => state.product);

  const [isLoggedIn, setIsLoggedIn] = useState(
    getUserName().length === 0 ? false : true
  );
  let navigate = useNavigate();

  const [pageNo, setPageNo] = useState(1);

  const getBusinessData = () => {
    const localBusinessName = sessionStorage.getItem("businessName");
    if (businessName !== localBusinessName) {
      sessionStorage.setItem("cartData", "null");
    }

    if (!businessId || businessName !== localBusinessName)
      dispatch(getBusinessIdDetails(businessName));
    sessionStorage.setItem("businessName", businessName);
  };

  useEffect(() => {
    if (isDomainUrl) {
      resolveDomainUrl(dispatch);
      sessionStorage.setItem("businessName", businessName);
      return;
    }
    getBusinessData();
  }, [businessName]);

  useEffect(() => {
    if (businessId) {
      if (isLoggedIn) {
        if (!commercialDetails) {
          dispatch(getCommercialDetails());
        }

        if (accountDetails == null) {
          dispatch(getAccountDetails());
        }
        if (orderDetails == null) {
          dispatch(
            getAllOrderDetails({
              businessId: businessId?.businessId,
              pageNo: 1,
            })
          );
        }
        // if (openOrderDetails == null) {
        //   dispatch(getAllOpenOrderDetails({ businessId, pageNo: 1 }));
        // }
        if (cashbackData == null) {
          dispatch(getCashbackAvailabel(businessId?.businessId));
        }
        if (ordersCountData == null) {
          dispatch(getOrdersCount(businessId?.businessId));
        }
      } else {
        navigateTo(navigate, '')
      }
    }
  }, [businessId]);

  useEffect(() => {
    // Filter to remove paycart and businesscart orders

    // const orders = orderDetails?.details?.filter((item) => {
    //   return (
    //     item.orderStatus != 'paycartcreated' &&
    //     item.orderStatus != 'businesscartcreated'
    //   )
    // })

    // all cart orders
    const orders = orderDetails?.details;

    if (orders) {
      setOrderList(orders);
    }
  }, [orderDetails]);

  const handleAllOrderPagination = (pageNumber) => {
    dispatch(
      getAllOrderDetails({
        businessId: businessId?.businessId,
        pageNo: pageNumber,
      })
    );
    setPageNo(pageNumber);
  };

  return (
    <Box backgroundColor={{ xs: backgroundColor01 }}>
      {/* <Navbar value='' /> */}
      <Box
        sx={{
          position: "sticky",
          top: 0,
          width: "100%",
          zIndex: 100,
          backgroundColor: { xs: whiteColor, md: backgroundColor01 },
          pb: 1,
        }}
      >
        <Box minHeight={{ xs: "auto", md: "126.7px" }} bgcolor={whiteColor}>
          {businessId !== null && commercialDetails ? (
            <TopComponent value="dashboard" disableBanners />
          ) : (
            <HeaderLoader hideBanners />
          )}
        </Box>

        {matches && (
          <Container
            sx={{
              maxWidth: "1350px !important",
              mt: { xs: 0, md: "15px" },
              paddingX: "15px !important",
            }}
            backgroundColor={whiteColor}
          >
            <BreadcrumbsSection
              links={[{ label: "All Orders", to: `/AllOrders` }]}
            />
          </Container>
        )}
      </Box>

      <Container
        sx={{
          maxWidth: "1350px !important",
          mt: { xs: commercialDetails ? 0 : "-92px", md: 0 },
          paddingX: { md: "15px !important", xs: 0 },
          mb: { md: 0, xs: 7 },
        }}
      >
        <AllOrdersContent
          orderData={orderDetails}
          accountDetails={accountDetails}
          orderDetails={orderList}
          openOrderDetails={openOrderDetails}
          cashbackData={cashbackData}
          loading={loading}
          businessId={businessId}
          ordersCountData={ordersCountData}
          handleAllOrderPagination={handleAllOrderPagination}
          pageNo={pageNo}
        />
      </Container>
      {matches && <FooterMui />}
    </Box>
  );
}

export default AllOrders;

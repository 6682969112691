import { Box, Grid, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import ProductDescriptionSideBar from "./ProductDescriptionSideBar";
import DescriptionSpecificationTabs from "./DescriptionSpecificationTabs";
import SingleProductSidebarShimmer from "components/ShimmerComponents/SingleProductSidebarShimmer";
import ProductDescription from "./ProductDescription";
import {
  extractNameCodePairs,
  extractNames,
  getNameByCode,
  getValueByCode,
  getValueByPath,
} from "./VariantProduct/VariantHelper";

function SingleProductDetail({
  productDetails,
  similarProductDetails,
  commercialDetails,
  recLoading,
  productId,
  cartProductList,
  totalQty,
  productQty,
  cashback,
  isOutOfStock,
  onQtyUpdate,
  onAddProduct,
  onRemoveProduct,
  cashbackData,
  recommendedProducts,
  specification,
  fullSpecification,
  inventoryLoader,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const [ondcSpecData, setOndcSpecData] = useState(null);

  useEffect(() => {
    if ((specification, fullSpecification)) {
      const mapByCode = fullSpecification?.details?.reduce((acc, obj) => {
        acc[obj.code] = obj;
        return acc;
      }, {});

      const array = specification?.details?.[0]?.attributes?.map((obj1) => {
        // Check if there is a matching object in the second array based on code
        if (mapByCode[obj1?.value]) {
          console.log(
            "specificationInputValue",
            productDetails,
            obj1,
            mapByCode[obj1?.value]?.dbObjectName,
            getValueByPath(
              productDetails?.product,
              mapByCode[obj1?.value]?.dbObjectName
            )
          );
          // Merge properties from the second array object to the first array object
          console.log("map", mapByCode[obj1?.value]?.type);
          if (mapByCode[obj1?.value]?.dbObjectName === "list") {
            console.log("hit585");
            if (mapByCode[obj1?.value]?.type === "codeValue") {
              const colorList = extractNameCodePairs(
                mapByCode[obj1?.value]?.values
              );
              const colorCode = getValueByCode(
                productDetails?.product?.ondc?.attributeList,
                mapByCode[obj1?.value]?.code
              );
              const colourName = getNameByCode(colorList, colorCode);
              return {
                ...obj1,
                required: obj1?.code,
                ...mapByCode[obj1?.value],
                inputValue: colourName || "",
                options: extractNames(mapByCode[obj1?.value]?.values),
                nameCodes: extractNameCodePairs(mapByCode[obj1?.value]?.values),
              };
            }
            return {
              ...obj1,
              required: obj1?.code,
              ...mapByCode[obj1?.value],
              inputValue:
                getValueByCode(
                  productDetails?.product?.ondc?.attributeList,
                  mapByCode[obj1?.value]?.code
                ) || "",
              // mapByCode[obj1?.value]?.type === "default"
              //   ? mapByCode[obj1?.value]?.values?.[0]
              //   : "",
            };
          }
          if (mapByCode[obj1?.value]?.type === "codeValue") {
            return {
              ...obj1,
              required: obj1?.code,
              ...mapByCode[obj1?.value],
              inputValue: "",
              options: extractNames(mapByCode[obj1?.value]?.values),
              nameCodes: extractNameCodePairs(mapByCode[obj1?.value]?.values),
            };
          }
          if (mapByCode[obj1?.value]?.type === "radioButton") {
            // const options = mapByCode[obj1?.value]?.values?.map((jsonString) =>
            //   JSON.parse(jsonString)
            // );
            const options = mapByCode[obj1?.value]?.radioOptions;
            const radioOptions = options?.map((option) => {
              return {
                ...option,
                value: getValueByPath(productDetails?.product, option?.key),
              };
            });

            return {
              ...obj1,
              required: obj1?.code,
              ...mapByCode[obj1?.value],
              radioOptions,
            };
          }
          return {
            ...obj1,
            required: obj1?.code,
            ...mapByCode[obj1?.value],
            inputValue:
              getValueByPath(
                productDetails?.product,
                mapByCode[obj1?.value]?.dbObjectName
              ) || "",
          };
        } else {
          return obj1;
        }
      });

      console.log("Ondc_Spec_Data:", array, mapByCode);

      setOndcSpecData(array);
    }
  }, [specification, fullSpecification]);
  return (
    <Box>
      <Grid container direction="row" mt={0} spacing={{ md: 0, xs: 0 }}>
        <Grid item md={9.45} xs={12}>
          <ProductDescription
            productDetails={productDetails}
            similarProductDetails={similarProductDetails}
            commercialDetails={commercialDetails}
            recLoading={recLoading}
            productId={productId}
            cartProductList={cartProductList}
            totalQty={totalQty}
            productQty={productQty}
            cashback={cashbackData}
            isOutOfStock={isOutOfStock}
            inventoryLoader={inventoryLoader}
            onQtyUpdate={onQtyUpdate}
            onAddProduct={onAddProduct}
            onRemoveProduct={onRemoveProduct}
          />
        </Grid>
        {matches && (
          <Grid
            item
            md={2.55}
            xs={12}
            mb={{
              md: 0,
              xs:
                recommendedProducts?.length === 0 ? (totalQty > 0 ? 15 : 7) : 1,
            }}
          >
            {commercialDetails?.info?.rewardPointPercent ||
            commercialDetails?.info?.orderInfoDetails?.[0]?.minOrderAmount ||
            commercialDetails?.info?.immediateDiscount ||
            commercialDetails?.info?.shipmentArea ||
            commercialDetails?.info?.returnPolicy ||
            commercialDetails?.info?.notes ? (
              <ProductDescriptionSideBar
                rewardPointPercent={commercialDetails?.info?.rewardPointPercent}
                minOrderAmount={
                  commercialDetails?.info?.orderInfoDetails?.[0]?.minOrderAmount
                }
                immediateDiscount={commercialDetails?.info?.immediateDiscount}
                shipmentArea={commercialDetails?.info?.shipmentArea}
                shipmentDistance={commercialDetails?.info?.shipmentDistance}
                returnPolicy={commercialDetails?.info?.returnPolicy}
                notes={commercialDetails?.info?.notes}
                cashback={cashbackData}
              />
            ) : (
              <SingleProductSidebarShimmer />
            )}
          </Grid>
        )}
        {productDetails ? (
          <Grid item md={12} xs={12}>
            <Box mt={{ md: 2, xs: 0 }}>
              <DescriptionSpecificationTabs
                // value={value}
                // handleChange={(event, newValue) =>
                //   handleChange(event, newValue)
                // }
                descriptionData={productDetails?.product?.productDescription}
                specificationData={productDetails?.product?.label}
                refLink={productDetails?.product?.refLink}
                ondcData={productDetails?.product?.ondc}
                uom={productDetails?.product?.productDisplayUnitOfMeasure}
                dynamicSpecs={ondcSpecData}
              />
            </Box>
          </Grid>
        ) : null}
        {!matches && (
          <Grid
            item
            md={2.55}
            xs={12}
            mb={{
              md: 0,
              xs:
                recommendedProducts?.length === 0 ? (totalQty > 0 ? 15 : 7) : 1,
            }}
          >
            {commercialDetails?.info?.rewardPointPercent ||
            commercialDetails?.info?.orderInfoDetails?.[0]?.minOrderAmount ||
            commercialDetails?.info?.immediateDiscount ||
            commercialDetails?.info?.shipmentArea ||
            commercialDetails?.info?.returnPolicy ||
            commercialDetails?.info?.notes ? (
              <ProductDescriptionSideBar
                rewardPointPercent={commercialDetails?.info?.rewardPointPercent}
                minOrderAmount={
                  commercialDetails?.info?.orderInfoDetails?.[0]?.minOrderAmount
                }
                immediateDiscount={commercialDetails?.info?.immediateDiscount}
                shipmentArea={commercialDetails?.info?.shipmentArea}
                shipmentDistance={commercialDetails?.info?.shipmentDistance}
                returnPolicy={commercialDetails?.info?.returnPolicy}
                notes={commercialDetails?.info?.notes}
                cashback={cashbackData}
              />
            ) : null}
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default SingleProductDetail;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { getOrderDetails } from "redux/orderDetails/OrderDetailsSlice";
import FooterMui from "../components/FooterMui";
import OrderDetailsContent from "./OrderDetails/OrderDetailsContent";
import TopComponent from "./TopComponent";
import BreadcrumbsSection from "components/Breadcrumbs";
import { Box, Container, useMediaQuery } from "@mui/material";
import {
  backgroundColor,
  backgroundColor01,
  whiteColor,
} from "configs/styles/muiThemes";
import { getBuyerAppLookupAPI } from "services/api_calls/orderdetails_api/OrderDetailsApi";
import { getBusinessIdDetails } from "redux/rootSlice/RootSlice";
import HeaderLoader from "components/skeletonLoaders/HeaderLoader";
import { getCommercialDetails } from "redux/productDescription/ProductDescriptionSlice";

function OrderDetails(props) {
  const { businessName } = useParams();

  const { cartId } = useParams();
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  let { state } = useLocation();
  const cartDate = state?.cartDate;
  const fromPage = state?.fromPage;

  const dispatch = useDispatch();
  const { cartOrderDetails, loading } = useSelector(
    (state) => state.orderdetails
  );

  const { commercialDetails } = useSelector((state) => state.product);

  const [bapNameList, setBapNameList] = useState(null);
  const { businessId } = useSelector((state) => state.rootSlice);
  const businessIdData = sessionStorage.getItem("businessId");
  const getBapDisplayNameInfo = async () => {
    const response = await getBuyerAppLookupAPI();

    if (response?.details?.length > 0) {
      setBapNameList(response?.details || []);
    }
  };
  // Get ONDC Bap Display Name by Subscriber ID
  const getOndcBapNameBySubscriberId = (id) => {
    const data = bapNameList?.find((item) => item?.subscriberId === id);
    return data ? data?.displayName : "";
  };

  const getBusinessData = () => {
    const localBusinessName = sessionStorage.getItem("businessName");
    if (businessName !== localBusinessName) {
      sessionStorage.setItem("cartData", "null");
    }

    if (!businessId || businessName !== localBusinessName)
      dispatch(getBusinessIdDetails(businessName));
    sessionStorage.setItem("businessName", businessName);
  };

  useEffect(() => {
    getBusinessData();
  }, [businessName]);

  useEffect(() => {
    if (businessId) {
      getBapDisplayNameInfo();
    }
  }, [businessId]);

  useEffect(() => {
    if (businessId) {
      if (!commercialDetails) {
        dispatch(getCommercialDetails());
      }

      dispatch(getOrderDetails(cartId));
    }
  }, [businessId]);

  return (
    <Box backgroundColor={backgroundColor01}>
      {/* <Navbar value='' /> */}
      {/* <TopComponent value='' disableBanners /> */}

      <Box
        sx={{
          position: "sticky",
          top: 0,
          width: "100%",
          zIndex: 100,
          backgroundColor: { xs: whiteColor, md: backgroundColor01 },
          pb: 1,
        }}
      >
        <Box minHeight={{ xs: "auto", md: "126.7px" }} bgcolor={whiteColor}>
          {businessId !== null && commercialDetails && cartOrderDetails ? (
            <TopComponent value="dashboard" disableBanners />
          ) : (
            <HeaderLoader hideBanners />
          )}
        </Box>

        {matches && (
          <Container
            sx={{
              maxWidth: "1350px !important",
              mt: { xs: 0, md: "15px" },
              paddingX: "15px !important",
            }}
            backgroundColor={whiteColor}
          >
            <BreadcrumbsSection
              links={[
                { label: fromPage.label, to: `/${fromPage.to}` },
                { label: "Order Details", to: `#` },
              ]}
            />
          </Container>
        )}
      </Box>

      <OrderDetailsContent
        // data={cartData}
        // cartDate={cartDate}
        // orderStatus={cartData?.cart?.orderStatus}
        loading={loading}
        commercialDetails={commercialDetails}
        data={cartOrderDetails}
        cartDate={cartDate}
        orderStatus={cartOrderDetails?.cart?.orderStatus}
        fromPage={fromPage}
        getOndcBapNameBySubscriberId={getOndcBapNameBySubscriberId}
      />
      {matches && cartOrderDetails && <FooterMui />}
    </Box>
  );
}

export default OrderDetails;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCoupons } from "../redux/dealsCoupons/dealsCouponsSlice";
import DiscountNew from "./DealsCoupons/DiscountNew";
import EmptyCard from "../components/EmptyCard";
import { dealsEmptyImgUrl, dealsEmptyMessage } from "../configs/Constants";
import FooterMui from "../components/FooterMui";
import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import TopComponent from "./TopComponent";
import {
  backgroundColor01,
  headingColor,
  whiteColor,
} from "configs/styles/muiThemes";
import Loader from "components/Loader";
import { useParams } from "react-router-dom";
import { getBusinessIdDetails } from "redux/rootSlice/RootSlice";
import { checkCartOrderProducts } from "redux/cart/CartSlice";
import {
  getCategoryDataFinder,
  getDataForSideBar,
} from "redux/subCatergory/subCategorySlice";
import { getCommercialDetails } from "redux/productDescription/ProductDescriptionSlice";
import { getCartDetails } from "redux/addToCart/AddToCartSlice";
import { getUserName } from "utils/authHelpers";
import BreadcrumbsSection from "components/Breadcrumbs";
import HeaderLoader from "components/skeletonLoaders/HeaderLoader";
import { getActiveBusiness } from "redux/header/headerSlice";
import { resolveDomainUrl } from "utils/urlHelper";

function DealsCoupons({isDomainUrl}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const { businessName } = useParams();
  const businessIdData = sessionStorage.getItem("businessId");
  const { commercialDetails } = useSelector((state) => state.product);
  const { allProducts, productsByCategory } = useSelector(
    (state) => state.subCategory
  );
  const { businessDetails } = useSelector((state) => state.header);

  const [isLoggedIn, setIsLoggedIn] = useState(
    getUserName().length === 0 ? false : true
  );
  const { cartDetails } = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const { dealsCouponsDetails, loading } = useSelector(
    (state) => state.coupons
  );
  const { businessId } = useSelector((state) => state.rootSlice);

  const getBusinessData = () => {
    const localBusinessName = sessionStorage.getItem("businessName");
    if (businessName !== localBusinessName) {
      sessionStorage.setItem("cartData", "null");
    }

    if (!businessId || businessName !== localBusinessName)
      dispatch(getBusinessIdDetails(businessName));
    sessionStorage.setItem("businessName", businessName);
  };

  useEffect(() => {
    if (isDomainUrl) {
      resolveDomainUrl(dispatch);
      return;
    }
    getBusinessData();
  }, [businessName]);

  // useEffect(() => {
  //   if (businessIdData !== null) {
  //     dispatch(getDataForSideBar());
  //     dispatch(getCategoryDataFinder());
  //     if (!commercialDetails) {
  //       dispatch(getCommercialDetails());
  //     }
  //     if (isLoggedIn) {
  //       if (!cartDetails) {
  //         dispatch(getCartDetails(businessId));
  //       }
  //     }
  //   }

  //   return () => {
  //     // dispatch(clearSearchData())
  //   };
  // }, [businessId]);

  useEffect(() => {
    if (businessId?.validate) {
      // dispatch(getDataForSideBar());
      // dispatch(getCategoryDataFinder());
      if (!commercialDetails) {
        dispatch(getCommercialDetails());
      }
      dispatch(getActiveBusiness());
      if (!dealsCouponsDetails) {
        console.log("Valid_BusinessID");
        dispatch(getCoupons());
      }
    }
  }, [businessId]);

  return (
    <Box backgroundColor={backgroundColor01}>
      <Box minHeight="126.7px" bgcolor={whiteColor}>
        {businessIdData !== null && commercialDetails !== null ? (
          // &&
          // productsByCategory?.length > 0 &&
          // allProducts !== null
          <TopComponent value={"coupons"} bannerHide disableBanners />
        ) : (
          <>{matches && <HeaderLoader />}</>
        )}
      </Box>

      <Container
        sx={{
          maxWidth: "1360px !important",
          p: "20px 15px 0px 15px !important",
        }}
        backgroundColor={whiteColor}
      >
        <BreadcrumbsSection
          links={[{ label: "Deals/Coupons", to: `/Catalog` }]}
        />
      </Container>

      <DiscountNew
        dealData={dealsCouponsDetails || []}
        dealsCouponsDetails={dealsCouponsDetails}
        loading={loading || !commercialDetails}
      />
      {matches && <FooterMui />}

      {/* {dealsCouponsDetails && (
        <>
          {loading === false &&
            dealsCouponsDetails !== null &&
            dealsCouponsDetails.length > 0 && (
              <>
                <DiscountNew
                  dealData={dealsCouponsDetails}
                  dealsCouponsDetails={dealsCouponsDetails}
                  loading={loading}
                />
                {matches && <FooterMui />}
              </>
            )}
        </>
      )} */}
    </Box>
  );
}
export default DealsCoupons;

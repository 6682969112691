import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import {
  addressForm_AlternateNumber,
  addressForm_AlternateNumberError,
  addressForm_CityNameError,
  addressForm_EnterMail,
  addressForm_EnterName,
  addressForm_EnterNumber,
  addressForm_HouseNo,
  addressForm_HouseNoError,
  addressForm_Landmark,
  addressForm_MailError,
  addressForm_NameError,
  addressForm_NumberError,
  addressForm_Pincode,
  addressForm_PincodeError,
  GOOGLE_MAPS_API_KEY,
} from "configs/Constants";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAddressDetails,
  getCoordinatesDetails,
  modifyAddressDetails,
} from "redux/checkout/CheckoutSlice";
import { validateMobileNumber } from "utils/validationUtils";
import TextboxWithLabel from "../../../components/TextboxWithLabel";
import {
  blackColor,
  greenColor05,
  greyShade76,
  greyShade78,
  greyShade79,
  headingColor,
  lightGreenShade02,
  orangeColor01,
  whiteColor,
} from "../../../configs/styles/muiThemes";
import PickAddressFromMapModal from "components/PickAddress/PickAddressFromMapModal";
import { checkIsUpdateRequired } from "utils/AddressHelper/addressHelper";
import { allowOnlyEnglish } from "utils/utilities";
import ModalCard from "components/ModalCard";
import { ADDRESS_TYPES } from "utils/data";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { fetchAddressFromLatLng } from "utils/geoLocationHelper";
import AddressConfirmAlert from "../Popups/AddressConfirmAlert";
import CheckIcon from "@mui/icons-material/Check";
import RadioButtonUncheckedRoundedIcon from "@mui/icons-material/RadioButtonUncheckedRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

const MarkerIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="#1C1C1C"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    aria-labelledby="icon-svg-title- icon-svg-desc-"
    role="img"
    class="sc-rbbb40-0 haYhpV"
  >
    <title>loction-pin</title>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.50376 0.135614C5.50502 0.734541 3.10602 3.13025 2.60623 6.12488C2.00648 9.81826 4.00564 13.1123 7.10435 14.3102C8.10393 14.7095 9.00355 15.4082 9.50334 16.4064C9.70326 16.7059 9.80322 17.1052 10.0031 17.4047C10.1031 17.1052 10.303 16.7059 10.5029 16.4064C11.0027 15.4082 11.9023 14.8093 12.9019 14.3102C15.6008 13.2122 17.5 10.517 17.5 7.42255C17.5 2.9306 13.3018 -0.762775 8.50376 0.135614ZM10.0031 10.0179C8.60372 10.0179 7.50418 8.91987 7.50418 7.52237C7.50418 6.12488 8.60372 5.02685 10.0031 5.02685C11.4025 5.02685 12.5021 6.12488 12.5021 7.52237C12.5021 8.91987 11.4025 10.0179 10.0031 10.0179Z"
    ></path>
    <path
      fill="#10847e"
      d="M10.0033 20C11.1074 20 12.0025 19.5531 12.0025 19.0018C12.0025 18.4505 11.1074 18.0036 10.0033 18.0036C8.89921 18.0036 8.00415 18.4505 8.00415 19.0018C8.00415 19.5531 8.89921 20 10.0033 20Z"
    ></path>
  </svg>
);

function EditAddressFormNew({
  openPopup,
  title,
  accountDetails,
  addressDetails,
  handleAddAddressClose,
  handleUpdateAddressClose,
  editAddress,
  setSeletedAddress,
  setErrorMessage,
}) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY, // Replace with your API key
    libraries: ["places"],
  });

  const [currentPage, setCurrentPage] = useState("edit-addr");
  const [selectedAddr, setSelectedAddr] = useState(null);

  const [name, setName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [alternateNumber, setAlternateNumber] = useState("");
  const [pincode, setPincode] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [landmark, setLandmark] = useState("");
  const [type, setType] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [selected, setDefault] = useState(false);

  const [latLong, setLatLong] = useState({
    latitude: "",
    longitude: "",
  });
  const [isUpdateRequired, setIsUpdateRequired] = useState(true);
  const [openAlert, setOpenAlert] = useState(false);

  const [isAddressError, setIsAddressError] = useState(false);
  const [isEmailError, setIsEmailError] = useState(false);
  const [isNameError, setIsNameError] = useState(false);
  const [isPincodeError, setIsPincodeError] = useState(false);
  const [isCityError, setIsCityError] = useState(false);
  const [isStateError, setIsStateError] = useState(false);
  const [isTypeError, setIsTypeError] = useState(false);
  const [isAlternateError, setIsAlternateError] = useState(false);

  const [loading, setLoading] = useState(false);

  const { stateCityDetails } = useSelector((state) => state.checkout);

  const [openMap, setOpenMap] = useState({
    show: false,
    userLat: "",
    userLong: "",
  });

  const dispatch = useDispatch();

  //   get address from lat lng
  const getAddressFromLatLng = async ({ center }) => {
    if (!center?.lat || !center?.lng) return;
    const addressInfo = await fetchAddressFromLatLng(center?.lat, center?.lng);
    console.log("handleMapChange___addressInfo", addressInfo);

    setSelectedAddr(addressInfo);
  };

  useEffect(() => {
    if (editAddress) {
      getAddressFromLatLng({
        center: { lat: editAddress?.latitude, lng: editAddress?.longitude },
      });

      setName(editAddress.name);
      setContactNumber(editAddress.phoneNo);
      setAlternateNumber(editAddress.alternatePhoneNo);
      setLandmark(editAddress.landMark);
      setType(editAddress.addressType);
      setAddress(editAddress.addressLine1);
      setCity(editAddress.city);
      setState(editAddress.state);
      setDefault(editAddress.selected);
      setPincode(editAddress.zip);
      setCountry(editAddress?.country || "India");
      setOpenMap((prevState) => ({
        ...prevState,
        userLat: editAddress?.latitude,
        userLong: editAddress?.longitude,
      }));

      setIsUpdateRequired(checkIsUpdateRequired(editAddress?.updatedOn));
    }
  }, [editAddress]);

  const reset = () => {
    setName("");
    setContactNumber("");
    setAlternateNumber("");
    setLandmark("");
    setType("");
    setAddress("");
    setCity("");
    setState("");
    // setEmail('')
    setDefault("");
    setPincode("");
  };

  useEffect(() => {
    setEmail(accountDetails?.account?.email);
    console.log("accountDetails", accountDetails);
  }, [accountDetails]);

  useEffect(() => {
    if (stateCityDetails) {
    }
  }, [stateCityDetails]);

  const editAddressClicked = () => {
    let error = false;

    if (alternateNumber) {
      let alterError = validateMobileNumber(alternateNumber);
      console.log("hiytdf", alterError);
      if (alterError) {
        setIsAlternateError(true);
        error = true;
      }
    }
    if (!address) {
      setIsAddressError(true);
      error = true;
    }
    if (!name) {
      setIsNameError(true);
      error = true;
    }
    if (!email) {
      setIsEmailError(true);
      error = true;
    }
    if (pincode.length !== 6) {
      setIsPincodeError(true);
      error = true;
    }
    if (!city) {
      setIsCityError(true);
      error = true;
    }
    if (!state) {
      setIsStateError(true);
      error = true;
    }
    if (!type) {
      setIsTypeError(true);
      error = true;
    }
    if (error) {
      setLoading(false);
      return;
    }
    let newAddress = [...addressDetails];
    let newObj = null;

    if (
      address === editAddress.addressLine1 &&
      city === editAddress.city &&
      state === editAddress.state &&
      pincode === editAddress.zip
    ) {
      let formattedData = [];

      if (selected === editAddress.selected || !selected) {
        console.log("hiitt__First");
        newAddress.forEach((obj) => {
          if (obj === editAddress) {
            newObj = {
              ...obj,
              name: name,
              addressLine1: address,
              city: city,
              zip: pincode,
              state: state,
              landMark: landmark,
              phoneNo: contactNumber,
              alternatePhoneNo: alternateNumber,
              addressType: type,
              selected: selected,
              updatedOn: moment().valueOf(),
            };
          }
        });
        let filteredAddress = newAddress.filter(
          (address) => address !== editAddress
        );
        formattedData = [...filteredAddress, newObj];
      } else {
        console.log("hittttttt False");
        let newAddressData = [];
        let filteredSelectedAddress = addressDetails?.filter(
          (address) => address.selected === true
        );
        console.log("filteredSelectedAddress", filteredSelectedAddress);
        let addressWithoutSelected = addressDetails?.filter(
          (address) => address.selected !== true
        );
        console.log("addressWithoutSelected", addressWithoutSelected);
        newAddressData = [...addressWithoutSelected];
        if (filteredSelectedAddress.length > 0) {
          let obj = { ...filteredSelectedAddress[0], selected: false };
          newAddressData = [...newAddressData, obj];
        }
        newAddress.forEach((obj) => {
          if (obj === editAddress) {
            newObj = {
              ...obj,
              name: name,
              addressLine1: address,
              city: city,
              zip: pincode,
              state: state,
              landMark: landmark,
              phoneNo: contactNumber,
              alternatePhoneNo: alternateNumber,
              addressType: type,
              selected: selected,
              updatedOn: moment().valueOf(),
            };
          }
        });
        let filteredAddress = newAddressData.filter(
          (address) => address !== editAddress
        );
        formattedData = [...filteredAddress, newObj];
      }

      dispatch(
        modifyAddressDetails({
          addressDetails: formattedData,
        })
      ).then((res) => {
        console.log("deliveryAddress__Edit:", newObj);
        setErrorMessage("");
        setSeletedAddress(newObj);

        handleUpdateAddressClose();
        dispatch(getAddressDetails());
        reset();
        setTimeout(() => {
          setLoading(false);
        }, 3000);
      });
    } else {
      dispatch(
        getCoordinatesDetails({ address: pincode, city, state, pincode })
      ).then((res) => {
        if (res?.payload?.results?.length > 0) {
          let newAddressData = [];
          if (selected === editAddress.selected || !selected) {
            console.log("hittttt true", editAddress);

            newAddress.forEach((obj) => {
              if (obj === editAddress) {
                console.log("hittttt true__obj", obj);
                newObj = {
                  name: name,
                  addressLine1: address,
                  addressLine2: "",
                  addressLine3: "",
                  houseNo: "",
                  city: city,
                  zip: pincode,
                  state: state,
                  country: "India",
                  landMark: landmark,
                  phoneNo: contactNumber,
                  alternatePhoneNo: alternateNumber,
                  addressType: type,
                  // latitude: res?.payload?.results?.[0]?.geometry?.location?.lat,
                  // longitude: res?.payload?.results?.[0]?.geometry?.location?.lng,
                  latitude:
                    latLong.latitude ||
                    res?.payload?.results?.[0]?.geometry?.location?.lat,
                  longitude:
                    latLong.longitude ||
                    res?.payload?.results?.[0]?.geometry?.location?.lng,
                  selected: selected,
                  addedOn:
                    obj?.addedOn || moment().format("YYYY-MM-DDTHH:MM:SS"),
                  updatedOn: moment().valueOf(),
                };
              }
            });
            let filteredAddress = newAddress.filter(
              (address) => address !== editAddress
            );
            newAddressData = [...filteredAddress, newObj];
          } else {
            console.log("hittttt false", editAddress);
            let filteredSelectedAddress = addressDetails?.filter(
              (address) => address.selected === true
            );
            let addressWithoutSelected = addressDetails?.filter(
              (address) => address.selected !== true
            );
            newAddressData = [...addressWithoutSelected];
            if (filteredSelectedAddress.length > 0) {
              let obj = { ...filteredSelectedAddress[0], selected: false };
              newAddressData = [...newAddressData, obj];
            }
            newAddress.forEach((obj) => {
              if (obj === editAddress) {
                console.log("hittttt false__obj", obj);
                newObj = {
                  name: name,
                  addressLine1: address,
                  addressLine2: "",
                  addressLine3: "",
                  houseNo: "",
                  city: city,
                  zip: pincode,
                  state: state,
                  country: "India",
                  landMark: landmark,
                  phoneNo: contactNumber,
                  alternatePhoneNo: alternateNumber,
                  addressType: type,
                  latitude:
                    latLong.latitude ||
                    res?.payload?.results?.[0]?.geometry?.location?.lat,
                  longitude:
                    latLong.longitude ||
                    res?.payload?.results?.[0]?.geometry?.location?.lng,
                  selected: selected,
                  addedOn:
                    obj?.addedOn || moment().format("YYYY-MM-DDTHH:MM:SS"),
                  updatedOn: moment().valueOf(),
                };
              }
            });
            let filteredAddress = newAddressData.filter(
              (address) => address !== editAddress
            );
            newAddressData = [...filteredAddress, newObj];
            console.log("newAddress", newAddress);
          }

          dispatch(
            modifyAddressDetails({
              addressDetails: newAddressData,
            })
          ).then((res) => {
            console.log("deliveryAddress__Edit:::", newObj);
            setSeletedAddress(newObj);
            handleUpdateAddressClose();
            dispatch(getAddressDetails());
            reset();
            setTimeout(() => {
              setLoading(false);
            }, 3000);
          });
        } else {
          setIsPincodeError(true);
          setLoading(false);
          console.log("address line havind no coordinates");
          return;
        }
      });
    }
  };

  const validateAddress = () => {
    let error = false;

    if (alternateNumber) {
      let alterError = validateMobileNumber(alternateNumber);

      if (alterError) {
        setIsAlternateError(true);
        error = true;
      }
    }
    if (!address) {
      setIsAddressError(true);
      error = true;
    }
    if (!name) {
      setIsNameError(true);
      error = true;
    }
    if (!email) {
      setIsEmailError(true);
      error = true;
    }
    if (pincode.length !== 6) {
      setIsPincodeError(true);
      error = true;
    }
    if (!city) {
      setIsCityError(true);
      error = true;
    }
    if (!state) {
      setIsStateError(true);
      error = true;
    }
    if (!type) {
      setIsTypeError(true);
      error = true;
    }
    if (error) {
      setLoading(false);
      return;
    }

    setOpenAlert(true);
  };

  const updateAddress = () => {
    console.log("Update_Address__addressDetails:", addressDetails);

    const addressList = addressDetails?.filter((item) => item);

    console.log("Update_Address__addressList:", addressList);
    console.log("Update_Address__editAddress:", editAddress);

    const newAddress = {
      ...editAddress,
      name: name,
      addressLine1: address?.trim(),
      city: city,
      zip: pincode,
      state: state,
      landMark: landmark,
      phoneNo: contactNumber,
      alternatePhoneNo: alternateNumber,
      addressType: type,
      latitude: latLong.latitude || editAddress?.latitude,
      longitude: latLong.longitude || editAddress?.longitude,
      selected: selected,
      updatedOn: moment().valueOf(),
      addedOn: editAddress?.addedOn || moment().format("YYYY-MM-DDTHH:MM:SS"),
    };

    const updatedAddList = addressList?.map((item, index) => {
      if (
        item?.addressLine1 === editAddress?.addressLine1 &&
        item?.name === editAddress?.name &&
        item?.latitude === editAddress?.latitude &&
        item?.longitude === editAddress?.longitude &&
        item?.zip === editAddress?.zip
      ) {
        console.log("Update_Address__HITT:" + index, {
          ...item,
          ...newAddress,
        });
        return {
          ...item,
          ...newAddress,
        };
      } else {
        return { ...item, selected: selected ? false : item?.selected };
      }
    });

    console.log("Update_Address__updatedAddList:", updatedAddList);

    dispatch(
      modifyAddressDetails({
        addressDetails: updatedAddList,
      })
    ).then((res) => {
      setSeletedAddress(() => newAddress);

      handleUpdateAddressClose();
      dispatch(getAddressDetails());
      reset();
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    });
  };

  // autoFill user selected address from map
  const handlePickLocationConfirm = (userAddress) => {
    if (userAddress) {
      console.log("userAddress:", userAddress);
      setCity((prevState) => allowOnlyEnglish(userAddress?.city));
      setPincode((prevState) => allowOnlyEnglish(userAddress?.postalCode));
      setState((prevState) => allowOnlyEnglish(userAddress?.state));
      setAddress("");
      setAddress((prevState) => userAddress?.address?.trim() || prevState);
      setLandmark((prevState) => allowOnlyEnglish(userAddress?.locality));
      setCountry(userAddress?.country || "India");
      setSelectedAddr((prevState) => userAddress);

      setLatLong((prevState) => ({
        latitude: userAddress?.lat,
        longitude: userAddress?.lng,
      }));
    }

    setOpenMap({
      show: false,
      userLat: userAddress?.lat,
      userLong: userAddress?.lng,
    });

    setCurrentPage("add-address");
    setIsUpdateRequired(false);
  };

  const onChooseLocation = () => {
    setOpenMap((prevState) => ({
      ...prevState,
      show: true,
      userLat: editAddress?.latitude,
      userLong: editAddress?.longitude,
    }));
  };

  return (
    isLoaded &&
    (currentPage === "map-view" ? (
      <>
        (
        <PickAddressFromMapModal
          isOpen={openPopup}
          setIsOpen={handleUpdateAddressClose}
          onConfirm={handlePickLocationConfirm}
          userLat={openMap.userLat || null}
          userLong={openMap.userLong || null}
        />
        )
      </>
    ) : (
      <ModalCard
        handleClose={handleUpdateAddressClose}
        open={openPopup}
        width="65%"
        mobileWidth="100%"
        closeBtnStyles={{
          bgcolor: whiteColor,
          p: 0.3,
          top: { xs: 10, md: 5 },
        }}
      >
        <Box sx={styles.modelCardContainer}>
          <Box
            py={{ xs: 0.3, md: 0 }}
            sx={{
              height: { xs: "95vh", md: "87vh" },
              overflowY: "auto",
              background: whiteColor,
              borderRadius: { xs: "10px 10px 0 0", md: "10px" },
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    width: "100%",
                    height: { xs: "30vh", md: "87vh" },
                    position: "relative",
                  }}
                >
                  <SelecteAddressInfo
                    fullAddress={selectedAddr?.fullAddress}
                    onChange={() => {
                      setCurrentPage("map-view");
                    }}
                  />

                  <GoogleMap
                    center={{ lat: openMap?.userLat, lng: openMap?.userLong }}
                    zoom={20}
                    mapContainerStyle={{ width: "100%", height: "100%" }}
                    options={{
                      mapTypeControl: false,
                      fullscreenControl: false,
                      streetViewControl: false,
                      zoomControl: false,
                      draggable: false,
                    }}
                  >
                    <Marker
                      position={{
                        lat: openMap?.userLat,
                        lng: openMap?.userLong,
                      }}
                    />
                  </GoogleMap>
                </Box>
              </Grid>

              <Grid item xs={12} md={6}>
                <Box
                  // p={{ xs: "40px 20px 15px 12px", md: "10px 20px 15px 12px" }}
                  sx={{
                    height: { xs: "55vh", md: "87vh" },
                    overflowY: "scroll",
                    scrollbarWidth: "none",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                  }}
                >
                  <Box
                    sx={{
                      bgcolor: whiteColor,
                      position: "sticky",
                      top: 0,
                      p: { xs: "40px 20px 0px 12px", md: "10px 20px 0px 12px" },
                      zIndex: 10,
                    }}
                  >
                    <Typography
                      fontSize={{ xs: 14, md: 15 }}
                      fontWeight={600}
                      lineHeight="24px"
                      letterSpacing="-0.3px"
                      color={headingColor}
                      p={{
                        xs: "4px 0px 3px !important",
                        md: "4px 0px 5px !important",
                      }}
                    >
                      {title}
                    </Typography>
                  </Box>

                  <Box
                    p={{ xs: "0px 20px 15px 12px", md: "0px 20px 15px 12px" }}
                  >
                    <Box>
                      <Grid
                        container
                        rowSpacing={{ md: 0, xs: 0 }}
                        columnSpacing={{ md: 1, xs: 0 }}
                      >
                        <Grid item xs={12} md={12}>
                          <TextboxWithLabel
                            label={addressForm_HouseNo}
                            alertText=" (Edit the house/flat number if needed)"
                            placeholder=""
                            value={address}
                            onValueChange={(text) => {
                              if (isAddressError) {
                                setIsAddressError(false);
                              }
                              setAddress(text);
                            }}
                            required={true}
                            isError={isAddressError}
                            errorMsg={addressForm_HouseNoError}
                          />
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <TextboxWithLabel
                            label="Landmark"
                            placeholder={addressForm_Landmark}
                            value={landmark}
                            onValueChange={(text) => setLandmark(text)}
                          />
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "flex-start",
                              gap: "9px",
                              bgcolor: lightGreenShade02,
                              p: "10px 8px",
                              borderRadius: "6px",
                              m: "6px 0 8px",
                            }}
                          >
                            <Box
                              width={{ xs: "19px", md: "21px" }}
                              height={{ xs: "19px", md: "21px" }}
                              mt={-0.1}
                            >
                              <MarkerIcon />
                            </Box>

                            <Typography
                              fontSize={{ xs: 11.5, md: 12.5 }}
                              fontWeight={600}
                              color={greenColor05}
                              lineHeight="18px"
                              letterSpacing="0px"
                            >
                              {city}, {state} - {pincode}, {country}
                            </Typography>
                          </Box>
                        </Grid>

                        {/* <Grid item xs={12} md={12}>
                          <TextboxWithLabel
                            label="Pincode"
                            value={pincode}
                            onValueChange={(text) => {
                              // if (text.length === 6) {
                              //   dispatch(getStateCityDetails(text)).then((res) => {
                              //     if (text.length === 6) {
                              //       const filterState =
                              //         res?.payload?.results?.[0]?.address_components?.filter(
                              //           (item) =>
                              //             item?.types?.includes(
                              //               "administrative_area_level_1"
                              //             )
                              //         );
                              //       const filterCity1 =
                              //         res?.payload?.results?.[0]?.address_components?.filter(
                              //           (item) =>
                              //             item?.types?.includes(
                              //               "administrative_area_level_2"
                              //             )
                              //         );
                              //       const filterCity2 =
                              //         res?.payload?.results?.[0]?.address_components?.filter(
                              //           (item) =>
                              //             item?.types?.includes(
                              //               "administrative_area_level_3"
                              //             )
                              //         );
                              //       const filterCity3 =
                              //         res?.payload?.results?.[0]?.address_components?.filter(
                              //           (item) => item?.types?.includes("locality")
                              //         );
                              //       setCity(
                              //         filterCity1?.length > 0
                              //           ? filterCity1?.[0]?.long_name
                              //           : filterCity2?.length > 0
                              //           ? filterCity2?.[0]?.long_name
                              //           : filterCity3?.length > 0
                              //           ? filterCity3?.[0]?.long_name
                              //           : ""
                              //       );
                              //       console.log(
                              //         "filterCity",
                              //         filterCity1,
                              //         filterCity2,
                              //         filterCity3
                              //       );
                              //       console.log("filterState", filterState);
                              //       setState(
                              //         filterState?.length > 0
                              //           ? filterState?.[0]?.long_name
                              //           : ""
                              //       );
                              //       setOpenMap((prevState) => ({
                              //         show: true,
                              //         userLat:
                              //           res?.payload?.results?.[0]?.geometry
                              //             ?.location?.lat || "",
                              //         userLong:
                              //           res?.payload?.results?.[0]?.geometry
                              //             ?.location?.lng || "",
                              //       }));
                              //     }
                              //     // if (text?.length === 6)
                              //     //   setState(
                              //     //     res?.payload?.results?.[0]?.address_components?.[
                              //     //       res?.payload?.results?.[0]?.address_components
                              //     //         ?.length - 2
                              //     //     ]?.long_name
                              //     //   )
                              //     // setCity(
                              //     //   res?.payload?.results?.[0]?.address_components?.[
                              //     //     res?.payload?.results?.[0]?.address_components?.length -
                              //     //       3
                              //     //   ]?.long_name
                              //     // )
                              //     setIsStateError(false);
                              //     setIsCityError(false);
                              //   });
                              //   setIsPincodeError(false);
                              // }
                              // setPincode(text);
                            }}
                            maxLength={6}
                            placeholder={addressForm_Pincode}
                            required={true}
                            disabled
                            isError={isPincodeError}
                            errorMsg={addressForm_PincodeError}
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <TextboxWithLabel
                            label="City"
                            placeholder=""
                            required={true}
                            value={city}
                            onValueChange={(text) => {
                              // setIsCityError(false);
                              // setCity(text);
                            }}
                            disabled
                            isError={isCityError}
                            errorMsg={addressForm_CityNameError}
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <TextboxWithLabel
                            label="State"
                            placeholder=""
                            required={true}
                            value={state}
                            onValueChange={(text) => {
                              // setIsStateError(false);
                              // setState(text);
                            }}
                            disabled
                            isError={isStateError}
                            errorMsg={"Enter City Name"}
                          />
                        </Grid> */}
                      </Grid>
                    </Box>

                    <Box
                      pt={{ xs: 0, md: 1 }}
                      mt={0.5}
                      borderTop={{
                        xs: "none",
                        md: `1px solid rgba(0,0,0,0.1)`,
                      }}
                    >
                      <Typography
                        fontSize={14}
                        fontWeight={600}
                        color={blackColor}
                        lineHeight="24px"
                        letterSpacing="-0.3px"
                        pb={0.5}
                      >
                        Contact Info
                      </Typography>

                      <Grid
                        container
                        rowSpacing={{ md: 0.2, xs: 0.5 }}
                        columnSpacing={{ md: 1, xs: 0.5 }}
                      >
                        <Grid item xs={12} md={12}>
                          <TextboxWithLabel
                            label="Name"
                            value={name}
                            onValueChange={(text) => {
                              if (isNameError) {
                                setIsNameError(false);
                              }
                              setName(text);
                            }}
                            placeholder={addressForm_EnterName}
                            required={true}
                            isError={isNameError}
                            errorMsg={addressForm_NameError}
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <TextboxWithLabel
                            label={addressForm_EnterNumber}
                            value={contactNumber?.replace("+91", "+91  ")}
                            placeholder={addressForm_NumberError}
                            required={true}
                            disabled
                          />
                        </Grid>

                        <Grid item xs={12} md={6}>
                          <TextboxWithLabel
                            label="Email ID"
                            value={email}
                            placeholder={addressForm_EnterMail}
                            required={true}
                            disabled
                            isError={isEmailError}
                            errorMsg={addressForm_MailError}
                          />
                        </Grid>

                        <Grid item xs={12} md={12}>
                          <TextboxWithLabel
                            label={addressForm_AlternateNumber}
                            value={alternateNumber}
                            infoText="(Please enter, if you are booking the order on behalf of someone else.)"
                            maxLength={10}
                            onValueChange={(text) => {
                              if (isAlternateError) {
                                setIsAlternateError(false);
                              }
                              const value = text
                                .replace(/[^0-9.]/g, "")
                                .replace(/(\..*)\./g, "$1");
                              setAlternateNumber(value);
                            }}
                            placeholder={addressForm_AlternateNumberError}
                            isError={isAlternateError}
                            errorMsg={"Please enter a valid Mobile number"}
                            startIcon={
                              <Typography
                                fontSize={13}
                                fontWeight={400}
                                color={blackColor}
                                sx={{ mt: -0.1 }}
                              >
                                +91
                              </Typography>
                            }
                          />
                        </Grid>
                      </Grid>

                      <Box
                        display="flex"
                        flexDirection={{ xs: "column", md: "row" }}
                        alignItems={{ xs: "flex-start", md: "flex-end" }}
                        justifyContent="space-between"
                      >
                        <Box>
                          <Typography sx={styles.label}>
                            Save Address As{" "}
                            <Typography
                              component="span"
                              variant="body1"
                              sx={{ color: "#f00" }}
                            >
                              *
                            </Typography>
                          </Typography>

                          <Box
                            display="flex"
                            alignItems="center"
                            gap="5px"
                            mt={0.9}
                          >
                            {ADDRESS_TYPES.map((item) => (
                              <Box
                                sx={{
                                  ...styles.addrChip,
                                }}
                                onClick={() => {
                                  if (isTypeError) {
                                    setIsTypeError(false);
                                  }
                                  setType(item.value);
                                }}
                              >
                                {type == item.value ? (
                                  <CheckCircleRoundedIcon
                                    sx={{
                                      fontSize: "19px",
                                      color: greenColor05,
                                    }}
                                  />
                                ) : (
                                  <RadioButtonUncheckedRoundedIcon
                                    sx={{
                                      fontSize: "19px",
                                      color: greyShade79,
                                    }}
                                  />
                                )}
                                <Typography
                                  fontSize={12}
                                  fontWeight={500}
                                  color={blackColor}
                                >
                                  {item.label}
                                </Typography>
                              </Box>
                            ))}
                          </Box>

                          <Box p="5px 10px 8px">
                            <FormControlLabel
                              control={<Checkbox sx={styles.checkbox} />}
                              label={
                                <Typography
                                  fontSize={12}
                                  fontWeight={500}
                                  color={blackColor}
                                  ml={0.5}
                                >
                                  Default address
                                </Typography>
                              }
                              value={selected}
                              onChange={() => {
                                setDefault(!selected);
                              }}
                            />
                          </Box>
                        </Box>

                        <Button
                          sx={{ ...styles.addBtn, ml: { xs: "auto", md: 0 } }}
                          startIcon={
                            loading ? (
                              <CircularProgress color="inherit" size={16} />
                            ) : null
                          }
                          onClick={() => {
                            if (loading) return;
                            validateAddress();

                            // if (isUpdateRequired) {
                            //   setOpenAlert(true);
                            //   return;
                            // }

                            // setLoading(true);
                            // updateAddress();
                          }}
                        >
                          UPDATE ADDRESS
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <AddressConfirmAlert
              title="Please confirm the delivery address"
              content=""
              address={`${address} ${
                landmark ? `${landmark},` : ""
              } ${city}, ${state} - ${pincode}`}
              city={city}
              state={state}
              pincode={pincode}
              isOpen={openAlert}
              onClose={() => {
                setOpenAlert(false);
              }}
              onConfirm={() => {
                if (loading) return;

                setLoading(true);
                updateAddress();
                setOpenAlert(false);
              }}
            />
          </Box>
        </Box>
      </ModalCard>
    ))
  );
}

export default EditAddressFormNew;

const SelecteAddressInfo = ({ fullAddress, onChange }) => {
  return (
    <Box sx={styles.selectedAddrInfoBox}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={{ xs: "5px", md: "15px" }}
      >
        <Typography
          fontSize={{ xs: 13.5, md: 14 }}
          fontWeight={600}
          color={blackColor}
          lineHeight="15px"
          letterSpacing="-0.3px"
        >
          Selected Address
        </Typography>

        <Button
          variant="text"
          sx={{
            fontSize: 11.5,
            fontWeight: 600,
            lineHeight: "15px",
            textDecoration: "underline",
            textTransform: "uppercase",
            "&:hover": {
              bgcolor: "transparent",
            },
          }}
          onClick={onChange}
        >
          Pick New Location
        </Button>
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          gap: "9px",
          bgcolor: lightGreenShade02,
          p: "10px 8px",
          borderRadius: "6px",
        }}
      >
        <Box
          width={{ xs: "19px", md: "21px" }}
          height={{ xs: "19px", md: "21px" }}
          mt={-0.1}
        >
          <MarkerIcon />
        </Box>

        <Typography
          fontSize={{ xs: 11.5, md: 12.5 }}
          fontWeight={600}
          color={greenColor05}
          lineHeight="18px"
          letterSpacing="0px"
        >
          {fullAddress}
        </Typography>
      </Box>
    </Box>
  );
};

const styles = {
  submitBtn: {
    fontSize: "14px",
    fontWeight: 600,
    color: whiteColor,
    border: "2px solid " + orangeColor01,
    "&:hover": {
      backgroundColor: whiteColor,
      color: orangeColor01,
    },
  },

  chooseLocationBtn: {
    background: whiteColor,
    p: "0px 0px",
    fontSize: "13px",
    color: "#f58220",
    fontWeight: 600,
    textTransform: "uppercase",
    textDecoration: "underline",
    "&:hover": {
      backgroundColor: whiteColor,
      color: "#f58220",
      textDecoration: "none",
    },
  },

  addrChip: {
    display: "flex",
    alignItems: "center",
    gap: 0.8,
    p: "0px 12px 3px 0",
    color: "rgb(28, 28, 28)",
    lineHeight: "24px",
    borderRadius: "8px",
    // boxShadow: "rgba(28, 28, 28, 0.06) 0px 1px 4px 0px",
    cursor: "pointer",
    position: "relative",
  },

  addBtn: {
    fontSize: 12.5,
    fontWeight: 600,
    lineHeight: "22px",
    p: "7px 20px",
    borderRadius: "6px",
    letterSpacing: "-0.3px",

    color: whiteColor,
    background: greenColor05,
    border: `1px solid ${greenColor05}`,
    textTransform: "uppercase",
    "&:hover": {
      color: greenColor05,
      background: whiteColor,
    },
  },

  selectedAddrInfoBox: {
    width: { xs: "calc(100% - 45px)", md: "calc(100% - 30px)" },
    bgcolor: whiteColor,
    position: "absolute",
    bottom: { xs: -45, md: 0 },
    right: 0,
    zIndex: 30,
    p: { xs: "10px 12px", md: "15px" },
    mr: { xs: "7px", md: 0 },
    border: `1px solid ${lightGreenShade02}`,
    borderRadius: { xs: "10px", md: "10px 10px 0px 0px" },
    boxShadow: "rgb(0 0 0 / 17%) 0px 0px 16px 0px",
  },

  modelCardContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: { xs: "flex-end", md: "center" },
    height: { xs: "100dvh", md: "auto" },
  },

  label: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: "14px",
    letterSpacing: "-0.3px",
    color: { md: greyShade76, xs: greyShade76 },
  },

  checkIcon: {
    width: "16px",
    height: "16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    bgcolor: greenColor05,
    position: "absolute",
    top: -4,
    right: -4,
  },

  checkbox: {
    color: "rgba(0,0,0,0.5)",
    p: 0,
    transform: "scale(0.85)",
    "&.Mui-checked": {
      color: greenColor05,
    },
  },
};

import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useRef, useState } from "react";
import CouponCard from "./DiscountCards/CouponCard";
import CashbackCard from "./DiscountCards/CashbackCard";
import FirstTimeBuyerCard from "./DiscountCards/FirstTimeBuyerCard";
import InstantCashCard from "./DiscountCards/InstantCashCard";
import {
  backgroundColor,
  blackColor,
  blackShade11,
  greyColor40,
  minOrderTextColor,
  orangeColor01,
  redColor01,
  whiteColor,
} from "../../configs/styles/muiThemes";
import moment from "moment";
import ResellerCouponCard from "./DiscountCards/ResellerCouponCard";
import {
  availabelDiscount_AlertInfo,
  availabelDiscount_Info,
} from "configs/Constants";
import Slider from "react-slick/lib/slider";

function AvailableDiscount({
  rewardpoints,
  dealsCouponsDetails,
  cartDetails,
  commercialDetails,
  firstTimeDiscountData,
  discountAmount,
  setDiscountAmount,
  appliedDiscountDetails,
  setAppliedDiscountDetails,
  checkResellerData,
  instantDiscounts,
  resetDelivery,
  // Auto Coupon Apply Loading
  isCouponValidating,
  setIsCouponValidating,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const [selectedDiscount, setSelectedDiscount] = useState("");
  // const [showDiscounts, setShowDiscounts] = useState(false)

  const affiliateDiscountData = JSON.parse(
    sessionStorage.getItem("specialOfferData")
  );
  const showOnlyResellerCoupon = affiliateDiscountData ? true : false;
  const settings = {
    dots: true,
    // infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    speed: 1000,
    arrows: false,
    autoplaySpeed: 3000,
    cssEase: "linear",
  };

  return matches ? (
    <Box pb={0.5}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography
          component="p"
          fontSize={{ xs: 11, md: 12.5 }}
          fontWeight={400}
          color={minOrderTextColor}
          sx={{
            lineHeight: {
              xs: "16px !important",
              md: "16px !important",
            },
          }}
        >
          {availabelDiscount_Info}
        </Typography>
        {/* {selectedDiscount != '' ? (
        <DiscountHeader
          icon={<LocalOfferRounded sx={{ color: headingColor }} />}
          title='AVAILABLE DISCOUNTS (OPTIONAL)'
          discountType={selectedDiscount != ''}
          onCancelDiscount={() => setSelectedDiscount('')}
        />
      ) : null} */}
        {!showOnlyResellerCoupon ? (
          selectedDiscount !== "" ? (
            // <Box
            //   display='flex'
            //   alignItems='center'
            //   justifyContent='center'
            //   mt={1.5}
            // >
            <Button
              variant="contained"
              disableElevation
              sx={styles.cancelBtn}
              onClick={() => {
                setSelectedDiscount("");
                // resetDelivery()
              }}
              // size='small'
            >
              Cancel Discount
            </Button>
          ) : // </Box>
          null
        ) : null}
      </Box>

      <Box sx={{ mt: 2 }}>
        <Grid
          container
          spacing={{ xs: 1.3, md: 2 }}
          rowSpacing={{ xs: 1.3, md: 1.5 }}
          columnSpacing={{ xs: 1.3, md: 2.5 }}
        >
          {!showOnlyResellerCoupon && (
            <>
              {dealsCouponsDetails?.length > 0 ? (
                <Grid item xs={12} md={6}>
                  <CouponCard
                    isSelected={selectedDiscount === "coupon-code"}
                    dealsCouponsDetails={dealsCouponsDetails}
                    onSelect={() => {
                      setSelectedDiscount("coupon-code");
                    }}
                    rewardPoints={rewardpoints}
                    commercialDetails={commercialDetails}
                    cartDetails={cartDetails}
                    discountAmount={discountAmount}
                    setDiscountAmount={(data) => setDiscountAmount(data)}
                    appliedDiscountDetails={appliedDiscountDetails}
                    setAppliedDiscountDetails={(data) =>
                      setAppliedDiscountDetails(data)
                    }
                  />
                </Grid>
              ) : null}

              {rewardpoints?.details?.[0]?.availablePointValue?.toFixed() > 0 &&
              moment().isBefore(rewardpoints?.details?.[0]?.expiryDate) ? (
                <Grid item xs={12} md={6}>
                  <CashbackCard
                    isSelected={selectedDiscount === "cashback"}
                    onSelect={() => {
                      console.log("cashback");
                      setSelectedDiscount("cashback");
                    }}
                    rewardPoints={rewardpoints}
                    commercialDetails={commercialDetails}
                    cartDetails={cartDetails}
                    discountAmount={discountAmount}
                    setDiscountAmount={(data) => setDiscountAmount(data)}
                    setAppliedDiscountDetails={(data) =>
                      setAppliedDiscountDetails(data)
                    }
                  />
                </Grid>
              ) : null}

              {firstTimeDiscountData?.details ? (
                firstTimeDiscountData?.details?.availableAmount >
                firstTimeDiscountData?.details?.walletGiftLimit ? (
                  //   &&
                  // moment().isBetween(
                  //   firstTimeDiscountData?.details?.walletStartDate,
                  //   firstTimeDiscountData?.details?.walletEndDate
                  // )
                  <Grid item xs={12} md={6}>
                    <FirstTimeBuyerCard
                      isSelected={selectedDiscount === "first-time"}
                      onSelect={() => {
                        console.log("first");
                        setSelectedDiscount("first-time");
                      }}
                      firstTimeData={firstTimeDiscountData?.details}
                      commercialDetails={commercialDetails}
                      cartDetails={cartDetails}
                      discountAmount={discountAmount}
                      setDiscountAmount={(data) => setDiscountAmount(data)}
                      setAppliedDiscountDetails={(data) =>
                        setAppliedDiscountDetails(data)
                      }
                    />
                  </Grid>
                ) : null
              ) : null}

              {instantDiscounts?.length > 0 ? (
                <Grid item xs={12} md={6}>
                  <InstantCashCard
                    isSelected={selectedDiscount === "instant-cash"}
                    onSelect={() => {
                      console.log("instant");
                      setSelectedDiscount("instant-cash");
                    }}
                    instantDiscounts={instantDiscounts}
                    cartDetails={cartDetails}
                    setDiscountAmount={(data) => setDiscountAmount(data)}
                    setAppliedDiscountDetails={(data) =>
                      setAppliedDiscountDetails(data)
                    }
                    // onViewMoreDiscount={() => {
                    //   setShowDiscounts(true)
                    // }}
                  />
                </Grid>
              ) : null}
            </>
          )}

          {checkResellerData?.isPresent ? (
            <Grid item xs={12} md={6}>
              <ResellerCouponCard
                isSelected={selectedDiscount === "reseller-coupon-code"}
                dealsCouponsDetails={dealsCouponsDetails}
                onSelect={() => {
                  console.log("coupon");
                  setSelectedDiscount("reseller-coupon-code");
                }}
                rewardPoints={rewardpoints}
                commercialDetails={commercialDetails}
                cartDetails={cartDetails}
                discountAmount={discountAmount}
                setDiscountAmount={(data) => setDiscountAmount(data)}
                appliedDiscountDetails={appliedDiscountDetails}
                setAppliedDiscountDetails={(data) =>
                  setAppliedDiscountDetails(data)
                }
                // Loader
                setIsCouponValidating={setIsCouponValidating}
              />
            </Grid>
          ) : null}
        </Grid>
      </Box>

      <Typography
        component="p"
        fontSize={{ xs: 11, ms: 13 }}
        fontWeight={500}
        color={{ xs: minOrderTextColor, md: blackShade11 }}
        sx={{
          lineHeight: {
            xs: "16px !important",
            md: "20px !important",
          },
          mt: 0.5,
        }}
      >
        {availabelDiscount_AlertInfo}
      </Typography>

      {/* {!showOnlyResellerCoupon ? (
        selectedDiscount !== '' ? (
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            mt={1.5}
          >
            <Button
              variant='outlined'
              sx={styles.actionBtn}
              onClick={() => {
                setSelectedDiscount('')
                // resetDelivery()
              }}
              size='small'
            >
              Cancel Discount
            </Button>
          </Box>
        ) : null
      ) : null} */}
    </Box>
  ) : (
    <Box>
      <Box display={"flex"} justifyContent={"space-between"} pb={1.5}>
        <Typography
          width={
            !showOnlyResellerCoupon
              ? selectedDiscount !== ""
                ? "60%"
                : "100%"
              : "100%"
          }
          component="p"
          fontSize={{ xs: 11, md: 14 }}
          fontWeight={500}
          color={minOrderTextColor}
          sx={{
            lineHeight: {
              xs: "16px !important",
              md: "20px !important",
            },
          }}
        >
          {availabelDiscount_Info}
        </Typography>
        {/* {selectedDiscount != '' ? (
        <DiscountHeader
          icon={<LocalOfferRounded sx={{ color: headingColor }} />}
          title='AVAILABLE DISCOUNTS (OPTIONAL)'
          discountType={selectedDiscount != ''}
          onCancelDiscount={() => setSelectedDiscount('')}
        />
      ) : null} */}
        {!showOnlyResellerCoupon ? (
          selectedDiscount !== "" ? (
            // <Box
            //   display='flex'
            //   alignItems='center'
            //   justifyContent='center'
            //   mt={1.5}
            // >
            <Button
              variant="contained"
              disableElevation
              sx={styles.cancelBtn}
              onClick={() => {
                setSelectedDiscount("");
                // resetDelivery()
              }}
              // size='small'
            >
              Cancel Discount
            </Button>
          ) : // </Box>
          null
        ) : null}
      </Box>

      {/* <Box sx={{ ".slick-dots": { marginTop: "20px",pt:10 } }}> */}
      {!showOnlyResellerCoupon ? (
        <Slider {...settings}>
          {dealsCouponsDetails?.length > 0 ? (
            <Box sx={styles.ticketContainer}>
              <CouponCard
                isSelected={selectedDiscount === "coupon-code"}
                dealsCouponsDetails={dealsCouponsDetails}
                onSelect={() => {
                  setSelectedDiscount("coupon-code");
                }}
                rewardPoints={rewardpoints}
                commercialDetails={commercialDetails}
                cartDetails={cartDetails}
                discountAmount={discountAmount}
                setDiscountAmount={(data) => setDiscountAmount(data)}
                appliedDiscountDetails={appliedDiscountDetails}
                setAppliedDiscountDetails={(data) =>
                  setAppliedDiscountDetails(data)
                }
              />
              {/* <Box sx={styles.ticketRightSide}>
                  <Box sx={styles.smallSemiCircle} mt={1}></Box>
                  <Box sx={styles.smallSemiCircle}></Box>
                  <Box sx={styles.bigSemiCircle}></Box>
                  <Box sx={styles.smallSemiCircle}></Box>
                  <Box sx={styles.smallSemiCircle} mb={1}></Box>
                </Box> */}
            </Box>
          ) : null}

          {rewardpoints?.details?.[0]?.availablePointValue?.toFixed() > 0 &&
          moment().isBefore(rewardpoints?.details?.[0]?.expiryDate) ? (
            <Box sx={styles.ticketContainer}>
              <CashbackCard
                isSelected={selectedDiscount === "cashback"}
                onSelect={() => {
                  console.log("cashback");
                  setSelectedDiscount("cashback");
                }}
                rewardPoints={rewardpoints}
                commercialDetails={commercialDetails}
                cartDetails={cartDetails}
                discountAmount={discountAmount}
                setDiscountAmount={(data) => setDiscountAmount(data)}
                setAppliedDiscountDetails={(data) =>
                  setAppliedDiscountDetails(data)
                }
              />
            </Box>
          ) : null}

          {firstTimeDiscountData?.details ? (
            firstTimeDiscountData?.details?.availableAmount >
            firstTimeDiscountData?.details?.walletGiftLimit ? (
              <Box sx={styles.ticketContainer}>
                <FirstTimeBuyerCard
                  isSelected={selectedDiscount === "first-time"}
                  onSelect={() => {
                    console.log("first");
                    setSelectedDiscount("first-time");
                  }}
                  firstTimeData={firstTimeDiscountData?.details}
                  commercialDetails={commercialDetails}
                  cartDetails={cartDetails}
                  discountAmount={discountAmount}
                  setDiscountAmount={(data) => setDiscountAmount(data)}
                  setAppliedDiscountDetails={(data) =>
                    setAppliedDiscountDetails(data)
                  }
                />
              </Box>
            ) : null
          ) : null}

          {instantDiscounts?.length > 0 ? (
            <Box sx={styles.ticketContainer}>
              <InstantCashCard
                isSelected={selectedDiscount === "instant-cash"}
                onSelect={() => {
                  console.log("instant");
                  setSelectedDiscount("instant-cash");
                }}
                instantDiscounts={instantDiscounts}
                cartDetails={cartDetails}
                setDiscountAmount={(data) => setDiscountAmount(data)}
                setAppliedDiscountDetails={(data) =>
                  setAppliedDiscountDetails(data)
                }
                // onViewMoreDiscount={() => {
                //   setShowDiscounts(true)
                // }}
              />
            </Box>
          ) : null}
        </Slider>
      ) : null}

      {checkResellerData?.isPresent ? (
        <Box sx={styles.ticketContainer}>
          <ResellerCouponCard
            isSelected={selectedDiscount === "reseller-coupon-code"}
            dealsCouponsDetails={dealsCouponsDetails}
            onSelect={() => {
              console.log("coupon");
              setSelectedDiscount("reseller-coupon-code");
            }}
            rewardPoints={rewardpoints}
            commercialDetails={commercialDetails}
            cartDetails={cartDetails}
            discountAmount={discountAmount}
            setDiscountAmount={(data) => setDiscountAmount(data)}
            appliedDiscountDetails={appliedDiscountDetails}
            setAppliedDiscountDetails={(data) =>
              setAppliedDiscountDetails(data)
            }
            // Loader
            setIsCouponValidating={setIsCouponValidating}
          />
        </Box>
      ) : null}
      {/* </Box> */}

      <Typography
        component="p"
        fontSize={{ xs: 11, ms: 13 }}
        color={blackColor}
        fontWeight={500}
        sx={{
          lineHeight: {
            xs: "16px !important",
            md: "20px !important",
          },
        }}
      >
        {availabelDiscount_AlertInfo}
      </Typography>
    </Box>
  );
}

export default AvailableDiscount;

const styles = {
  applyBtn: {
    width: "25%",
    fontSize: "15px",
    fontWeight: 600,
    color: whiteColor,
    border: "2px solid " + orangeColor01,
    "&:hover": {
      backgroundColor: whiteColor,
      color: orangeColor01,
    },
  },

  actionBtn: {
    fontSize: { xs: "11px", md: "13px" },
    padding: "4px 8px",
    "&:hover": {
      backgroundColor: "primary.main",
      color: whiteColor,
    },
  },
  cancelBtn: {
    padding: { md: "1px 10px", xs: "0px 6px" },
    fontSize: { md: "11px", xs: "10px" },
    fontWeight: 600,
    color: { xs: redColor01, md: redColor01 },
    backgroundColor: whiteColor,
    // mt: 0.8,
    border: {
      xs: "1px solid " + redColor01,
      md: "1px solid " + redColor01,
    },
    textTransform: "uppercase",
    "&:hover": {
      color: whiteColor,
      backgroundColor: { xs: redColor01, md: redColor01 },
    },
  },

  ticketContainer: {
    display: "flex",
    width: "350px",
    minWidth: "100%",
    height: "138px",
    borderRadius: "10px",
    // boxShadow: '0 0px 10px rgba(0, 0, 0, 0.2)',
    overflow: "hidden",
    position: "relative",
    // backgroundColor: '#ffffff',
    // boxShadow: `0 0 1px ${greyColor40}`,
    mb: { xs: 1, md: 3 },
  },
  ticketRightSide: {
    width: "1px",
    // backgroundColor: '#f5f5f5',
    // backgroundColor:"red",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    position: "relative",
  },
  smallSemiCircle: {
    width: "7px",
    height: "7px",
    backgroundColor: backgroundColor,
    // backgroundColor: '#ffffff',
    borderRadius: "50% 0 0 50% ",
    // marginBottom: '-15px', // Align the small semicircles closely with the big one
    // boxShadow: '0 0 10px rgba(0, 0, 0, 0.05)',
    boxShadow: `0 0 1px ${greyColor40}`,
  },
  bigSemiCircle: {
    width: "20px",
    height: "20px",
    backgroundColor: backgroundColor,
    // backgroundColor: '#ffffff',
    borderRadius: "50% 0 0 50%",

    margin: "-4px 0",
    boxShadow: `0 0 1px ${greyColor40}`,
    // boxShadow: '0 0 10px rgba(0, 0, 0, 0.05)',
  },
};

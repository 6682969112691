import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Typography,
  TextareaAutosize,
  ListItem,
  ListItemText,
  CircularProgress,
  useMediaQuery,
  Radio,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
  backgroundColor,
  backgroundColor01,
  blackColor,
  blackColor01,
  blackShade11,
  blackShade8,
  borderLineColor,
  borderTextbox,
  couponColor,
  delectIconInactiveColor,
  greenColor04,
  greenColor05,
  greyColor9,
  greyShade10,
  greyShade50,
  greyShade7,
  headingColor,
  lightGreen03,
  loginPrimary,
  minOrderTextColor,
  navLinkColor,
  orangeColor01,
  orangeColor03,
  orangeColor04,
  orangeColor05,
  savingTextColor,
  secondaryColor,
  topbarColor,
  whiteColor,
} from "../../configs/styles/muiThemes";
import CartBottomTab from "../Cart/CartBottomTab";
import AdditionalSavingCard from "../Cart/AdditionalSavingCard";
import DiscountHeader from "./DiscountHeader";
import AddressSection from "./AddressCards/AddressSection";
import AvailableDiscount from "./AvailableDiscount";
import CartCount from "./DiscountCards/CartCount";
import { Inventory2, Lightbulb } from "@mui/icons-material";
import DeliveryMethodSection from "./DeliveryMethodSection";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  addPaymentProcess,
  checkDeliveryAvailabilityDetails,
  delhiveryCostCalculationDetails,
  delhiveryServiceabilityDetails,
  delhiveryShipmentPriceDetails,
  getBusinessContactDetails,
  sendOrderMessage,
  sendOrderNotification,
  updateWebOrderPayment,
} from "redux/checkout/CheckoutSlice";
import AlertDialog from "components/AlertDialog";
import {
  addPaymentProcessHelper,
  checkOutUpdateCartHelper,
  discountCouponDataHelper,
  getFormattedDeliveryTime,
  getTimeToShip,
  getTimeUntilOpen,
  orderCODCartDataHelper,
  paycartUpdateHelper,
  prepareGenerateLinkPostData,
  prepareOndcPricePayloadData,
  sendOrderMessageHelper,
  sendOrderNotificationHelper,
  setRazorpayPaymentOptions,
  updateWebOrderPaymentHelper,
} from "utils/checkoutHelpers";
import { updateCartDetails } from "redux/cart/CartSlice";
import {
  availabelDiscount_Info,
  dealsEmptyImgUrl,
  finboxApplyStatusmsg,
  finboxApplyStatusTitle,
  finboxApprovedStatusmsg,
  finboxApprovedStatusTitle,
  finboxRejectedStatusmsg,
  finboxRejectedStatusTitle,
  orderLimitAmount,
  selectDeliveryAddressMsg,
  selectDeliveryMethodMsg,
  transactionErrorMsg,
  unicomInventoryErrMsg,
  updateLatLongMsg,
} from "configs/Constants";
import { Link, useNavigate } from "react-router-dom";
import {
  getOperatingSystem,
  getBrowser,
  fintechApplyData,
  fintechData,
  allowOnlyEnglish,
} from "utils/utilities";
import TableProductCard from "components/TableProductCard";
import EmptyCard from "components/EmptyCard";
import {
  checkFinboxUserExists,
  checkFinvervUserExists,
  checkTelyportRegion,
  checkTelyportServiceTime,
  finboxLoanOrder,
  finboxPartnerPush,
  getFinboxBanner,
  getFinboxCreditLine,
  getFinboxEligibility,
  getFinboxUrl,
  getFinvervApplicationStatus,
  getFinvervLines,
  getFinvervUrl,
  getTelyportPrizing,
  orderCODCartAPI,
  payUsingCredit,
  registeFinboxUser,
  createLoanOrderFinverv,
  registeFinvervUser,
  generatePaymentLinkApi,
  upgradeToPayCartApi,
  getOndcPrizing,
} from "services/api_calls/checkout/checkoutApi";
import AlertInfoCard from "./DiscountCards/AlertInfoCard";
import WorkingHoursCard from "pages/Overview/WorkingHoursCard";
import ModalCard from "components/ModalCard";
import { getPhoneNumber, getProfileId } from "utils/authHelpers";
import CODConfirmationAlert from "components/CODConfirmationAlert";
import ShoppingBasketRoundedIcon from "@mui/icons-material/ShoppingBasketRounded";
import OtpCard from "./OtpCard";
import firebase from "../../firebase";
import FreeShippingContentCard from "components/FreeShippingContentCard";
import ModalCard1 from "components/ModalCard1";
import GSTDetialsSection from "./GSTDetialsSection";
import { checkIsUpdateRequired } from "utils/AddressHelper/addressHelper";
import ConfirmAlert from "./Popups/ConfirmAlert";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import {
  createSaleOrderpostData,
  getPartnerInventoryError,
  validateCatalogPartnetInventory
} from "utils/catalogValidationHelper";
import {
  createUnicomSaleOrderApi,
  verifyUnicommerceSaleOrder,
} from "services/api_calls/unicommerce/UnicommerceApi";
import BreadcrumbNew from "components/BreadcrumbNew";
import TopComponent from "pages/TopComponent";
import AlertModal from "components/AlertModal";
import HeaderLoader from "components/skeletonLoaders/HeaderLoader";
import ApplyDiscountLoader from "components/skeletonLoaders/cart/ApplyDiscountLoader";
import CartItemsLoaderMinimal from "components/skeletonLoaders/cart/CartItemsLoaderMinimal";
import CounponCardsMobileLoader from "components/skeletonLoaders/discountCoupons/CounponCardsMobileLoader";
import { getCatalogAllProducts } from "redux/subCatergory/subCategorySlice";
import { navigateTo } from "utils/urlHelper";

function DiscountDeliveryContent({
  addressDetails,
  accountDetails,
  rewardpoints,
  dealsCouponsDetails,
  cartDetails,
  commercialDetails,
  firstTimeDiscountData,
  checkResellerData,
  instantDiscounts,
  activeBusinessData,
  currentPage,
  setCurrentPage,
  couponsLoading,
  overviewDetails,
  cartProductList,
  addressLoader,
}) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const [openAddAddress, setOpenAddAddress] = useState(false);
  const [openUpdateAddress, setOpenUpdateAddress] = useState(false);
  const slider = useRef(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [isAdd, setIsAdd] = useState(true);
  const [editAddress, setEditAddress] = useState(null);
  const [makeDefault, setMakeDefault] = useState(true);

  // CONSTANTS - Start
  const TYPE_BUSINESS = "business";
  const TYPE_DELHIVERY_B2B = "delhiveryb2b";
  const TYPE_DELHIVERY = "delhivery";
  const TYPE_TELYPORT = "telyport";

  const KILOMETER_KM = "km";
  const SHIP_TYPE_REGULAR = "Regular Delivery";
  // CONSTANTS - END

  const dispatch = useDispatch();
  let navigate = useNavigate();

  const isBusinessClosed = commercialDetails?.info?.hyperlocalStore
    ? !commercialDetails?.info?.hyperlocalStore?.storeOpenStatus
    : false;

  const [isCouponValidating, setIsCouponValidating] = useState(true);

  const [collapseCartTabel, setToggleCollapseCartTabel] = useState(true);
  const [collapseInstructions, setToggleCollapseInstructions] = useState(true);

  const businessName = sessionStorage.getItem("businessName");
  // const loginCXPhone = '+91' + sessionStorage.getItem('phone')
  const userProfileId = getProfileId();

  const [paycartLoading, setPaycartLoading] = useState(false);

  const [isInstorePick, setIsInstorePick] = useState(false);
  // const [instoreDisplay, setInstoreDisplay] = useState(false)
  const [addressDeliveryDisplay, setAddressDeliveryDisplay] = useState(false);
  const [deliveryMethod, setDeliveryMethod] = useState("");
  const [discountAmount, setDiscountAmount] = useState(0);
  const [appliedDiscountDetails, setAppliedDiscountDetails] = useState(null);
  const [isDiscountSection, setIsDiscountSection] = useState(false);
  const [hideDiscountSection, setHideDiscountSection] = useState(true);
  let totalQty = 0;
  let totalSavings = 0;

  const [totalQty1, settotalQty1] = useState(null);
  const [totalCartWeightGm, setTotalCartWeightGm] = useState(0);
  const [totalCartWeightKg, settotalCartWeightKg] = useState(0);

  const [alertMsg, setAlertMsg] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);

  const [openConfirmAlert, setOpenConfirmAlert] = useState({
    isOpen: false,
    message: "",
  });

  const [selectedDiscountType, setSelectedDiscountType] = useState(null);

  // const [diliveryDistance, setDiliveryDistance] = useState(0)
  const [shipmentEta, setShipmentEta] = useState("");

  const [isFreeShipping, setIsFreeShipping] = useState(false);
  // const [isDeliverySelected, setIsDeliverySelected] = useState(false)
  // const [additionalDiscount, setAdditionalDiscount] = useState(0)
  let additionalDiscount = 0;

  const [seletedAddress, setSeletedAddress] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [noAddressError, setNoAddressError] = useState(null)

  let shipmentValueCod = 0;
  // const [isverifyBusinessAvailability, setIsverifyBusinessAvailability] =
  //   useState(false)
  // const [isverifyUserAvailability, setIsverifyUserAvailability] =
  //   useState(false)

  const [costboPremier, setCostboPremier] = useState({
    totalAmount: "",
    originalAmount: "",
    show: false,
    error: false,
    type: "",
    shipmentTax: 0,
  });
  const [costboPremierDeliverETA, setCostboPremierDeliverETA] = useState("");
  const [costboPremierHeavy, setCostboPremierHeavy] = useState({
    totalAmount: "",
    originalAmount: "",
    show: false,
    error: false,
    type: "",
    shipmentTax: 0,
  });
  // const [costboPremierDeliverETA, setCostboPremierDeliverETA] = useState('')
  const [businessDelivery, setBusinessDelivery] = useState({
    totalAmount: "",
    show: false,
    deliveryETA: "",
    originalAmount: "",
    error: false,
    type: "",
    shipmentTax: 0,
  });
  const [telyportRushDelivery, setTelyportRushDelivery] = useState({
    totalAmount: "",
    show: false,
    deliveryETA: "",
    originalAmount: "",
    error: false,
    type: "",
    shipmentTax: 0,
  });
  const [telyportExpressDelivery, setTelyportExpressDelivery] = useState({
    totalAmount: "",
    show: false,
    deliveryETA: "",
    originalAmount: "",
    error: false,
    type: "",
    shipmentTax: 0,
  });
  const [telyportStandardDelivery, setTelyportStandardDelivery] = useState({
    totalAmount: "",
    show: false,
    deliveryETA: "",
    originalAmount: "",
    error: false,
    type: "",
    shipmentTax: 0,
  });
  const [costboPremierDeliverETAHeavy, setCostboPremierDeliverETAHeavy] =
    useState("");
  const [ondcDelivery, setOndcDelivery] = useState({
    totalAmount: "",
    show: false,
    deliveryETA: "",
    originalAmount: "",
    error: false,
    type: "",
    shipmentTax: 0,
  });

  const [consumerNotes, setConsumerNotes] = useState("");
  const [shipmentAmount, setShipmentAmount] = useState("");
  const [crmOwnershipInfo, setCrmOwnershipInfo] = useState("");
  const [codAvailable, setCodAvailable] = useState(false);
  const [codCost, setCodCost] = useState("");
  const [codChargeTo, setCodChargeTo] = useState("");
  const [cashOnDelivery, seCashOnDelivery] = useState(false);
  const [activeDeliveryType, setActiveDeliveryType] = useState({});
  const [isAdditionalDiscount, setIsAdditionalDiscount] = useState(false);
  const [deliveryMethodData, setDeliveryMethodData] = useState([]);
  const [packageInfo, setPackageInfo] = useState({
    unitSize: "",
    length: 0,
    width: 0,
    height: 0,
    unit: "kg",
  });

  const [deviceInfo, setDeviceInfo] = useState(null);
  const [shipmentTaxValue, setShipmentTaxValue] = useState(0);
  const [telyportPrizing, setTelyportPrizing] = useState(null);

  const [workingHourPopup, setWorkingHourPopup] = useState(false);
  const [isFinvervAvailable, setIsFinvervAvailable] = useState("");
  const [finAvailValue, setFinAvailValue] = useState(null);
  const [finPercentComplete, setFinPercentComplete] = useState(null);

  const [codConfirmAlert, setCodConfirmAlert] = useState(false);
  const [codLoading, setCodLoading] = useState(false);

  const [finvervModal, setFinvervModal] = useState({ show: false, url: "" });
  const [finvervModal1, setFinvervModal1] = useState({ show: false, url: "" });
  const [otpModal, setOtpModal] = useState(false);
  const [finboxLoading, setFinboxLoading] = useState(false);
  const [finboxAvlAmt, setFinboxAvlAmt] = useState(null);

  const [confirmPayLoader, setConfirmPayLoader] = useState(false);

  const [finvervLoading, setFinvervLoading] = useState(false);

  const [gstChecked, setGstChecked] = useState(false);
  const [gstSelected, setGstSelected] = useState("");
  const [gstNumber, setGstNumber] = useState("");

  let b2cDelhiveryCheck = false;
  let b2bDelhiveryCheck = false;
  let businessDeliveryCheck = false;
  let telyportDeliveryCheck = false;
  let ondcDeliveryCheck = false;

  let additionalDiscountCheck = false;
  let additionalDiscountTypeValue = "";

  const businessId = sessionStorage.getItem("businessId");

  const { isLoggedIn } = useSelector((state) => state.auth);
  const { fintechStatus } = useSelector((state) => state.checkout);

  const [deliveryMethodLoader, setDeliveryMethodLoader] = useState("");

  const { allProducts } = useSelector((state) => state.subCategory);

  // unicommerce
  const [catalogPartnerValidationData, setCatalogPartnerValidationData] = useState()
  const unicomErrRef = useRef(null);

  /* Start of calculate OrderTotalCost */
  const calculateOrderTotalCost = (
    totalProductCost,
    discountAmt,
    shippingAmt
  ) => {
    return Number(totalProductCost) - Number(discountAmt) + Number(shippingAmt);
  };
  /* End of calculate OrderTotalCost */

  /*Start of check if discount section exists*/
  const checkDiscountSection = () => {
    const affiliateDiscountData = JSON.parse(
      sessionStorage.getItem("specialOfferData")
    );

    // Hiding all discount cards of affiliate discount percentage is zero
    if (affiliateDiscountData?.discountPercentage === 0) {
      const discountResellerData = discountCouponDataHelper(
        affiliateDiscountData,
        cartDetails
      );

      // Updating Reseller Data
      setAppliedDiscountDetails(discountResellerData);

      // Hiding discount Cards
      setHideDiscountSection(false);
      setIsDiscountSection(false);
      setIsCouponValidating(false);
      return;
    }

    if (dealsCouponsDetails?.length > 0) {
      setIsDiscountSection(true);
      setHideDiscountSection(false);
      return;
    }

    if (
      rewardpoints?.details?.[0]?.availablePointValue?.toFixed() > 0 &&
      moment().isBefore(rewardpoints?.details?.[0]?.expiryDate)
    ) {
      setIsDiscountSection(true);
      setHideDiscountSection(false);
      return;
    }
    if (
      firstTimeDiscountData?.details &&
      firstTimeDiscountData?.details?.availableAmount >
        firstTimeDiscountData?.details?.walletGiftLimit &&
      moment().isBetween(
        firstTimeDiscountData?.details?.walletStartDate,
        firstTimeDiscountData?.details?.walletEndDate
      )
    ) {
      setIsDiscountSection(true);
      setHideDiscountSection(false);
      return;
    }
    if (instantDiscounts?.length > 0) {
      setIsDiscountSection(true);
      setHideDiscountSection(false);
      return;
    }

    if (checkResellerData?.isPresent > 0) {
      setIsDiscountSection(true);
      setHideDiscountSection(false);
      return;
    }

    if (
      rewardpoints &&
      dealsCouponsDetails &&
      firstTimeDiscountData &&
      checkResellerData &&
      instantDiscounts
    ) {
      setIsCouponValidating(false);
      setHideDiscountSection(false);
    }
  };
  /*End of check if discount section exists*/

  /* Common Function 1 - Start calculating weight*/
  const getCartWeight = () => {
    let totalWeight = 0;
    cartDetails?.productList?.forEach((product) => {
      console.log("ProductWeight", product?.measurementInfo?.weight, product);
      totalWeight +=
        product?.measurementInfo?.weight * product?.currentQuantity;
    });
    setTotalCartWeightGm(totalWeight);
    settotalCartWeightKg(totalWeight / 1000);
  };
  /*End calculating weight*/

  /*Start convert degree to radius*/
  const degTorad = (deg) => {
    return deg * (Math.PI / 180);
  };
  /*End convert degree to radius*/

  /* Common Function 2 - Start Calculate distance b/w selected address & business address in Km*/
  const calculateDistance = (latitude, longitude) => {
    console.log("USER_LAT:", latitude);
    console.log("USER_Long:", longitude);

    console.log("USER_LAT1:", commercialDetails?.info?.loc.y);
    console.log("USER_Long1:", commercialDetails?.info?.loc.x);
    const R = 6371; // Radius of the earth in km
    const dLat = degTorad(commercialDetails?.info?.loc.y - latitude);
    const dLon = degTorad(commercialDetails?.info?.loc.x - longitude);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(degTorad(commercialDetails?.info?.loc.y)) *
        Math.cos(degTorad(latitude)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    console.log("distance", d);
    return d;
  };
  /*End Calculate distance b/w selected address & business address in Km*/

  /*Common Function 3 - Start of get ownership */
  const getCRMOwnerShip = () => {
    dispatch(getBusinessContactDetails()).then((res) => {
      if (res?.payload?.success) {
        if (res?.payload?.detail) {
          setCrmOwnershipInfo(res?.payload?.detail?.contactInfo?.ownerShip);
        } else {
          setCrmOwnershipInfo("new");
        }
      }
    });
  };
  /*End of get ownership*/

  /*Common Function 4 - Start check free shipping*/
  const checkFreeShipping = () => {
    if (
      commercialDetails?.info?.immediateDiscount > 0 &&
      commercialDetails?.info?.immediateDiscount !== ""
    ) {
      if (
        cartDetails?.orderTotalProductCost >=
        commercialDetails?.info?.immediateDiscount
      ) {
        console.log("FREE_SHIPPING_ENABLED");
        setIsFreeShipping(true);
      }
    }
  };
  /*End check free shipping*/

  // Utily Function
  const checkTelyportServiceTimeAvailable = (curTime, curDay, serviceTime) => {
    const curServiceTime = serviceTime?.[curDay];
    const Ltime = moment(curServiceTime?.end, "HH:mm");
    const Ftime = moment(curServiceTime?.start, "HH:mm");
    // const isValidTime = moment()
    //   .format('HH:mm')
    //   .isBetween(curServiceTime?.start, curServiceTime?.end)
    // console.log(
    //   'Telyport checkTelyportServiceTimeAvailable',
    //   curTime,
    //   curDay,
    //   serviceTime,
    //   curServiceTime,
    //   curServiceTime?.start,
    //   curServiceTime?.end,
    //   moment(curTime, 'HH:mm')?.isBefore(Ltime),
    //   moment(curTime, 'HH:mm')?.isBefore(Ftime),
    //   moment(curTime, 'HH:mm')?.isBetween(Ftime, Ltime)
    // )
    return moment(curTime, "HH:mm")?.isBetween(Ftime, Ltime);
  };

  /*Start check if instore or other delivery methods are there*/
  const checkDeliveryAvailable = (code, setValue) => {
    const deliveryArray = commercialDetails?.info?.deliveryMethods?.filter(
      (item) => {
        return item.code === code && item.status === "active";
      }
    );
    setValue(deliveryArray?.length > 0);
  };
  /*End check if instore or other delivery methods are there*/

  /*Start of check delivery Eligibility*/
  const checkDeliveryEligibility = (
    deliveryType,
    commercialDetails,
    distanceInKm
  ) => {
    const deliveryInfo = commercialDetails?.info?.deliveryTypeInfo?.filter(
      (item) => {
        return item.type === deliveryType;
      }
    );
    console.log("CALCULATE_DELIVERY", deliveryInfo, deliveryType);
    if (deliveryInfo.length > 0) {
      if (
        distanceInKm >= deliveryInfo?.[0]?.from &&
        distanceInKm <= deliveryInfo?.[0]?.to
      ) {
        console.log("CALCULATE_DELIVERY_SUCCESS", deliveryInfo, distanceInKm);
        return true;
      } else {
        console.log("CALCULATE_DELIVERY_FAILED", deliveryInfo, distanceInKm);
        return false;
      }
    } else {
      return false;
    }
  };
  /*End of check delivery Eligibility*/

  /*Start Check if weight is in range for delhivery types b2c*/
  const checkWeightInBetweenDelhivery = () => {
    const selectedDeliveryType =
      commercialDetails?.info?.deliveryPartners?.filter((item) => {
        return item.deliveryType === TYPE_DELHIVERY;
      });
    if (selectedDeliveryType?.length === 0) {
      return false;
    }
    setCostboPremierDeliverETA(selectedDeliveryType?.[0]?.deliveryETA);

    if (
      totalCartWeightKg > selectedDeliveryType?.[0]?.packageRange?.[0]?.from &&
      totalCartWeightKg < selectedDeliveryType?.[0]?.packageRange?.[0]?.to
    ) {
      setCostboPremier({
        ...costboPremier,
        show: true,
      });
      return true;
    }
    return false;
  };
  /*End Check if weight is in range for delhivery types b2c*/

  /*Start Check if weight is in range for delhivery types b2b*/
  const checkWeightInBetweenDelhiveryHeavy = () => {
    const selectedDeliveryType =
      commercialDetails?.info?.deliveryPartners?.filter((item) => {
        return item.deliveryType === TYPE_DELHIVERY_B2B;
      });
    if (selectedDeliveryType?.length === 0) {
      return false;
    }
    setCostboPremierDeliverETAHeavy(selectedDeliveryType?.[0]?.deliveryETA);

    if (
      totalCartWeightKg > selectedDeliveryType?.[0]?.b2bStart &&
      totalCartWeightKg < selectedDeliveryType?.[0]?.packageRange?.[0]?.to
    ) {
      setCostboPremierHeavy({
        ...costboPremierHeavy,
        show: true,
      });
      return true;
    }
    return false;
  };
  /*End Check if weight is in range for delhivery types b2c*/

  /*Start check additional discount Available*/
  const checkAdditionalDiscount = (distanceInKm) => {
    if (commercialDetails?.info?.additionalDiscount?.length > 0) {
      const discountArray = commercialDetails?.info?.additionalDiscount?.filter(
        (item) => {
          if (
            distanceInKm > item?.distanceStart &&
            distanceInKm < item?.distanceEnd
          )
            return item;
        }
      );

      if (discountArray?.length > 0) {
        additionalDiscountCheck = true;
        additionalDiscount = discountArray?.[0]?.discountPercentage;
        additionalDiscountTypeValue = discountArray?.[0]?.discountType;
      }
    }
  };

  const calculateDiscountShipmentAmount = (shipingCost) => {
    let newShippingCost =
      shipingCost - (additionalDiscount * shipingCost) / 100;
    return newShippingCost;
  };

  /*Start Check if any delhivery types are their and calculate the shipment money*/
  const checkBusinessDeliveryAvailability = () => {
    const pincode = activeBusinessData?.viewBusiness?.businessArea?.filter(
      (item) => {
        return item.code === "pincode";
      }
    );
    const city = activeBusinessData?.viewBusiness?.businessArea?.filter(
      (item) => {
        return item.code === "City";
      }
    );
    const state = activeBusinessData?.viewBusiness?.businessArea?.filter(
      (item) => {
        return item.code === "State";
      }
    );
    dispatch(checkDeliveryAvailabilityDetails(pincode?.[0]?.value)).then(
      (res) => {
        console.log("checkDeliveryAvailabilityDetails", res);
        if (res?.payload?.delivery_codes?.[0]?.postal_code?.pre_paid === "N") {
          console.log("checkDeliveryAvailabilityDetails____", res);
          b2bDelhiveryCheck = false;
          b2cDelhiveryCheck = false;
          if (
            !b2bDelhiveryCheck &&
            !b2cDelhiveryCheck &&
            !businessDeliveryCheck
          ) {
            setErrorMessage(
              "Delivery not available for this address, Please select a different address. Sorry for the inconvenience"
            );
          }
          // setErrorMessage(
          //   'Delivery temporarily not available from the business. Sorry for the inconvenience'
          // )
          // setIsverifyBusinessAvailability(false)
        } else {
          dispatch(checkDeliveryAvailabilityDetails(seletedAddress?.zip)).then(
            (res) => {
              console.log("checkDeliveryAvailabilityDetails", res);
              if (
                res?.payload?.delivery_codes?.[0]?.postal_code?.pre_paid === "N"
              ) {
                b2bDelhiveryCheck = false;
                b2cDelhiveryCheck = false;
                if (
                  !b2bDelhiveryCheck &&
                  !b2cDelhiveryCheck &&
                  !businessDeliveryCheck
                ) {
                  setErrorMessage(
                    "Delivery not available for this address, Please select a different address. Sorry for the inconvenience"
                  );
                }
                // setErrorMessage(
                //   'Delivery temporarily not available to your address. Sorry for the inconvenience'
                // )
                // setIsverifyUserAvailability(false)
              } else {
                if (b2cDelhiveryCheck) {
                  b2cDelhiveryCheck = checkWeightInBetweenDelhivery();
                  /*Start calculating shipment amount for costBo Primer b2c*/
                  if (b2cDelhiveryCheck) {
                    dispatch(
                      delhiveryCostCalculationDetails({
                        d_zipcode: pincode?.[0]?.value,
                        o_zipcode: seletedAddress?.zip,
                        cgm: totalCartWeightGm,
                        md: "S",
                        pt: "prepaid",
                        ss: "Delivered",
                        cl: "COSTBO SURFACE",
                      })
                    )
                      .then((res) => {
                        console.log("delhiveryCostCalculationDetails", res);

                        let shipmentTax =
                          Number(
                            res?.payload?.[0]?.tax_data?.swacch_bharat_tax || 0
                          ) +
                          Number(res?.payload?.[0]?.tax_data?.IGST || 0) +
                          Number(res?.payload?.[0]?.tax_data?.SGST || 0) +
                          Number(
                            res?.payload?.[0]?.tax_data?.service_tax || 0
                          ) +
                          Number(
                            res?.payload?.[0]?.tax_data?.krishi_kalyan_cess || 0
                          ) +
                          Number(res?.payload?.[0]?.tax_data?.CGST || 0);

                        console.log("shipmentTaxshipmentTax", shipmentTax);
                        if (isFreeShipping) {
                          setCostboPremier({
                            ...costboPremier,
                            originalAmount: res?.payload?.[0]?.total_amount,
                            totalAmount: 0,
                            show: true,
                            type: "immediateDiscount",
                            shipmentTax: shipmentTax,
                          });
                        } else {
                          if (
                            additionalDiscountCheck &&
                            additionalDiscount > 0
                          ) {
                            let newDiscountAmount =
                              calculateDiscountShipmentAmount(
                                res?.payload?.[0]?.total_amount
                              );
                            setCostboPremier({
                              ...costboPremier,
                              originalAmount: res?.payload?.[0]?.total_amount,
                              totalAmount: newDiscountAmount,
                              show: true,
                              type: additionalDiscountTypeValue,
                              shipmentTax: shipmentTax,
                            });
                          } else {
                            setCostboPremier({
                              ...costboPremier,
                              totalAmount: res?.payload?.[0]?.total_amount,
                              show: true,
                              type: "none",
                              shipmentTax: shipmentTax,
                            });
                          }
                        }
                      })
                      .catch(() => {
                        setCostboPremier({
                          ...costboPremier,
                          error: true,
                        });
                      });
                  }
                }
                /*End calculating shipment amount for costBo Primer b2c*/
                dispatch(
                  delhiveryServiceabilityDetails({
                    fromPin: pincode?.[0]?.value,
                    toPin: seletedAddress?.zip,
                  })
                ).then((res) => {
                  if (res?.payload?.isServiceable?.serviceable) {
                    if (b2bDelhiveryCheck) {
                      b2bDelhiveryCheck = checkWeightInBetweenDelhiveryHeavy();

                      /*Start calculating shipment amount for costBo heavry b2b*/
                      if (b2bDelhiveryCheck) {
                        dispatch(
                          delhiveryShipmentPriceDetails({
                            fromPin: pincode?.[0]?.value,
                            toPin: seletedAddress?.zip,
                            fromCity: city?.[0]?.value,
                            toCity: seletedAddress?.city,
                            fromState: state?.[0]?.value,
                            toState: seletedAddress?.state,
                            weight: totalCartWeightKg,
                          })
                        )
                          .then((res) => {
                            console.log("delhiveryCostCalculationDetails", res);
                            if (isFreeShipping) {
                              setCostboPremierHeavy({
                                ...costboPremierHeavy,
                                originalAmount:
                                  res?.payload?.priceInfo?.totalShippingCost,
                                totalAmount: 0,
                                show: true,
                                type: "immediateDiscount",
                                shipmentTax:
                                  res?.payload?.priceInfo?.shippingTax,
                              });
                            } else {
                              if (
                                additionalDiscountCheck &&
                                additionalDiscount > 0
                              ) {
                                let newDiscountAmount =
                                  calculateDiscountShipmentAmount(
                                    res?.payload?.priceInfo?.totalShippingCost
                                  );
                                setCostboPremierHeavy({
                                  ...costboPremierHeavy,
                                  originalAmount:
                                    res?.payload?.priceInfo?.totalShippingCost,
                                  totalAmount: newDiscountAmount,
                                  show: true,
                                  type: additionalDiscountTypeValue,
                                  shipmentTax:
                                    res?.payload?.priceInfo?.shippingTax,
                                });
                              } else {
                                setCostboPremierHeavy({
                                  ...costboPremierHeavy,
                                  totalAmount:
                                    res?.payload?.priceInfo?.totalShippingCost,
                                  show: true,
                                  type: "none",
                                  shipmentTax:
                                    res?.payload?.priceInfo?.shippingTax,
                                });
                              }
                            }
                          })
                          .catch(() => {
                            setCostboPremierHeavy({
                              ...costboPremier,
                              error: true,
                            });
                          });
                      }
                    }
                  } else {
                    b2bDelhiveryCheck = false;
                    if (
                      !b2bDelhiveryCheck &&
                      !b2cDelhiveryCheck &&
                      !businessDeliveryCheck
                    ) {
                      setErrorMessage(
                        "Delivery not available for this address, Please select a different address. Sorry for the inconvenience"
                      );
                    }
                  }
                  /*End calculating shipment amount for costBo heavry b2b*/
                  console.log(
                    "HITTTT",
                    b2bDelhiveryCheck,
                    b2cDelhiveryCheck,
                    businessDeliveryCheck
                  );
                  console.log("delhiveryServiceabilityDetails", res);

                  if (
                    !b2bDelhiveryCheck &&
                    !b2cDelhiveryCheck &&
                    !businessDeliveryCheck
                  ) {
                    setErrorMessage(
                      "Delivery not available for this address, Please select a different address. Sorry for the inconvenience"
                    );
                  }
                });
              }
            }
          );
        }
      }
    );
  };
  /*End Check if any delhivery types are their and calculate the shipment money*/

  /*Start Check if business regular delivery is there*/
  const checkBusinessDelivery = (distanceInKm) => {
    console.log("distanceInKmdistanceInKm", distanceInKm);
    const selectedBusinessCategory =
      commercialDetails?.info?.shipmentCost?.filter((item) => {
        return (
          cartDetails?.orderTotalProductCost >= item.txnAmount &&
          cartDetails?.orderTotalProductCost <= item.txnAmount2
        );
      });
    if (selectedBusinessCategory.length > 0) {
      if (isFreeShipping) {
        setBusinessDelivery({
          originalAmount: selectedBusinessCategory[0].byFor,
          totalAmount: 0,
          show: true,
          deliveryETA: commercialDetails?.info?.shipmentETA?.[0],
          type: "immediateDiscount",
        });
      } else if (
        commercialDetails?.info?.freeShipment?.[0] > 0 &&
        distanceInKm <= commercialDetails?.info?.freeShipment?.[0]
      ) {
        console.log("true block hit");
        setBusinessDelivery({
          originalAmount: selectedBusinessCategory[0].byFor,
          totalAmount: 0,
          show: true,
          deliveryETA: commercialDetails?.info?.shipmentETA?.[0],
          freeShipment: true,
          type: "immediateDiscount",
        });
      } else {
        if (additionalDiscountCheck && additionalDiscount > 0) {
          let newDiscountAmount = calculateDiscountShipmentAmount(
            selectedBusinessCategory[0].byFor
          );
          setBusinessDelivery({
            originalAmount: selectedBusinessCategory[0].byFor,
            totalAmount: newDiscountAmount,
            show: true,
            deliveryETA: commercialDetails?.info?.shipmentETA?.[0],
            type: additionalDiscountTypeValue,
          });
        } else {
          setBusinessDelivery({
            totalAmount: selectedBusinessCategory[0].byFor,
            show: true,
            deliveryETA: commercialDetails?.info?.shipmentETA?.[0],
            type: "none",
          });
        }
      }
    } else {
      businessDeliveryCheck = false;
      if (!b2bDelhiveryCheck && !b2cDelhiveryCheck && !businessDeliveryCheck) {
        setErrorMessage(
          "Delivery not available for this address, Please select a different address. Sorry for the inconvenience"
        );
      }
    }
  };
  /*End Check if business regular delivery is there*/

  /*Start Check if Telyport Delivery is there*/
  const checkTelyportDelivery = (distanceInKm) => {
    let telyportNormalDelivery = false;
    let telyportStandardOnlyDelivery = false;

    const minutes = moment().minute();
    const hours = moment().hour();
    const day = moment().day();
    const dayName = moment().format("ddd");
    const currentTimeInMinutes = Number(hours) * 60 + Number(minutes);
    const currentTimeIn24HrsFormat = moment().format("HH:mm");

    const filteredDay = commercialDetails?.info?.businessTiming?.filter(
      (item) => item?.dayOfWeek == day + 1
    );
    const businessAddress = commercialDetails?.info?.loc;
    const deliveryPartnerInfo =
      commercialDetails?.info?.deliveryPartners?.filter((item) => {
        return item.deliveryType == "telyport";
      });
    const deliveryInfo = commercialDetails?.info?.deliveryTypeInfo?.filter(
      (item) => {
        return item.type === "telyport";
      }
    );

    if (
      filteredDay?.[0]?.from <= currentTimeInMinutes &&
      Number(filteredDay?.[0]?.to) -
        Number(deliveryPartnerInfo?.[0]?.pickupSlaMins) >=
        currentTimeInMinutes
    ) {
      telyportNormalDelivery = true;
      telyportStandardOnlyDelivery = false;
    } else {
      telyportStandardOnlyDelivery = true;
      telyportNormalDelivery = false;
    }
    let telyportRushEnabled = false;
    let telyportExpressEnabled = false;
    let telyportStandardEnabled = false;

    const rush = deliveryPartnerInfo?.[0]?.ranges?.filter(
      (item) => item?.code == "Rush"
    );
    const express = deliveryPartnerInfo?.[0]?.ranges?.filter(
      (item) => item?.code == "Express"
    );
    const standard = deliveryPartnerInfo?.[0]?.ranges?.filter(
      (item) => item?.code == "Standard"
    );

    telyportRushEnabled = deliveryInfo?.[0]?.deliveryTime?.includes("rush");
    telyportExpressEnabled =
      deliveryInfo?.[0]?.deliveryTime?.includes("express");
    telyportStandardEnabled =
      deliveryInfo?.[0]?.deliveryTime?.includes("standard");

    // if (rush?.length > 0) {
    //   telyportRushEnabled = true
    // }
    // if (express?.length > 0) {
    //   telyportExpressEnabled = true
    // }
    // if (standard?.length > 0) {
    //   telyportStandardEnabled = true
    // }

    const weightRange = deliveryPartnerInfo?.[0]?.packageRange?.filter(
      (item) => {
        return totalCartWeightKg >= item?.from && totalCartWeightKg <= item?.to;
      }
    );

    if (weightRange?.length == 0) {
      telyportDeliveryCheck = false;
    }

    checkTelyportRegion({
      from: `${businessAddress?.y},${businessAddress?.x}`,
      to: `${seletedAddress?.latitude},${seletedAddress?.longitude}`,
    }).then((response) => {
      if (response?.status != "OK") {
        telyportDeliveryCheck = false;
      } else {
        console.log("Telyport region check data", response);
        if (telyportRushEnabled && !response?.shipType?.rush?.serving) {
          telyportRushEnabled = false;
        }
        if (telyportExpressEnabled && !response?.shipType?.express?.serving) {
          telyportExpressEnabled = false;
        }
        if (telyportStandardEnabled && !response?.shipType?.standard?.serving) {
          telyportStandardEnabled = false;
        }

        if (
          !telyportRushEnabled &&
          !telyportExpressEnabled &&
          !telyportStandardEnabled
        ) {
          console.log("Telyport Disabled");
          telyportDeliveryCheck = false;
        }

        if (telyportDeliveryCheck) {
          checkTelyportServiceTime().then((serviceResponse) => {
            if (
              serviceResponse?.success &&
              serviceResponse?.details?.status == "OK"
            ) {
              if (
                telyportRushEnabled &&
                !checkTelyportServiceTimeAvailable(
                  currentTimeIn24HrsFormat,
                  dayName?.toLowerCase(),
                  serviceResponse?.details?.serviceTime?.rush
                )
              ) {
                telyportRushEnabled = false;
              }
              if (
                telyportExpressEnabled &&
                !checkTelyportServiceTimeAvailable(
                  currentTimeIn24HrsFormat,
                  dayName?.toLowerCase(),
                  serviceResponse?.details?.serviceTime?.express
                )
              ) {
                telyportExpressEnabled = false;
              }
              // if (
              //   telyportStandardEnabled &&
              //   !checkTelyportServiceTimeAvailable(
              //     currentTimeIn24HrsFormat,
              //     dayName?.toLowerCase(),
              //     serviceResponse?.details?.serviceTime?.standard
              //   )
              // ) {
              //   telyportStandardEnabled = false
              // }
              if (
                !telyportRushEnabled &&
                !telyportExpressEnabled &&
                !telyportStandardEnabled
              ) {
                console.log("Telyport Disabled");
                telyportDeliveryCheck = false;
              }
              if (telyportDeliveryCheck) {
                getTelyportPrizing({
                  // origin: `${seletedAddress?.latitude},${seletedAddress?.longitude}`,
                  // destination: `${businessAddress?.y},${businessAddress?.x}`,
                  destination: {
                    lat: businessAddress?.y,
                    lng: businessAddress?.x,
                  },
                  origin: {
                    lat: seletedAddress?.latitude,
                    lng: seletedAddress?.longitude,
                  },
                }).then((res) => {
                  console.log("Telyport response", res);
                  let prizing = null;
                  Object.keys(res).forEach(function (key) {
                    //loop through keys array

                    console.log("Telyport response weightRange", weightRange);
                    if (weightRange?.[0]?.value == key) {
                      console.log("Telyport response 1", res?.[key]);
                      setTelyportPrizing(res?.[key]);
                      prizing = res?.[key];
                    }
                  });
                  if (telyportDeliveryCheck) {
                    if (
                      (telyportNormalDelivery ||
                        telyportStandardOnlyDelivery) &&
                      telyportStandardEnabled
                    ) {
                      if (isFreeShipping) {
                        setTelyportStandardDelivery({
                          originalAmount: prizing?.standardTotal,
                          totalAmount: 0,
                          show: true,
                          deliveryETA: standard?.[0]?.value,
                          type: "immediateDiscount",
                          shipmentTax: prizing?.standardTaxes,
                        });
                      }
                      // else if (
                      //   commercialDetails?.info?.freeShipment?.[0] > 0 &&
                      //   distanceInKm <=
                      //     commercialDetails?.info?.freeShipment?.[0]
                      // ) {
                      //   setTelyportStandardDelivery({
                      //     originalAmount: prizing?.standardTotal,
                      //     totalAmount: 0,
                      //     show: true,
                      //     deliveryETA: standard?.[0]?.value,
                      //     freeShipment: true,
                      //     type: 'immediateDiscount',
                      //     shipmentTax: prizing?.standardTaxes,
                      //   })
                      // }
                      else if (
                        additionalDiscountCheck &&
                        additionalDiscount > 0
                      ) {
                        let newDiscountAmount = calculateDiscountShipmentAmount(
                          prizing?.standardTotal
                        );
                        setTelyportStandardDelivery({
                          originalAmount: prizing?.standardTotal,
                          totalAmount: newDiscountAmount,
                          show: true,
                          deliveryETA: standard?.[0]?.value,
                          type: additionalDiscountTypeValue,
                          shipmentTax: prizing?.standardTaxes,
                        });
                      } else {
                        setTelyportStandardDelivery({
                          totalAmount: prizing?.standardTotal,
                          show: true,
                          deliveryETA: standard?.[0]?.value,
                          type: "none",
                          shipmentTax: prizing?.standardTaxes,
                        });
                      }
                    }
                    if (telyportNormalDelivery && telyportRushEnabled) {
                      if (isFreeShipping) {
                        setTelyportRushDelivery({
                          originalAmount: prizing?.rushTotal,
                          shipmentTax: prizing?.rushTaxes,
                          totalAmount: 0,
                          show: true,
                          deliveryETA: rush?.[0]?.value,
                          type: "immediateDiscount",
                        });
                      }
                      // else if (
                      //   commercialDetails?.info?.freeShipment?.[0] > 0 &&
                      //   distanceInKm <=
                      //     commercialDetails?.info?.freeShipment?.[0]
                      // ) {
                      //   setTelyportRushDelivery({
                      //     originalAmount: prizing?.rushTotal,
                      //     totalAmount: 0,
                      //     show: true,
                      //     deliveryETA: rush?.[0]?.value,
                      //     freeShipment: true,
                      //     shipmentTax: prizing?.rushTaxes,
                      //     type: 'immediateDiscount',
                      //   })
                      // }
                      else if (
                        additionalDiscountCheck &&
                        additionalDiscount > 0
                      ) {
                        let newDiscountAmount = calculateDiscountShipmentAmount(
                          prizing?.rushTotal
                        );
                        setTelyportRushDelivery({
                          originalAmount: prizing?.rushTotal,
                          totalAmount: newDiscountAmount,
                          show: true,
                          deliveryETA: rush?.[0]?.value,
                          type: additionalDiscountTypeValue,
                          shipmentTax: prizing?.rushTaxes,
                        });
                      } else {
                        setTelyportRushDelivery({
                          totalAmount: prizing?.rushTotal,
                          show: true,
                          deliveryETA: rush?.[0]?.value,
                          shipmentTax: prizing?.rushTaxes,
                          type: "none",
                        });
                      }
                    }
                    if (telyportNormalDelivery && telyportExpressEnabled) {
                      if (isFreeShipping) {
                        setTelyportExpressDelivery({
                          // originalAmount: prizing?.expressTotal,
                          originalAmount: prizing?.xpressTotal,
                          totalAmount: 0,
                          show: true,
                          deliveryETA: express?.[0]?.value,
                          type: "immediateDiscount",
                          // shipmentTax: prizing?.expressTaxes,
                          shipmentTax: prizing?.xpressTaxes,
                        });
                      }
                      // else if (
                      //   commercialDetails?.info?.freeShipment?.[0] > 0 &&
                      //   distanceInKm <=
                      //     commercialDetails?.info?.freeShipment?.[0]
                      // ) {
                      //   setTelyportExpressDelivery({
                      //     originalAmount: prizing?.expressTotal,
                      //     totalAmount: 0,
                      //     show: true,
                      //     deliveryETA: express?.[0]?.value,
                      //     freeShipment: true,
                      //     type: 'immediateDiscount',
                      //     shipmentTax: prizing?.expressTaxes,
                      //   })
                      // }
                      else if (
                        additionalDiscountCheck &&
                        additionalDiscount > 0
                      ) {
                        let newDiscountAmount = calculateDiscountShipmentAmount(
                          prizing?.xpressTotal
                        );
                        setTelyportExpressDelivery({
                          originalAmount: prizing?.xpressTotal,
                          totalAmount: newDiscountAmount,
                          show: true,
                          deliveryETA: express?.[0]?.value,
                          type: additionalDiscountTypeValue,
                          shipmentTax: prizing?.xpressTaxes,
                        });
                      } else {
                        setTelyportExpressDelivery({
                          totalAmount: prizing?.xpressTotal,
                          show: true,
                          deliveryETA: express?.[0]?.value,
                          type: "none",
                          shipmentTax: prizing?.xpressTaxes,
                        });
                      }
                    }
                    if (telyportStandardOnlyDelivery && telyportRushEnabled) {
                      if (isFreeShipping) {
                        setTelyportRushDelivery({
                          originalAmount: prizing?.rushTotal,
                          totalAmount: 0,
                          show: true,
                          error: true,
                          deliveryETA: rush?.[0]?.value,
                          type: "immediateDiscount",
                          shipmentTax: prizing?.rushTaxes,
                        });
                      }
                      // else if (
                      //   commercialDetails?.info?.freeShipment?.[0] > 0 &&
                      //   distanceInKm <=
                      //     commercialDetails?.info?.freeShipment?.[0]
                      // ) {
                      //   setTelyportRushDelivery({
                      //     originalAmount: prizing?.rushTotal,
                      //     totalAmount: 0,
                      //     show: true,
                      //     error: true,
                      //     deliveryETA: rush?.[0]?.value,
                      //     freeShipment: true,
                      //     type: 'immediateDiscount',
                      //     shipmentTax: prizing?.rushTaxes,
                      //   })
                      // }
                      else if (
                        additionalDiscountCheck &&
                        additionalDiscount > 0
                      ) {
                        let newDiscountAmount = calculateDiscountShipmentAmount(
                          prizing?.rushTotal
                        );
                        setTelyportRushDelivery({
                          originalAmount: prizing?.rushTotal,
                          totalAmount: newDiscountAmount,
                          show: true,
                          deliveryETA: rush?.[0]?.value,
                          error: true,
                          type: additionalDiscountTypeValue,
                          shipmentTax: prizing?.rushTaxes,
                        });
                      } else {
                        setTelyportRushDelivery({
                          totalAmount: prizing?.rushTotal,
                          show: true,
                          deliveryETA: rush?.[0]?.value,
                          error: true,
                          type: "none",
                          shipmentTax: prizing?.rushTaxes,
                        });
                      }
                    }
                    if (
                      telyportStandardOnlyDelivery &&
                      telyportExpressEnabled
                    ) {
                      if (isFreeShipping) {
                        setTelyportExpressDelivery({
                          originalAmount: prizing?.xpressTotal,
                          totalAmount: 0,
                          show: true,
                          error: true,
                          deliveryETA: express?.[0]?.value,
                          type: "immediateDiscount",
                          shipmentTax: prizing?.xpressTaxes,
                        });
                      }
                      // else if (
                      //   commercialDetails?.info?.freeShipment?.[0] > 0 &&
                      //   distanceInKm <=
                      //     commercialDetails?.info?.freeShipment?.[0]
                      // ) {
                      //   setTelyportExpressDelivery({
                      //     originalAmount: prizing?.expressTotal,
                      //     totalAmount: 0,
                      //     error: true,
                      //     show: true,
                      //     deliveryETA: express?.[0]?.value,
                      //     freeShipment: true,
                      //     type: 'immediateDiscount',
                      //     shipmentTax: prizing?.expressTaxes,
                      //   })
                      // }
                      else if (
                        additionalDiscountCheck &&
                        additionalDiscount > 0
                      ) {
                        let newDiscountAmount = calculateDiscountShipmentAmount(
                          prizing?.xpressTotal
                        );
                        setTelyportExpressDelivery({
                          originalAmount: prizing?.xpressTotal,
                          totalAmount: newDiscountAmount,
                          show: true,
                          error: true,
                          deliveryETA: express?.[0]?.value,
                          type: additionalDiscountTypeValue,
                          shipmentTax: prizing?.xpressTaxes,
                        });
                      } else {
                        setTelyportExpressDelivery({
                          totalAmount: prizing?.xpressTotal,
                          show: true,
                          error: true,
                          deliveryETA: express?.[0]?.value,
                          type: "none",
                          shipmentTax: prizing?.xpressTaxes,
                        });
                      }
                    }
                  }
                });
              }
              console.log(
                "Telyport service time check data",
                serviceResponse,
                dayName,
                currentTimeIn24HrsFormat
              );
            }
          });
        }
      }
    });

    console.log(
      "Telyport response check:",
      // distanceInKm,
      // commercialDetails?.info?.businessTiming,
      // currentTimeInMinutes,
      // day,
      // filteredDay,
      // deliveryPartnerInfo,
      weightRange,
      totalCartWeightKg
      // seletedAddress,
      // businessAddress
    );
  };
  /*End Check if Telyport Delivery is there*/

  /*Start Check if ONDC Delivery is there*/
  const checkOndcDelivery = async () => {
    const businessAddress = commercialDetails?.info;
    const payloadData = prepareOndcPricePayloadData(
      cartDetails,
      seletedAddress,
      businessAddress,
      totalCartWeightGm,
      commercialDetails?.info?.immediateDiscount
    );

    const ondcPricingRes = await getOndcPrizing(payloadData);

    if (ondcPricingRes?.status === "serviceable") {
      const timeToShip = getTimeToShip(allProducts, cartDetails);

      const storeTime = getTimeUntilOpen(
        commercialDetails?.info?.ondc?.openDays,
        commercialDetails?.info?.ondc?.operatingHours
      );

      console.log(
        "storeTimestoreTime",
        storeTime,
        getTimeUntilOpen("1,2,4,5,6,7", ["1000", "1800"]),
        moment().format("HH:MM"),
        timeToShip
      );

      const deliveryETA = getFormattedDeliveryTime(
        timeToShip,
        ondcPricingRes?.estimateDeliveryTimeInMin,
        storeTime
      );

      setOndcDelivery({
        totalAmount: ondcPricingRes?.shippingCost,
        originalAmount: ondcPricingRes?.freeShipping
          ? ondcPricingRes?.rtoCost
          : ondcPricingRes?.shippingTax,
        show: true,
        error: false,
        deliveryETA,
        type: ondcPricingRes?.freeShipping ? "immediateDiscount" : "none",
        shipmentTax: ondcPricingRes?.shippingTax,
        name: "ONDC " + ondcPricingRes?.shipType?.split(" ")[0],
        shipType: ondcPricingRes?.shipType,
        providerId: ondcPricingRes?.providerId,
        estimateDeliveryTimeInMin: ondcPricingRes?.estimateDeliveryTimeInMin,
      });

      // if (isFreeShipping) {
      //   setOndcDelivery({
      //     totalAmount: 0,
      //     originalAmount: ondcPricingRes?.shippingCost,
      //     show: true,
      //     error: false,
      //     deliveryETA,
      //     type: "immediateDiscount",
      //     shipmentTax: ondcPricingRes?.shippingTax,
      //     name: ondcPricingRes?.shipType,
      //     providerId: ondcPricingRes?.providerId,
      //   });
      // } else if (additionalDiscountCheck && additionalDiscount > 0) {
      //   let newDiscountAmount = calculateDiscountShipmentAmount(
      //     ondcPricingRes?.shippingCost
      //   );

      //   setOndcDelivery({
      //     totalAmount: newDiscountAmount,
      //     originalAmount: ondcPricingRes?.shippingCost,
      //     show: true,
      //     error: false,
      //     deliveryETA,
      //     type: additionalDiscountTypeValue,
      //     shipmentTax: ondcPricingRes?.shippingTax,
      //     name: ondcPricingRes?.shipType,
      //     providerId: ondcPricingRes?.providerId,
      //   });
      // } else {

      // }
    }

    console.log("ondcPricingRes", ondcPricingRes);
  };
  /*End Check if ONDC Delivery is there*/

  /*

  /*Start DeliveryMethod check*/
  const selectDeliveryMethod = (value) => {
    setDeliveryMethod(value);
    if (value === "costbo-premier") {
      costboPremierSelected();
      return;
    }
    if (value === "costbo-heavy") {
      costboHeavySelected();
      return;
    }
    if (value === "regular-delivery") {
      regularDeleverySelected();
      return;
    }
    if (value === "rush") {
      rushSelected();
      return;
    }
    if (value === "express") {
      expressSelected();
      return;
    }
    if (value === "standard") {
      standardSelected();
      return;
    }
    if (value === "ondc-delivery") {
      ondcSelected();
      return;
    }
  };
  /*End DeliveryMethod check*/

  /*Start Costbo Premier Delivery Method selected*/
  const costboPremierSelected = () => {
    checkCalculateCodDelhivery();
    const deliveryTypeArray = commercialDetails?.info?.deliveryTypeInfo?.filter(
      (item) => {
        return item.type === TYPE_DELHIVERY;
      }
    );
    let deliveryType = {
      from: deliveryTypeArray?.[0]?.from,
      to: deliveryTypeArray?.[0]?.to,
      type: TYPE_DELHIVERY,
      unit: KILOMETER_KM,
      shipType: deliveryTypeArray?.[0]?.deliveryTime?.[0],
    };
    setActiveDeliveryType(deliveryType);
    setPackageInfo({
      ...packageInfo,
      Weight: totalCartWeightKg,
      type: "DelhiveryType",
    });
    setDeliveryMethodData("Customer location");
    console.log("costbo-premier");
    return;
  };
  /*End Costbo Premier Delivery Method selected*/

  /*Start Costbo Heavy Delivery Method selected*/
  const costboHeavySelected = () => {
    setCodAvailable(false);
    const deliveryTypeArray = commercialDetails?.info?.deliveryTypeInfo?.filter(
      (item) => {
        return item.type === TYPE_DELHIVERY_B2B;
      }
    );
    let deliveryType = {
      from: deliveryTypeArray?.[0]?.from,
      to: deliveryTypeArray?.[0]?.to,
      type: TYPE_DELHIVERY_B2B,
      unit: KILOMETER_KM,
      shipType: deliveryTypeArray?.[0]?.deliveryTime?.[0],
    };
    seCashOnDelivery(false);
    setIsAdditionalDiscount(false);
    setActiveDeliveryType(deliveryType);
    setDeliveryMethodData("Customer location");
    setPackageInfo({
      ...packageInfo,
      Weight: totalCartWeightKg,
      type: TYPE_DELHIVERY_B2B,
    });
    console.log("costbo-heavy");
    return;
  };
  /*End Costbo Heavy Delivery Method selected*/

  /*Start Regular Delivery Method selected*/
  const regularDeleverySelected = () => {
    setCodAvailable(false);
    const deliveryTypeArray = commercialDetails?.info?.deliveryTypeInfo?.filter(
      (item) => {
        return item.type === TYPE_BUSINESS;
      }
    );
    let deliveryType = {
      from: deliveryTypeArray?.[0]?.from,
      to: deliveryTypeArray?.[0]?.to,
      type: TYPE_BUSINESS,
      unit: KILOMETER_KM,
      shipType: SHIP_TYPE_REGULAR,
    };
    setActiveDeliveryType(deliveryType);
    setDeliveryMethodData("Customer location");
    setPackageInfo({
      ...packageInfo,
      Weight: totalCartWeightKg,
      type: "regulartype",
    });
    console.log("regular-delivery");
    return;
  };
  /*End Regular Delivery Method selected*/

  /*Start Rush Delivery Method selected*/
  const rushSelected = () => {
    setCodAvailable(false);
    const deliveryTypeArray = commercialDetails?.info?.deliveryTypeInfo?.filter(
      (item) => {
        return item.type === "telyport";
      }
    );
    let deliveryType = {
      from: deliveryTypeArray?.[0]?.from,
      to: deliveryTypeArray?.[0]?.to,
      type: TYPE_TELYPORT,
      unit: KILOMETER_KM,
      shipType: "rush",
    };
    seCashOnDelivery(false);
    setIsAdditionalDiscount(false);
    setActiveDeliveryType(deliveryType);
    setDeliveryMethodData("Customer location");
    setPackageInfo({
      ...packageInfo,
      Weight: totalCartWeightKg,
      type: "rush",
    });
    console.log("rush");
    return;
  };
  /*End Rush Delivery Method selected*/

  /*Start Express Delivery Method selected*/
  const expressSelected = () => {
    setCodAvailable(false);
    const deliveryTypeArray = commercialDetails?.info?.deliveryTypeInfo?.filter(
      (item) => {
        return item.type === "telyport";
      }
    );
    let deliveryType = {
      from: deliveryTypeArray?.[0]?.from,
      to: deliveryTypeArray?.[0]?.to,
      type: TYPE_TELYPORT,
      unit: KILOMETER_KM,
      shipType: "xpress",
    };
    seCashOnDelivery(false);
    setIsAdditionalDiscount(false);
    setActiveDeliveryType(deliveryType);
    setDeliveryMethodData("Customer location");
    setPackageInfo({
      ...packageInfo,
      Weight: totalCartWeightKg,
      type: "xpress",
    });
    console.log("express");
    return;
  };
  /*End Express Delivery Method selected*/

  /*Start Standard Delivery Method selected*/
  const standardSelected = () => {
    setCodAvailable(false);
    const deliveryTypeArray = commercialDetails?.info?.deliveryTypeInfo?.filter(
      (item) => {
        return item.type === "telyport";
      }
    );
    let deliveryType = {
      from: deliveryTypeArray?.[0]?.from,
      to: deliveryTypeArray?.[0]?.to,
      type: TYPE_TELYPORT,
      unit: KILOMETER_KM,
      shipType: "standard",
    };
    seCashOnDelivery(false);
    setIsAdditionalDiscount(false);
    setActiveDeliveryType(deliveryType);
    setDeliveryMethodData("Customer location");
    setPackageInfo({
      ...packageInfo,
      Weight: totalCartWeightKg,
      type: "standard",
    });
    console.log("standard");
    return;
  };
  /*End Standard Delivery Method selected*/

  /*Start ONDC Delivery Method selected*/
  const ondcSelected = () => {
    setCodAvailable(false);

    let deliveryType = {
      from: 0,
      to: 0,
      type: "ondc",
      unit: KILOMETER_KM,
      shipType: "Immediate Delivery",
      providerId: ondcDelivery?.providerId || "OLA",
      estimateDeliveryTimeInMin: ondcDelivery?.estimateDeliveryTimeInMin,
      bufferTimeToApprove: "",
    };
    seCashOnDelivery(false);
    setIsAdditionalDiscount(false);
    setActiveDeliveryType(deliveryType);
    setDeliveryMethodData("Customer location");
    setPackageInfo({
      ...packageInfo,
      Weight: totalCartWeightKg,
      type: "Immediate Delivery",
    });
    console.log("Immediate Delivery");
    return;
  };
  /*End ONDC Delivery Method selected*/

  /*Start check and Calculate Cod for Delhivery*/
  const checkCalculateCodDelhivery = () => {
    if (
      commercialDetails?.info?.codInfo &&
      commercialDetails?.info?.codInfo?.length > 0
    ) {
      const delhiveryArray = commercialDetails?.info?.codInfo?.filter(
        (item) => {
          return item.type === TYPE_DELHIVERY;
        }
      );
      if (delhiveryArray?.length > 0) {
        if (
          // delhiveryArray?.[0]?.chargeTo === 'customer' &&
          delhiveryArray?.[0]?.limit > cartDetails?.orderTotalProductCost
        ) {
          let codCostAmount =
            ((Number(cartDetails?.orderTotalProductCost) +
              Number(shipmentValueCod)) *
              delhiveryArray?.[0]?.codPercent) /
            100;
          if (codCostAmount < delhiveryArray?.[0]?.codFlatCharge) {
            codCostAmount = delhiveryArray?.[0]?.codFlatCharge;
          }

          setCodChargeTo(delhiveryArray?.[0]?.chargeTo);
          setCodAvailable(true);
          setCodCost(codCostAmount.toFixed(2));
        }
      }
    }
  };
  /*End check and Calculate Cod for Delhivery*/

  /*Start of Load Script*/
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };
  /*End of Load Script*/

  /*Start of Open RazorPay*/
  const openRazorPay = async (razorpayData) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      setConfirmPayLoader(false);
      return;
    }

    const paymentObject = new window.Razorpay(razorpayData);
    paymentObject.open();
    setConfirmPayLoader(false);
  };
  /*End of Open RazorPay*/

  /*Start of RazorPay Success Handler*/
  async function handleRazorPaySuccess(response) {
    if (
      response.razorpay_payment_id !== "" ||
      (response.razorpay_payment_id !== null &&
        response.razorpay_order_id !== "") ||
      (response.razorpay_order_id !== null &&
        response.razorpay_signature !== "") ||
      response.razorpay_signature !== null
    ) {
      // place unicommerce order
      // const unicomSaleOrder = await unicomCreateSaleOrder();

      //Preparing send Order Message Data to Customer
      const messageToCustomer = sendOrderMessageHelper({
        type: "toCustomer",
        cartDetails,
        seletedAddress,
        deliveryMethod,
      });

      //Preparing send Order Message Data to Business
      const messageToBusiness = sendOrderMessageHelper({
        type: "toBusiness",
        cartDetails,
        seletedAddress,
        deliveryMethod,
      });

      //Preparing send Order Notification Data
      const sendOrderNotificationData = sendOrderNotificationHelper({
        cartDetails,
        seletedAddress,
        deliveryMethod,
      });

      dispatch(
        sendOrderMessage({
          postData: messageToCustomer,
          cartId: cartDetails?.cartId,
        })
      );
      dispatch(
        sendOrderMessage({
          postData: messageToBusiness,
          cartId: cartDetails?.cartId,
        })
      );
      dispatch(sendOrderNotification(sendOrderNotificationData));
      sessionStorage.setItem("cartData", null);
      // navigate(`/${businessName}/confirmation`, {
      //   state: { cartId: cartDetails?.cartId },
      // });
      navigateTo(navigate, `confirmation`, {state: { cartId: cartDetails?.cartId }})
    } else {
      setTimeout(() => {
        // navigate(`/${businessName}`);
        navigateTo(navigate, '')
      }, 500000);
    }
  }
  /*End of RazorPay Success Handler*/

  /*Start on Confirm Pay*/
  const onConfirm = async () => {
    if (currentPage === "discount") {
      setCurrentPage("address-page");
      return;
    }

    if (checkIsUpdateRequired(seletedAddress?.updatedOn)) {
      setAlertOpen(true);
      setAlertMsg(updateLatLongMsg);
      return;
    }

    if (!seletedAddress) {
      setAlertOpen(true);
      setAlertMsg(selectDeliveryAddressMsg);
      return;
    }
    if (!deliveryMethod) {
      setAlertOpen(true);
      setAlertMsg(selectDeliveryMethodMsg);
      return;
    }

    if (
      cartDetails?.orderTotalProductCost + Number(shipmentAmount) >
      orderLimitAmount
    ) {
      setAlertOpen(true);
      setAlertMsg(transactionErrorMsg);
      return;
    }

    if (gstChecked && gstNumber.length !== 15 && gstSelected.length !== 15) {
      setAlertOpen(true);
      setAlertMsg("GST Number is not selected");
      return;
    }

    setConfirmPayLoader(true);

    if (commercialDetails?.info?.catalogPartnerInfo?.[0]?.status == "active") {
      const validationInfo = await validateCatalogPartnetInventory(
        cartDetails,
        commercialDetails
      );
      console.log("ValidationInfo__CatalogPartner__DONE:", validationInfo);

      if (validationInfo?.isError) {
        setCatalogPartnerValidationData(validationInfo)
        setAlertOpen(true);
        setAlertMsg(unicomInventoryErrMsg);
        setConfirmPayLoader(false);
        return;
      }
    }

    const gst = gstNumber?.length > 0 ? gstNumber : gstSelected;
    //Post data for update cart
    const updateCartData = checkOutUpdateCartHelper({
      cartDetails,
      seletedAddress,
      activeBusinessData,
      appliedDiscountDetails,
      crmOwnershipInfo,
      deliveryMethodData,
      packageInfo,
      activeDeliveryType,
      modeOfPayment: "E-Payment",
      totalOrderCost: calculateOrderTotalCost(
        cartDetails?.orderTotalProductCost,
        discountAmount,
        shipmentAmount
      ),
      consumerNotes,
      shipmentAmount: shipmentAmount,
      shipmentEta,
      deviceInfo,
      selectedDiscountType,
      shipmentTaxValue,
      gst,
    });

    //Post data for updateWebOrderPayment
    const webOrderPaymentData = updateWebOrderPaymentHelper({
      appliedDiscountDetails,
      crmOwnershipInfo,
      totalOrderCost: calculateOrderTotalCost(
        cartDetails?.orderTotalProductCost,
        discountAmount,
        shipmentAmount
      ),
      consumerNotes,
      shipmentAmount: shipmentAmount,
      packageInfo,
      selectedDiscountType,
      shipmentTaxValue,
    });

    //Post data for add payment process
    const addPaymentProcessData = addPaymentProcessHelper({
      cartId: cartDetails?.cartId,
      paymentAmount: calculateOrderTotalCost(
        cartDetails?.orderTotalProductCost,
        discountAmount,
        shipmentAmount
      ),
    });

    //Updating Cart
    dispatch(
      updateCartDetails({ ...updateCartData, id: cartDetails?.cartId })
    ).then((res) => {
      if (res?.payload?.success) {
        //getting order Id from updateWebOrderPayment
        dispatch(
          updateWebOrderPayment({
            ...webOrderPaymentData,
            cartId: cartDetails?.cartId,
          })
        ).then((webOrderRes) => {
          if (webOrderRes?.payload?.success) {
            //getting paymentProcessId from addPaymentProcess Api
            dispatch(addPaymentProcess(addPaymentProcessData)).then(
              (paymentProcessRes) => {
                if (paymentProcessRes?.payload?.success) {
                  //Preparing RazorpayOptions
                  const razorpayOptions = setRazorpayPaymentOptions({
                    orderId: webOrderRes?.payload?.id,
                    paymentInfo: paymentProcessRes?.payload?.paymentProcessId,
                    commercialDetails,
                    cartDetails,
                    orderTotalCost: calculateOrderTotalCost(
                      cartDetails?.orderTotalProductCost,
                      discountAmount,
                      shipmentAmount
                    ),
                    successHandler: handleRazorPaySuccess,
                  });
                  openRazorPay(razorpayOptions);
                }
              }
            );
          }
        });
      }
    });
  };
  /*End on Confirm Pay*/

  const confirmPaycartOrder = () => {
    if (currentPage === "discount") {
      setCurrentPage("address-page");
      return;
    }

    if (checkIsUpdateRequired(seletedAddress?.updatedOn)) {
      setAlertOpen(true);
      setAlertMsg(updateLatLongMsg);
      return;
    }

    if (!seletedAddress?.alternatePhoneNo) {
      setAlertMsg("Please update the Recipient number in the Delivery address");
      setAlertOpen(true);
      return;
    }

    if (!seletedAddress) {
      setAlertOpen(true);
      setAlertMsg(selectDeliveryAddressMsg);
      return;
    }

    if (!deliveryMethod) {
      setAlertOpen(true);
      setAlertMsg(selectDeliveryMethodMsg);
      return;
    }

    if (
      cartDetails?.orderTotalProductCost + Number(shipmentAmount) >
      orderLimitAmount
    ) {
      setAlertOpen(true);
      setAlertMsg(transactionErrorMsg);
      return;
    }

    if (gstChecked && gstNumber.length !== 15 && gstSelected.length !== 15) {
      setAlertOpen(true);
      setAlertMsg("GST Number is not selected");
      return;
    }

    setOpenConfirmAlert({
      isOpen: true,
      message: `Recipient number +91 ${
        seletedAddress?.alternatePhoneNo
      } will be used as point of contact ${
        matches ? `\n` : ""
      }by the delivery partners.`,
    });
  };

  const onPaycartPaymentLink = () => {
    setOpenConfirmAlert({
      isOpen: false,
      message: "",
    });

    if (currentPage === "discount") {
      setCurrentPage("address-page");
      return;
    }

    if (checkIsUpdateRequired(seletedAddress?.updatedOn)) {
      setAlertOpen(true);
      setAlertMsg(updateLatLongMsg);
      return;
    }

    if (!seletedAddress?.alternatePhoneNo) {
      setAlertMsg("Please update the Recipient number in the Delivery address");
      setAlertOpen(true);
      return;
    }

    if (!seletedAddress) {
      setAlertOpen(true);
      setAlertMsg(selectDeliveryAddressMsg);
      return;
    }
    if (!deliveryMethod) {
      setAlertOpen(true);
      setAlertMsg(selectDeliveryMethodMsg);
      return;
    }

    if (
      cartDetails?.orderTotalProductCost + Number(shipmentAmount) >
      orderLimitAmount
    ) {
      setAlertOpen(true);
      setAlertMsg(transactionErrorMsg);
      return;
    }

    if (gstChecked && gstNumber.length !== 15 && gstSelected.length !== 15) {
      setAlertOpen(true);
      setAlertMsg("GST Number is not selected");
      return;
    }

    //  setConfirmPayLoader(true)

    const gst = gstNumber?.length > 0 ? gstNumber : gstSelected;
    //Post data for update cart
    const updateCartData = checkOutUpdateCartHelper({
      cartDetails,
      seletedAddress,
      activeBusinessData,
      appliedDiscountDetails,
      crmOwnershipInfo,
      deliveryMethodData,
      packageInfo,
      activeDeliveryType,
      modeOfPayment: "E-Payment",
      totalOrderCost: calculateOrderTotalCost(
        cartDetails?.orderTotalProductCost,
        discountAmount,
        shipmentAmount
      ),
      consumerNotes,
      shipmentAmount: shipmentAmount,
      shipmentEta,
      deviceInfo,
      selectedDiscountType,
      shipmentTaxValue,
      gst,
    });

    const paycartUpdateData = paycartUpdateHelper({
      cartDetails,
      seletedAddress,
      activeBusinessData,
      appliedDiscountDetails,
      crmOwnershipInfo,
      deliveryMethodData,
      packageInfo,
      activeDeliveryType,
      modeOfPayment: "E-Payment",
      totalOrderCost: calculateOrderTotalCost(
        cartDetails?.orderTotalProductCost,
        discountAmount,
        shipmentAmount
      ),
      consumerNotes,
      shipmentAmount: shipmentAmount,
      shipmentEta,
      deviceInfo,
      selectedDiscountType,
      shipmentTaxValue,
      gst,
    });

    //Post data for updateWebOrderPayment
    const webOrderPaymentData = updateWebOrderPaymentHelper({
      appliedDiscountDetails,
      crmOwnershipInfo,
      totalOrderCost: calculateOrderTotalCost(
        cartDetails?.orderTotalProductCost,
        discountAmount,
        shipmentAmount
      ),
      consumerNotes,
      shipmentAmount: shipmentAmount,
      packageInfo,
      selectedDiscountType,
      shipmentTaxValue,
    });

    //Post data for add payment process
    const addPaymentProcessData = addPaymentProcessHelper({
      cartId: cartDetails?.cartId,
      paymentAmount: calculateOrderTotalCost(
        cartDetails?.orderTotalProductCost,
        discountAmount,
        shipmentAmount
      ),
    });

    setPaycartLoading(true);

    //Updating Cart
    dispatch(
      updateCartDetails({
        ...updateCartData,
        id: cartDetails?.cartId,
      })
    ).then((res) => {
      if (res?.payload?.success) {
        dispatch(
          addPaymentProcess({
            businessId: cartDetails?.businessId,
            businessName: "",
            profileId: cartDetails?.profileId,
            cartId: cartDetails?.cartId,
            contactName: "",
            razorPayId: "",
            contactEmail: "",
            country: "",
            paymentType: "",
            paymentGatewayCode: "",
            paymentGatewayText: "",
            currencyType: "",
            planOrigin: "",
            status: "",
            paymentOrderId: "",
            paymentDescription: "",
            fee: "",
            tax: "",
            errorCode: "",
            errorDescription: "",
            bank: "",
            wallet: "",
            vpa: "",
            paymentMethod: "",
            notes: "",
            paymentSubmittedOn: "",
            contactPhone: "",
            paymentCaptured: "",
            paymentApproved: "",
            paymentDeclined: "",
            paymentAmount: "",
          })
        ).then((paymentProcessRes) => {
          if (paymentProcessRes?.payload?.success) {
            dispatch(
              updateWebOrderPayment({
                ...webOrderPaymentData,
                paymentId: paymentProcessRes?.payload?.paymentProcessId || "",
                cartId: cartDetails?.cartId,
              })
            ).then(async (webOrderRes) => {
              if (webOrderRes?.payload?.success) {
                const updateRes = await upgradeToPayCartApi(
                  cartDetails?.cartId
                );

                if (updateRes?.success) {
                  const generateLinkPostData = prepareGenerateLinkPostData({
                    cartDetails,
                    paymentId: paymentProcessRes?.payload?.paymentProcessId,
                    paymentAmount: calculateOrderTotalCost(
                      cartDetails?.orderTotalProductCost,
                      discountAmount,
                      shipmentAmount
                    ),
                    seletedAddress,
                    businessName,
                  });

                  const generatedLinkData = await generatePaymentLinkApi(
                    generateLinkPostData
                  );

                  console.log("generate_link___apiData:", generatedLinkData);

                  if (generatedLinkData?.success) {
                    sessionStorage.setItem("cartData", null);
                    setPaycartLoading(false);
                    // navigate(`/${businessName}/easycartConfirmation`, {
                    //   state: {
                    //     cartId: cartDetails?.cartId,
                    //     payCartData: generatedLinkData,
                    //   },
                    // });
                    navigateTo(navigate, `easycartConfirmation`, {state: {
                      cartId: cartDetails?.cartId,
                      payCartData: generatedLinkData,
                    }})
                  }
                }
              }
            });
          }
        });
      }
    });
  };

  const sendCODNotifications = () => {
    //Preparing send Order Message Data to Customer
    const messageToCustomer = sendOrderMessageHelper({
      type: "toCustomer",
      cartDetails,
      seletedAddress,
      deliveryMethod,
    });

    //Preparing send Order Message Data to Business
    const messageToBusiness = sendOrderMessageHelper({
      type: "toBusiness",
      cartDetails,
      seletedAddress,
      deliveryMethod,
    });

    //Preparing send Order Notification Data
    const sendOrderNotificationData = sendOrderNotificationHelper({
      cartDetails,
      seletedAddress,
      deliveryMethod,
    });

    dispatch(
      sendOrderMessage({
        postData: messageToCustomer,
        cartId: cartDetails?.cartId,
      })
    );
    dispatch(
      sendOrderMessage({
        postData: messageToBusiness,
        cartId: cartDetails?.cartId,
      })
    );
    dispatch(sendOrderNotification(sendOrderNotificationData));
    setCodLoading(false);
    sessionStorage.setItem("cartData", null);
    // navigate(`/${businessName}/confirmation`, {
    //   state: { cartId: cartDetails?.cartId },
    // });
    navigateTo(navigate, `confirmation`, {state: { cartId: cartDetails?.cartId }})
  };

  /*Start Place COD order  */
  const placeCODOrder = async () => {
    setCodLoading(true);
    setCodConfirmAlert(false);

    if (checkIsUpdateRequired(seletedAddress?.updatedOn)) {
      setAlertOpen(true);
      setAlertMsg(updateLatLongMsg);
      return;
    }

    if (!seletedAddress) {
      setAlertOpen(true);
      setAlertMsg(selectDeliveryAddressMsg);
      return;
    }
    if (!deliveryMethod) {
      setAlertOpen(true);
      setAlertMsg(selectDeliveryMethodMsg);
      return;
    }

    if (
      cartDetails?.orderTotalProductCost + Number(shipmentAmount) >
      orderLimitAmount
    ) {
      setAlertOpen(true);
      setAlertMsg(transactionErrorMsg);
      return;
    }

    if (gstChecked && gstNumber.length !== 15 && gstSelected.length !== 15) {
      setAlertOpen(true);
      setAlertMsg("GST Number is not selected");
      return;
    }

    setConfirmPayLoader(true);
    const gst = gstNumber?.length > 0 ? gstNumber : gstSelected;
    //Post data for update cart
    const updateCartData = checkOutUpdateCartHelper({
      cartDetails,
      seletedAddress,
      activeBusinessData,
      appliedDiscountDetails,
      crmOwnershipInfo,
      deliveryMethodData,
      packageInfo,
      activeDeliveryType,
      modeOfPayment: "COD",
      totalOrderCost:
        Number(
          calculateOrderTotalCost(
            cartDetails?.orderTotalProductCost,
            discountAmount,
            shipmentAmount
          ).toFixed(2)
        ) + Number(codChargeTo === "customer" ? codCost : 0),
      consumerNotes,
      shipmentAmount: shipmentAmount,
      shipmentEta,
      deviceInfo,
      selectedDiscountType,
      shipmentTaxValue,
      gst,
    });

    //Post data for add payment process
    const addPaymentProcessData = addPaymentProcessHelper({
      cartId: cartDetails?.cartId,
      paymentAmount:
        Number(
          calculateOrderTotalCost(
            cartDetails?.orderTotalProductCost,
            discountAmount,
            shipmentAmount
          ).toFixed(2)
        ) + Number(codChargeTo === "customer" ? codCost : 0),
    });

    //Post data for updateWebOrderPayment
    const webOrderPaymentData = updateWebOrderPaymentHelper({
      appliedDiscountDetails,
      crmOwnershipInfo,
      totalOrderCost:
        Number(
          calculateOrderTotalCost(
            cartDetails?.orderTotalProductCost,
            discountAmount,
            shipmentAmount
          ).toFixed(2)
        ) + Number(codChargeTo === "customer" ? codCost : 0),
      consumerNotes,
      shipmentAmount: shipmentAmount,
      packageInfo,
      selectedDiscountType,
      shipmentTaxValue,
    });

    // console.log('TEST_DATA_updateCartData_:', updateCartData)
    // console.log('TEST_DATA_addPaymentProcessData_:', addPaymentProcessData)
    // console.log('TEST_DATA_webOrderPaymentData_:', webOrderPaymentData)

    dispatch(
      updateCartDetails({ ...updateCartData, id: cartDetails?.cartId })
    ).then((updateRes) => {
      if (updateRes?.payload?.success) {
        console.log("TEST_DATA_updateCartData_API_RES", updateRes?.payload);

        dispatch(addPaymentProcess(addPaymentProcessData)).then(
          (addPaymentRes) => {
            if (addPaymentRes?.payload?.success) {
              console.log(
                "TEST_DATA_addPaymentProcess_API_RES",
                addPaymentRes?.payload
              );

              webOrderPaymentData.paymentId =
                addPaymentRes?.payload?.paymentProcessId;

              dispatch(
                updateWebOrderPayment({
                  ...webOrderPaymentData,
                  cartId: cartDetails?.cartId,
                })
              ).then((webOrderRes) => {
                if (webOrderRes?.payload?.success) {
                  console.log(
                    "TEST_DATA_updateWebOrderPayment_API_RES",
                    webOrderRes?.payload
                  );

                  const orderCODCartData = orderCODCartDataHelper({
                    cartId: cartDetails?.cartId,
                    cartDetails,
                    paymentId: addPaymentRes?.payload?.paymentProcessId,
                    shipmentAmount: shipmentAmount,
                    appliedDiscountDetails,
                    codCharges: Number(codCost),
                    chargeTo: codChargeTo,
                  });

                  console.log("TEST_DATA_orderCODCartData_:", orderCODCartData);

                  orderCODCartAPI(orderCODCartData).then((orderCODRes) => {
                    if (orderCODRes?.success) {
                      // unicomCreateSaleOrder(true).then((res) => {
                      //   sendCODNotifications();
                      // });
                      sendCODNotifications();
                    }

                    console.log("TEST_DATA_orderCODCart_API_RES", orderCODRes);
                  });
                }
              });
            }
          }
        );
      }
    });
  };
  /*END Place COD order */

  const handleCodClick = async () => {
    if (checkIsUpdateRequired(seletedAddress?.updatedOn)) {
      setAlertOpen(true);
      setAlertMsg(updateLatLongMsg);
      return;
    }

    setCodLoading(true);
    if (commercialDetails?.info?.catalogPartnerInfo?.[0]?.status == "active") {
      const validationInfo = await validateCatalogPartnetInventory(
        cartDetails,
        commercialDetails
      );
      console.log("ValidationInfo__CatalogPartner__DONE:", validationInfo);

      if (validationInfo?.isError) {
        setCatalogPartnerValidationData(validationInfo);
        setAlertOpen(true);
        setAlertMsg(unicomInventoryErrMsg);
        setCodLoading(false);
        return;
      }
    }

    setCodLoading(false);
    if (codLoading) return;
    setCodConfirmAlert(true);
  };

  /*Start Finbox order  */
  const handleFinboxClick = () => {
    if (checkIsUpdateRequired(seletedAddress?.updatedOn)) {
      setAlertOpen(true);
      setAlertMsg(updateLatLongMsg);
      return;
    }

    setFinboxLoading(true);

    if (!seletedAddress) {
      setAlertOpen(true);
      setAlertMsg(selectDeliveryAddressMsg);
      setFinboxLoading(false);
      return;
    }
    if (!deliveryMethod) {
      setAlertOpen(true);
      setAlertMsg(selectDeliveryMethodMsg);
      setFinboxLoading(false);
      return;
    }

    if (
      cartDetails?.orderTotalProductCost + Number(shipmentAmount) >
      orderLimitAmount
    ) {
      setAlertOpen(true);
      setAlertMsg(transactionErrorMsg);
      setFinboxLoading(false);
      return;
    }

    if (gstChecked && gstNumber.length !== 15 && gstSelected.length !== 15) {
      setAlertOpen(true);
      setAlertMsg("GST Number is not selected");
      return;
    }

    const gst = gstNumber?.length > 0 ? gstNumber : gstSelected;

    //Post data for update cart
    const updateCartData = checkOutUpdateCartHelper({
      cartDetails,
      seletedAddress,
      activeBusinessData,
      appliedDiscountDetails,
      crmOwnershipInfo,
      deliveryMethodData,
      packageInfo,
      activeDeliveryType,
      modeOfPayment: "short-term-loan",
      totalOrderCost: Number(
        calculateOrderTotalCost(
          cartDetails?.orderTotalProductCost,
          discountAmount,
          shipmentAmount
        ).toFixed(2)
      ),
      consumerNotes,
      shipmentAmount: shipmentAmount,
      shipmentEta,
      deviceInfo,
      selectedDiscountType,
      shipmentTaxValue,
      gst,
    });

    console.log("Cart updateCartData", updateCartData);

    dispatch(
      updateCartDetails({ ...updateCartData, id: cartDetails?.cartId })
    ).then((updateRes) => {
      if (updateRes?.payload?.success) {
        getOtp();
      }
    });
  };
  /*END Finbox order */

  const placeFinboxOrder = (token) => {
    // Post data for add payment process
    const addPaymentProcessData = addPaymentProcessHelper({
      cartId: cartDetails?.cartId,
      paymentAmount: Number(
        calculateOrderTotalCost(
          cartDetails?.orderTotalProductCost,
          discountAmount,
          shipmentAmount
        ).toFixed(2)
      ),
    });

    // Post data for updateWebOrderPayment
    const webOrderPaymentData = updateWebOrderPaymentHelper({
      appliedDiscountDetails,
      crmOwnershipInfo,
      totalOrderCost: Number(
        calculateOrderTotalCost(
          cartDetails?.orderTotalProductCost,
          discountAmount,
          shipmentAmount
        ).toFixed(2)
      ),
      consumerNotes,
      shipmentAmount: shipmentAmount,
      packageInfo,
      selectedDiscountType,
      shipmentTaxValue,
    });

    // Loan order
    finboxLoanOrder({
      cartId: cartDetails?.cartId,
      lineId: "",
      amount: Number(
        calculateOrderTotalCost(
          cartDetails?.orderTotalProductCost,
          discountAmount,
          shipmentAmount
        ).toFixed(0)
      ),
      tenure: 30,
      partner: "finbox",
      subventionType: "clid001",
      businessId: businessId,
      otpToken: token,
      userConsent: "Y",
      orderId: cartDetails?.orderId,
    })
      .then((res) => {
        if (res?.success) {
          dispatch(addPaymentProcess(addPaymentProcessData)).then(
            (addPaymentRes) => {
              if (addPaymentRes?.payload?.success) {
                console.log(
                  "TEST_DATA_addPaymentProcess_API_RES",
                  addPaymentRes?.payload
                );
                webOrderPaymentData.paymentId =
                  addPaymentRes?.payload?.paymentProcessId;
                dispatch(
                  updateWebOrderPayment({
                    ...webOrderPaymentData,
                    cartId: cartDetails?.cartId,
                  })
                ).then((webOrderRes) => {
                  if (webOrderRes?.payload?.success) {
                    console.log(
                      "TEST_DATA_updateWebOrderPayment_API_RES",
                      webOrderRes?.payload
                    );
                    sendCODNotifications();
                  }
                });
              }
            }
          );
        }
      })
      .finally(() => setFinboxLoading(false));
  };
  /*Start Finverv Order */
  const placeFinvervOrder = async () => {
    if (checkIsUpdateRequired(seletedAddress?.updatedOn)) {
      setAlertOpen(true);
      setAlertMsg(updateLatLongMsg);
      return;
    }

    if (!seletedAddress) {
      setAlertOpen(true);
      setAlertMsg(selectDeliveryAddressMsg);
      return;
    }
    if (!deliveryMethod) {
      setAlertOpen(true);
      setAlertMsg(selectDeliveryMethodMsg);
      return;
    }

    if (
      cartDetails?.orderTotalProductCost + Number(shipmentAmount) >
      orderLimitAmount
    ) {
      setAlertOpen(true);
      setAlertMsg(transactionErrorMsg);
      return;
    }

    if (gstChecked && gstNumber.length !== 15 && gstSelected.length !== 15) {
      setAlertOpen(true);
      setAlertMsg("GST Number is not selected");
      return;
    }

    setFinvervLoading(true);
    const gst = gstNumber?.length > 0 ? gstNumber : gstSelected;

    // setCodConfirmAlert(false)
    //Post data for update cart
    const updateCartData = checkOutUpdateCartHelper({
      // finverv: true,
      cartDetails,
      seletedAddress,
      activeBusinessData,
      appliedDiscountDetails,
      crmOwnershipInfo,
      deliveryMethodData,
      packageInfo,
      activeDeliveryType,
      modeOfPayment: "short-term-loan",
      totalOrderCost: Number(
        calculateOrderTotalCost(
          cartDetails?.orderTotalProductCost,
          discountAmount,
          shipmentAmount
        ).toFixed(2)
      ),
      consumerNotes,
      shipmentAmount: shipmentAmount,
      shipmentEta,
      deviceInfo,
      selectedDiscountType,
      shipmentTaxValue,
      gst,
    });

    console.log("Cart updateCartData", updateCartData);

    // Post data for add payment process
    const addPaymentProcessData = addPaymentProcessHelper({
      cartId: cartDetails?.cartId,
      paymentAmount: Number(
        calculateOrderTotalCost(
          cartDetails?.orderTotalProductCost,
          discountAmount,
          shipmentAmount
        ).toFixed(2)
      ),
    });

    // Post data for updateWebOrderPayment
    const webOrderPaymentData = updateWebOrderPaymentHelper({
      appliedDiscountDetails,
      crmOwnershipInfo,
      totalOrderCost: Number(
        calculateOrderTotalCost(
          cartDetails?.orderTotalProductCost,
          discountAmount,
          shipmentAmount
        ).toFixed(2)
      ),
      consumerNotes,
      shipmentAmount: shipmentAmount,
      packageInfo,
      selectedDiscountType,
      shipmentTaxValue,
    });

    console.log("TEST_DATA_updateCartData_:", updateCartData);
    console.log("TEST_DATA_addPaymentProcessData_:", addPaymentProcessData);
    console.log("TEST_DATA_webOrderPaymentData_:", webOrderPaymentData);

    dispatch(
      updateCartDetails({ ...updateCartData, id: cartDetails?.cartId })
    ).then((updateRes) => {
      if (updateRes?.payload?.success) {
        console.log("TEST_DATA_updateCartData_API_RES", updateRes?.payload);

        createLoanOrderFinverv({
          cartId: cartDetails?.cartId,
          lineId: finAvailValue?.line_id,
          amount: Number(
            calculateOrderTotalCost(
              cartDetails?.orderTotalProductCost,
              discountAmount,
              shipmentAmount
            ).toFixed(0)
          ),
          tenure: 60,
          partner: "finverv",
          businessId: businessId,
        })
          .then((res) => {
            if (res?.success) {
              dispatch(addPaymentProcess(addPaymentProcessData)).then(
                (addPaymentRes) => {
                  if (addPaymentRes?.payload?.success) {
                    console.log(
                      "TEST_DATA_addPaymentProcess_API_RES",
                      addPaymentRes?.payload
                    );
                    webOrderPaymentData.paymentId =
                      addPaymentRes?.payload?.paymentProcessId;
                    dispatch(
                      updateWebOrderPayment({
                        ...webOrderPaymentData,
                        cartId: cartDetails?.cartId,
                      })
                    ).then((webOrderRes) => {
                      if (webOrderRes?.payload?.success) {
                        console.log(
                          "TEST_DATA_updateWebOrderPayment_API_RES",
                          webOrderRes?.payload
                        );
                        sendCODNotifications();
                      }
                    });
                  }
                }
              );
            }
          })
          .finally(() => setFinvervLoading(false));
      }
    });
  };
  /*END Finverv  order */

  const handleFinvervClick = () => {
    if (finvervLoading) return;
    placeFinvervOrder();
    // setCodConfirmAlert(true)
  };
  /*Start of getting OTP functions */
  const configureCaptcha = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          getOtp();
          console.log("Recaptca varified");
        },
        defaultCountry: "IN",
      }
    );
  };

  const getOtp = () => {
    configureCaptcha();
    let phone = getPhoneNumber();
    // let phone = '+919686765867'
    phone = phone.replace(" ", "");
    // console.log('abc', '9686765867')
    const appVerifier = window.recaptchaVerifier;

    firebase
      .auth()
      .signInWithPhoneNumber(phone, appVerifier)
      .then((confirmationResult) => {
        console.log("OTP has been sent", confirmationResult);
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        console.log("OTP has been sent", confirmationResult);
        setOtpModal(true);
        // onContinue({ page: 'otp', phone: mobileNumber })
        // ...
      })
      .catch((error) => {});
  };
  /*End of getting OTP functions */

  /* Place Unicomerce sale order */
  const unicomCreateSaleOrder = async (isCod) => {
    const catalogPartnerInfo = commercialDetails?.info?.catalogPartnerInfo?.[0];

    if (catalogPartnerInfo?.catalogPartner == "unicommerce") {
      const facilityCode = catalogPartnerInfo?.warehouse?.facilityCode;
      const postData = createSaleOrderpostData({
        cartDetails,
        seletedAddress,
        facilityCode,
        isCod: isCod || false,
        shipmentAmount,
        discountAmount,
      });

      console.log("unicomCreateSaleOrder___:", postData);
      const orderRes = await createUnicomSaleOrderApi(postData);

      if (orderRes) {
        await verifyUnicommerceSaleOrder({
          orderId: cartDetails?.orderId,
          facilityCode,
        });
      }
    }

    return true;
  };

  useEffect(() => {
    if (cartDetails?.productList) {
      let qty = 0;
      cartDetails?.productList?.forEach((item, index) => {
        qty = qty + item.currentQuantity;
      });
      settotalQty1(qty);
    }
  }, [cartDetails]);

  useEffect(() => {
    getCRMOwnerShip();
    const currentOS = getOperatingSystem(window);
    const currentBrowser = getBrowser(window);
    console.log("GET_CURRENT_OS_BROWSER", { currentOS, currentBrowser });
    setDeviceInfo({
      os: currentOS,
      browser: currentBrowser,
    });

    if (!allProducts || allProducts == {}) {
      dispatch(getCatalogAllProducts());
    }
  }, []);

  useEffect(() => {
    checkDiscountSection();

    if (checkResellerData?.isPresent == false) {
      setIsCouponValidating(false);
    }
  }, [
    rewardpoints,
    dealsCouponsDetails,
    firstTimeDiscountData,
    checkResellerData,
    instantDiscounts,
  ]);

  useEffect(() => {
    if (cartDetails) {
      getCartWeight();
    }
  }, [cartDetails]);

  // useEffect(() => {
  //   window.addEventListener('focus', onFocus)
  //   window.addEventListener('blur', onBlur)
  //   // Calls onFocus when the window first loads
  //   onFocus()
  //   // Specify how to clean up after this effect:
  //   return () => {
  //     window.removeEventListener('focus', onFocus)
  //     window.removeEventListener('blur', onBlur)
  //   }
  // }, [])

  // const getFinvervLinesData = () => {
  //   getFinvervLines().then((resp) => {
  //     console.log(
  //       'getFinvervLines',
  //       resp,
  //       resp?.line_status,
  //       cartDetails?.orderTotalProductCost,
  //       resp?.available_amount
  //     )
  //     if (!resp?.line_id || !resp?.line_status) {
  //       getFinvervApplicationStatus().then((res) => {
  //         console.log('fffff', res)
  //         setFinPercentComplete(res?.data?.percent_complete)
  //       })
  //       setIsFinvervAvailable('notCompleted')
  //     }
  //     if (resp?.line_status === 'Under Process' && resp?.line_id) {
  //       console.log('ssclcmslcmlc')
  //       setIsFinvervAvailable('pendingApproval')
  //       setFinAvailValue(resp)
  //     }
  //     if (
  //       (resp?.line_status === 'Rejected' || resp?.line_status === 'Blocked') &&
  //       resp?.line_id
  //     ) {
  //       console.log('ssclcmslcmlc')
  //       setIsFinvervAvailable('rejected')
  //       setFinAvailValue(resp)
  //     }
  //     if (
  //       resp?.line_status === 'APPROVED' &&
  //       cartDetails?.orderTotalProductCost < resp?.available_amount
  //     ) {
  //       console.log('ssclcmslcmlc')
  //       setIsFinvervAvailable('balanceAvailable')
  //       setFinAvailValue(resp)
  //     }
  //     if (
  //       resp?.line_status === 'APPROVED ' &&
  //       cartDetails?.orderTotalProductCost < resp?.total_amount &&
  //       cartDetails?.orderTotalProductCost > resp?.available_amount
  //     ) {
  //       setIsFinvervAvailable('noBalanceAvailable')
  //       setFinAvailValue(resp)
  //     }
  //     if (
  //       resp?.line_status === 'APPROVED ' &&
  //       cartDetails?.orderTotalProductCost > resp?.total_amount
  //     ) {
  //       setIsFinvervAvailable('exceeded')
  //       setFinAvailValue(resp)
  //     }
  //   })
  // }

  const getFinboxBannerData = () => {
    getFinboxBanner().then((res) => {
      if (res?.data?.banner) {
        setIsFinvervAvailable(res?.data?.banner);
      }
      if (
        res?.data?.banner === "ACTIVE" ||
        res?.data?.banner === "DUE" ||
        res?.data?.banner === "OVERDUE"
      ) {
        getFinboxCreditLine().then((resp) => {
          if (resp?.data?.availableLimit) {
            setFinboxAvlAmt(resp?.data?.availableLimit);
          }
        });
      }
    });
  };

  const getFinvervLinesData = () => {
    getFinvervLines().then((resp) => {
      console.log(
        "getFinvervLines",
        resp,
        resp?.line_status,
        cartDetails?.orderTotalProductCost,
        resp?.available_amount
      );
      if (!resp?.line_id || !resp?.line_status) {
        getFinvervApplicationStatus().then((res) => {
          console.log("fffff", res);
          setFinPercentComplete(res?.data?.percent_complete);
        });
        setIsFinvervAvailable("notCompleted");
      }
      if (resp?.line_status === "Under Process" && resp?.line_id) {
        console.log("ssclcmslcmlc");
        setIsFinvervAvailable("notCompleted");
        setFinAvailValue(resp);
      }
      if (
        (resp?.line_status === "Rejected" || resp?.line_status === "Blocked") &&
        resp?.line_id
      ) {
        console.log("ssclcmslcmlc");
        setIsFinvervAvailable("rejected");
        setFinAvailValue(resp);
      }
      if (
        resp?.line_status === "Approved" &&
        cartDetails?.orderTotalProductCost < resp?.available_amount
      ) {
        console.log("ssclcmslcmlc");
        setIsFinvervAvailable("balanceAvailable");
        setFinAvailValue(resp);
      }
      if (
        resp?.line_status === "Approved" &&
        cartDetails?.orderTotalProductCost < resp?.total_amount &&
        cartDetails?.orderTotalProductCost > resp?.available_amount
      ) {
        setIsFinvervAvailable("noBalanceAvailable");
        setFinAvailValue(resp);
      }
      if (
        resp?.line_status === "Approved" &&
        cartDetails?.orderTotalProductCost > resp?.total_amount
      ) {
        setIsFinvervAvailable("exceeded");
        setFinAvailValue(resp);
      }
    });
  };

  useEffect(() => {
    if (cartDetails && commercialDetails) {
      checkFreeShipping();
      // checkDeliveryAvailable('DM002', setInstoreDisplay)
      checkDeliveryAvailable("DM001", setAddressDeliveryDisplay);
      if (
        commercialDetails?.info?.fintech?.active &&
        commercialDetails?.info?.fintech?.partners?.[0]?.name === "finbox" &&
        cartDetails?.orderTotalProductCost >
          commercialDetails?.info?.fintech?.partners?.[0]?.creditLimit
      ) {
        const finboxRes = JSON.parse(sessionStorage.getItem("finboxStatus"));
        if (finboxRes?.data?.banner) {
          setIsFinvervAvailable(finboxRes?.data?.banner);
          if (
            finboxRes?.data?.banner === "ACTIVE" ||
            finboxRes?.data?.banner === "DUE" ||
            finboxRes?.data?.banner === "OVERDUE"
          ) {
            getFinboxCreditLine().then((resp) => {
              if (resp?.data?.availableLimit) {
                setFinboxAvlAmt(resp?.data?.availableLimit);
              }
            });
          }
        }
        const finbox = sessionStorage.getItem("finboxUser");
        if (finbox === "notRegisteredFinbox") {
          setIsFinvervAvailable("notRegisteredFinbox");
        }
        // checkFinboxUserExists().then((res) => {
        //   console.log('checkFinvervUserExists', res)
        //   if (res?.exist == false) {
        //     console.log('checkFinvervUserExists1', res)
        //     setIsFinvervAvailable('notRegisteredFinbox')
        //   } else {
        //     getFinboxBannerData()
        //   }
        // })
      }
      if (
        commercialDetails?.info?.fintech?.active &&
        commercialDetails?.info?.fintech?.partners?.[0]?.name === "finverv" &&
        cartDetails?.orderTotalProductCost >
          commercialDetails?.info?.fintech?.partners?.[0]?.creditLimit
      ) {
        const finvervRes = JSON.parse(sessionStorage.getItem("finvervStatus"));

        const finverv = sessionStorage.getItem("finvervUser");
        if (finverv === "notRegistered") {
          setIsFinvervAvailable("notRegistered");
        }
        if (finverv === "notCompleted") {
          setIsFinvervAvailable("notCompleted");
        }
        if (finvervRes) {
          if (!finvervRes?.line_id || !finvervRes?.line_status) {
            getFinvervApplicationStatus().then((res) => {
              console.log("fffff", res);
              setFinPercentComplete(res?.data?.percent_complete);
            });
            setIsFinvervAvailable("notCompleted");
          }
          if (
            finvervRes?.line_status === "Under Process" &&
            finvervRes?.line_id
          ) {
            console.log("ssclcmslcmlc");
            setIsFinvervAvailable("notCompleted");
            setFinAvailValue(finvervRes);
          }
          if (
            (finvervRes?.line_status === "Rejected" ||
              finvervRes?.line_status === "Blocked") &&
            finvervRes?.line_id
          ) {
            console.log("ssclcmslcmlc");
            // setIsFinvervAvailable('rejected')
            setFinAvailValue(finvervRes);
          }
          if (
            finvervRes?.line_status === "Approved" &&
            cartDetails?.orderTotalProductCost < finvervRes?.available_amount
          ) {
            console.log("ssclcmslcmlc");
            setIsFinvervAvailable("balanceAvailable");
            setFinAvailValue(finvervRes);
          }
          if (
            finvervRes?.line_status === "Approved" &&
            cartDetails?.orderTotalProductCost < finvervRes?.total_amount &&
            cartDetails?.orderTotalProductCost > finvervRes?.available_amount
          ) {
            // setIsFinvervAvailable('noBalanceAvailable')
            setFinAvailValue(finvervRes);
          }
          if (
            finvervRes?.line_status === "Approved" &&
            cartDetails?.orderTotalProductCost > finvervRes?.total_amount
          ) {
            // setIsFinvervAvailable('exceeded')
            setFinAvailValue(finvervRes);
          }
          // if (!finvervRes?.line_id || !finvervRes?.line_status) {
          //   setIsFinvervAvailable('notCompleted')
          //   console.log('finvervRes', finvervRes, 'notCompleted')
          //   // getFinvervApplicationStatus().then((res) => {
          //   //   console.log('fffff', res)
          //   //   setFinPercentComplete(res?.data?.percent_complete)
          //   // })
          // }
          // if (
          //   finvervRes?.line_status === 'Under Process' &&
          //   finvervRes?.line_id
          // ) {
          //   console.log('ssclcmslcmlc')
          //   setIsFinvervAvailable('notCompleted')
          //   setFinAvailValue(finvervRes)
          // }
          // if (
          //   (finvervRes?.line_status === 'Rejected' ||
          //     finvervRes?.line_status === 'Blocked') &&
          //   finvervRes?.line_id
          // ) {
          //   console.log('ssclcmslcmlc')
          //   setIsFinvervAvailable('rejected')
          //   setFinAvailValue(finvervRes)
          // }
          // if (finvervRes?.line_status === 'Approved') {
          //   setIsFinvervAvailable('balanceAvailable')
          //   setFinAvailValue(finvervRes)
          // }
        }
        // checkFinvervUserExists().then((res) => {
        //   console.log('checkFinvervUserExists', res)
        //   if (res?.exist == false) {
        //     console.log('checkFinvervUserExists1', res)
        //     setIsFinvervAvailable('notRegistered')
        //   } else {
        //     getFinvervLinesData()
        //   }
        // })
      }
    }
  }, [cartDetails, commercialDetails, fintechStatus, isLoggedIn]);

  useEffect(() => {
    if (!isLoggedIn) {
      setIsFinvervAvailable("");
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (seletedAddress) {
      console.log("CHANGE___DELIVERY");
      setDeliveryMethodLoader(true);

      const distanceInKm = calculateDistance(
        seletedAddress?.latitude,
        seletedAddress?.longitude
      );

      if (!isFreeShipping) {
        console.log("distanceInKmVALUE", distanceInKm);
        checkAdditionalDiscount(distanceInKm);
      }

      b2cDelhiveryCheck = checkDeliveryEligibility(
        TYPE_DELHIVERY,
        commercialDetails,
        distanceInKm
      );

      b2bDelhiveryCheck = checkDeliveryEligibility(
        TYPE_DELHIVERY_B2B,
        commercialDetails,
        distanceInKm
      );

      businessDeliveryCheck = checkDeliveryEligibility(
        TYPE_BUSINESS,
        commercialDetails,
        distanceInKm
      );

      telyportDeliveryCheck = checkDeliveryEligibility(
        TYPE_TELYPORT,
        commercialDetails,
        distanceInKm
      );

      ondcDeliveryCheck = checkDeliveryEligibility(
        "ondc",
        commercialDetails,
        distanceInKm
      );

      if (activeBusinessData && (b2cDelhiveryCheck || b2bDelhiveryCheck)) {
        checkBusinessDeliveryAvailability();
      }

      if (businessDeliveryCheck) {
        checkBusinessDelivery(distanceInKm);
      }

      if (telyportDeliveryCheck) {
        checkTelyportDelivery(distanceInKm);
      }

      if (ondcDeliveryCheck) {
        checkOndcDelivery(distanceInKm);
      }

      setTimeout(() => {
        setDeliveryMethodLoader(false);
      }, 7000);

      if (
        !b2bDelhiveryCheck &&
        !b2cDelhiveryCheck &&
        !businessDeliveryCheck &&
        !telyportDeliveryCheck
      ) {
        setErrorMessage(
          "Delivery not available for this address, Please select a different address. Sorry for the inconvenience"
        );
      }
    }
  }, [seletedAddress]);

  // useEffect(() => {
  //   if (!b2bDelhiveryCheck && !b2cDelhiveryCheck && !businessDeliveryCheck) {
  //     setErrorMessage(
  //       'Delivery not available for this address, Please select a different address. Sorry for the inconvenience'
  //     )
  //   } else {
  //     setErrorMessage('')
  //   }
  // }, [b2bDelhiveryCheck, b2cDelhiveryCheck, businessDeliveryCheck])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  // Finbox Url
  const getModalFinboxUrl = () => {
    getFinboxUrl({
      redirectURL: window.location.href,
    }).then((resp) => {
      console.log("resprespresp", resp);
      if (resp?.data?.url) {
        setFinvervModal({
          show: true,
          url: resp?.data?.url,
        });
      }
    });
  };
  const [selectedOption, setSelectedOption] = useState("payNow");

  const handleSelectMethod = (value) => {
    setSelectedOption(value); // Set the selected value
  };

  // Scroll to cart items section if error
  const scrolltoCartItems = () => {
    const targetPosition =
      unicomErrRef.current.getBoundingClientRect().top + window.scrollY;
    const navbarHeight = matches ? 250 : 100;

    window.scrollTo({
      top: targetPosition - navbarHeight,
      behavior: "smooth",
    });
  };

  // Check fot No Address
  useEffect(() => {
    if(addressDetails?.length === 0){
      console.log("HITTTT____01");
      
      setDeliveryMethodLoader(false)
      setNoAddressError("Please add a delivery address and check for delivery options");
    }
    
    return () => {}
  }, [addressDetails])
  

  return matches ? (
    <>
      {matches && (
        <Box
          sx={{
            // position: { md: 'sticky', xs: 'static' },
            position: "sticky",
            top: 0,
            width: "100%",
            zIndex: 10,
            backgroundColor: backgroundColor01,
            pb: 1.5,
          }}
        >
          {/* <Navbar value='' /> */}
          <Box minHeight="126.7px" bgcolor={whiteColor}>
            {commercialDetails !== null &&
            overviewDetails !== null &&
            cartDetails != null ? (
              <TopComponent value="" disableBanners />
            ) : (
              <>{matches && <HeaderLoader />}</>
            )}
          </Box>

          {/* <TopComponent value="" disableBanners /> */}
          <Container
            sx={{
              maxWidth: "1370px !important",
              mt: { xs: 1.8, md: 2 },
            }}
          >
            <BreadcrumbNew
              links={[
                // { label: "Home", to: `/` },
                { label: "Catalog", to: `/catalog` },
                { label: "Cart", to: `/cart` },
                // currentPage === "discount"
                //   ? { label: "Discounts/Coupons", to: `/DiscountDelivery` }
                //   : ({ label: "Discounts/Coupons", to: `/DiscountDelivery` },
                //     { label: "Address & Delivery", to: `/DiscountDelivery` }),
              ]}
              itemCount={totalQty1}
              totalPrice={calculateOrderTotalCost(
                cartDetails?.orderTotalProductCost,
                discountAmount,
                shipmentAmount
              ).toFixed(2)}
              discount={currentPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />

            {/* <CatalogHeader
                onFilterClick={() => {
                  setShowFilters(true)
                }}
                itemCount={totalQty}
                totalPrice={
                  cartProductList ? cartProductList.orderTotalProductCost : 0
                }
                category={() => {
                  if (
                    category?.length === 1 &&
                    category?.[0]?.subCat?.length === 1
                  ) {
                    handleProductCatClick(category?.[0]?.subCat?.[0])
                    return
                  }

                  setFilterProducts({ products: [], title: '' })
                  setFilteredSubCat([])
                  setViewByFilter('category')
                }}
                products={() => {
                  setFilterProducts({ products: [], title: '' })
                  setFilteredSubCat([])
                  setViewByFilter('all-products')
                }}
                viewByFilter={viewByFilter}
                handleViewByCategoryClick={handleViewByCategoryClick}
                handleViewAllProducts={handleViewAllProducts}
                // All Products Filter props
                displaySelection={sort}
                totalProduct={allProducts.totalProduct}
                onSort={handleAllProductSort}
              /> */}
          </Container>
        </Box>
      )}
      <Container
        sx={{
          maxWidth: "1350px !important",
          mb: { xs: 10, md: 0 },
          p: { xs: "0px", md: "0 10px 0 15px !important" },
        }}
      >
        <div id="sign-in-button"></div>
        <Grid
          container
          rowSpacing={{ xs: 1, md: 1 }}
          columnSpacing={{ xs: 1, md: 2.5 }}
        >
          <Grid item xs={12} md={7.933} mt={{ xs: 0, md: -3 }}>
            <Box
              sx={{
                display: currentPage === "discount" ? "block" : "none",
              }}
            >
              <Box display={{ xs: "block", md: "block" }}>
                <Typography
                  sx={{
                    display: "block",
                    fontSize: { xs: "13.5px", md: "16px" },
                    marginBlockStart: "0.83em",
                    marginBlockEnd: "0.83em",
                    marginInlineStart: "0px",
                    marginInlineEnd: "0px",
                    fontWeight: 600,
                    unicodeBidi: "isolate",
                    color: blackColor,
                    pb: 0.5,
                  }}
                >
                  Discounts/Coupons{" "}
                  <Typography
                    sx={{
                      display: "inline-block",
                      fontSize: { xs: "13.5px", md: "14px" },
                      marginBlockStart: "0.83em",
                      marginBlockEnd: "0.83em",
                      marginInlineStart: "0px",
                      marginInlineEnd: "0px",
                      fontWeight: 600,
                      unicodeBidi: "isolate",
                      color: "#80898d",
                    }}
                  >
                    {" "}
                    (Optional)
                  </Typography>
                  {/* {matches && (
                  <Typography
                    component='p'
                    fontSize={{ xs: 11, md: 13 }}
                    fontWeight={500}
                    color={blackShade11}
                    sx={{
                      lineHeight: {
                        xs: '16px !important',
                        md: '20px !important',
                      },
                    }}
                  >
                    {availabelDiscount_Info}
                  </Typography>
                )} */}
                </Typography>
              </Box>
              {!hideDiscountSection ? (
                isDiscountSection ? (
                  matches ? (
                    <AvailableDiscount
                      rewardpoints={rewardpoints}
                      dealsCouponsDetails={dealsCouponsDetails}
                      commercialDetails={commercialDetails}
                      cartDetails={cartDetails}
                      firstTimeDiscountData={firstTimeDiscountData}
                      discountAmount={discountAmount}
                      setDiscountAmount={(data) => {
                        setDiscountAmount(data);
                      }}
                      appliedDiscountDetails={appliedDiscountDetails}
                      setAppliedDiscountDetails={(data) =>
                        setAppliedDiscountDetails(data)
                      }
                      checkResellerData={checkResellerData}
                      instantDiscounts={instantDiscounts}
                      isCouponValidating={isCouponValidating}
                      setIsCouponValidating={setIsCouponValidating}
                    />
                  ) : (
                    // <Box sx={styles.cartContainer}>
                    //   {/* Availabel Discount Section */}
                    //   <>

                    //   </>
                    // </Box>
                    <Box mb={5}>
                      <AvailableDiscount
                        rewardpoints={rewardpoints}
                        dealsCouponsDetails={dealsCouponsDetails}
                        commercialDetails={commercialDetails}
                        cartDetails={cartDetails}
                        firstTimeDiscountData={firstTimeDiscountData}
                        discountAmount={discountAmount}
                        setDiscountAmount={(data) => setDiscountAmount(data)}
                        appliedDiscountDetails={appliedDiscountDetails}
                        setAppliedDiscountDetails={(data) =>
                          setAppliedDiscountDetails(data)
                        }
                        checkResellerData={checkResellerData}
                        instantDiscounts={instantDiscounts}
                        isCouponValidating={isCouponValidating}
                        setIsCouponValidating={setIsCouponValidating}
                      />
                    </Box>
                  )
                ) : (
                  <Box mt={1.5} mb={{ xs: "0px", md: 2 }}>
                    <EmptyCard
                      iconWidth="57px"
                      iconHeignt="56px"
                      message="No discounts available currently. Please proceed to Checkout"
                      description="Please check this section for any Limited time offers, Cashback redemption, Instant cash discounts during next order."
                      // description={
                      //   <>
                      //     <Typography
                      //       variant='body1'
                      //       fontSize={{ xs: 13.5, md: 14 }}
                      //       color={{ md: blackColor, xs: greyColor9 }}
                      //       lineHeight={{ xs: '20px', md: '23px' }}
                      //       mt={0.7}
                      //       textAlign={'center'}
                      //     >
                      //       No discounts available currently. Please proceed to
                      //       Checkout
                      //     </Typography>
                      //     <Typography
                      //       variant='body1'
                      //       fontSize={{ xs: 13.5, md: 14 }}
                      //       color={{ md: blackColor, xs: greyColor9 }}
                      //       lineHeight={{ xs: '20px', md: '23px' }}
                      //       mt={0.7}
                      //       textAlign={'center'}
                      //     >
                      //       'Please check this section for any Limited time
                      //       offers, Cashback redemption, Instant cash discounts
                      //       during next order.'
                      //     </Typography>
                      //   </>
                      // }
                      // imageUrl={dealsEmptyImgUrl}
                      imageUrl={
                        "https://storage.googleapis.com/bodefaults/shopweb/discountsAndAddress/discount-empty.svg"
                      }
                      imgpadding={"0 0 10px 0"}
                    />
                  </Box>
                )
              ) : (
                matches && <ApplyDiscountLoader />
              )}
            </Box>
            <Box
              sx={{
                display: currentPage === "address-page" ? "block" : "none",
              }}
              mt={4}
            >
              {matches ? (
                // <Box sx={styles.cartContainer}>
                !isInstorePick && addressDeliveryDisplay ? (
                  <>
                    {/* Address Section */}
                    <AddressSection
                      openAddAddress={openAddAddress}
                      addressLoader={addressLoader}
                      setOpenAddAddress={setOpenAddAddress}
                      openUpdateAddress={openUpdateAddress}
                      setOpenUpdateAddress={setOpenUpdateAddress}
                      currentSlideIndex={currentSlideIndex}
                      setCurrentSlideIndex={setCurrentSlideIndex}
                      isAdd={isAdd}
                      setIsAdd={setIsAdd}
                      editAddress={editAddress}
                      setEditAddress={setEditAddress}
                      makeDefault={makeDefault}
                      setMakeDefault={setMakeDefault}
                      addressDetails={addressDetails}
                      accountDetails={accountDetails}
                      seletedAddress={seletedAddress}
                      setSeletedAddress={setSeletedAddress}
                      setErrorMessage={setErrorMessage}
                      setNoAddressError={setNoAddressError}
                      resetDelivery={() => {
                        // Clearing previously selected delivery method
                        setActiveDeliveryType(null);
                        setDeliveryMethod("");

                        setCostboPremier({
                          totalAmount: "",
                          originalAmount: "",
                          show: false,
                          error: false,
                          type: "",
                          shipmentTax: 0,
                        });
                        setCostboPremierDeliverETA("");
                        setCostboPremierHeavy({
                          totalAmount: "",
                          originalAmount: "",
                          show: false,
                          error: false,
                          type: "",
                          shipmentTax: 0,
                        });
                        setCostboPremierDeliverETAHeavy("");
                        setTelyportRushDelivery({
                          totalAmount: "",
                          show: false,
                          deliveryETA: "",
                          originalAmount: "",
                          error: false,
                          type: "",
                          shipmentTax: 0,
                        });
                        setTelyportExpressDelivery({
                          totalAmount: "",
                          show: false,
                          deliveryETA: "",
                          originalAmount: "",
                          error: false,
                          type: "",
                          shipmentTax: 0,
                        });
                        setTelyportStandardDelivery({
                          totalAmount: "",
                          show: false,
                          deliveryETA: "",
                          originalAmount: "",
                          error: false,
                          type: "",
                          shipmentTax: 0,
                        });

                        setBusinessDelivery({});
                        setOndcDelivery({
                          totalAmount: "",
                          show: false,
                          deliveryETA: "",
                          originalAmount: "",
                          error: false,
                          type: "",
                          shipmentTax: 0,
                        });
                        ondcDeliveryCheck = false;
                        b2cDelhiveryCheck = false;
                        b2bDelhiveryCheck = false;
                        businessDeliveryCheck = false;
                        telyportDeliveryCheck = false;
                      }}
                    />

                    {/* <Divider light /> */}
                    <GSTDetialsSection
                      gstChecked={gstChecked}
                      setGstChecked={setGstChecked}
                      selectedGst={gstSelected}
                      setGstSelected={setGstSelected}
                      gstNumber={gstNumber}
                      setGstNumber={setGstNumber}
                    />
                    {/* <Divider light /> */}
                    {/* Delivery Method Section */}
                    <DeliveryMethodSection
                      deliveryMethodLoader={deliveryMethodLoader}
                      deliveryMethod={deliveryMethod}
                      errorMessage={errorMessage}
                      noAddressError={noAddressError}
                      seletedAddress={seletedAddress}
                      costboPremier={costboPremier}
                      businessDelivery={businessDelivery}
                      costboPremierHeavy={costboPremierHeavy}
                      telyportStandardDelivery={telyportStandardDelivery}
                      telyportRushDelivery={telyportRushDelivery}
                      telyportExpressDelivery={telyportExpressDelivery}
                      isFreeShipping={isFreeShipping}
                      costboPremierDeliverETA={costboPremierDeliverETA}
                      additionalDiscount={additionalDiscount}
                      costboPremierDeliverETAHeavy={
                        costboPremierDeliverETAHeavy
                      }
                      ondcDelivery={ondcDelivery}
                      onDeliveryMethodChange={(value) => {
                        console.log("DELIVERY_SELECTED", value);
                        shipmentValueCod = value.amount;
                        selectDeliveryMethod(value.type);
                        setShipmentAmount(value.amount);
                        setShipmentEta(value.eta);
                        if (
                          value.discountType !== "none" &&
                          value.discountType !== ""
                        ) {
                          setSelectedDiscountType({
                            discountType: value.discountType,
                            discountAmount: (
                              value.originalAmount - value.amount
                            )?.toFixed(2),
                          });
                        }
                        setShipmentTaxValue(value?.shipmentTax || 0);
                      }}
                      totalCartWeightKg={totalCartWeightKg}
                    />
                    {telyportRushDelivery?.show ||
                    telyportExpressDelivery?.show ||
                    telyportStandardDelivery?.show ? (
                      <Box>
                        <Typography
                          variant="subtitle1"
                          component="p"
                          fontSize={{ xs: 12, ms: 13 }}
                          color={minOrderTextColor}
                          display="inline"
                          sx={{ lineHeight: "20px" }}
                        >
                          *Rush and Express delivery available only during
                          business{" "}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          component="p"
                          fontSize={{ xs: 12, ms: 13 }}
                          color={orangeColor01}
                          display="inline"
                          sx={{ lineHeight: "20px", cursor: "pointer" }}
                          onClick={() => setWorkingHourPopup(true)}
                        >
                          open hours
                        </Typography>
                      </Box>
                    ) : null}
                  </>
                ) : null
              ) : (
                // </Box>
                <>
                  {!isInstorePick && addressDeliveryDisplay ? (
                    <>
                      {/* Address Section */}
                      <AddressSection
                        addressLoader={addressLoader}
                        openAddAddress={openAddAddress}
                        setOpenAddAddress={setOpenAddAddress}
                        openUpdateAddress={openUpdateAddress}
                        setOpenUpdateAddress={setOpenUpdateAddress}
                        currentSlideIndex={currentSlideIndex}
                        setCurrentSlideIndex={setCurrentSlideIndex}
                        isAdd={isAdd}
                        setIsAdd={setIsAdd}
                        editAddress={editAddress}
                        setEditAddress={setEditAddress}
                        makeDefault={makeDefault}
                        setMakeDefault={setMakeDefault}
                        addressDetails={addressDetails}
                        accountDetails={accountDetails}
                        seletedAddress={seletedAddress}
                        setSeletedAddress={setSeletedAddress}
                        setErrorMessage={setErrorMessage}
                        setNoAddressError={setNoAddressError}
                        resetDelivery={() => {
                          // Clearing previously selected delivery method
                          setActiveDeliveryType(null);
                          setDeliveryMethod("");

                          setCostboPremier({
                            totalAmount: "",
                            originalAmount: "",
                            show: false,
                            error: false,
                            type: "",
                            shipmentTax: 0,
                          });
                          setCostboPremierDeliverETA("");
                          setCostboPremierHeavy({
                            totalAmount: "",
                            originalAmount: "",
                            show: false,
                            error: false,
                            type: "",
                            shipmentTax: 0,
                          });
                          setCostboPremierDeliverETAHeavy("");
                          setTelyportRushDelivery({
                            totalAmount: "",
                            show: false,
                            deliveryETA: "",
                            originalAmount: "",
                            error: false,
                            type: "",
                            shipmentTax: 0,
                          });
                          setTelyportExpressDelivery({
                            totalAmount: "",
                            show: false,
                            deliveryETA: "",
                            originalAmount: "",
                            error: false,
                            type: "",
                            shipmentTax: 0,
                          });
                          setTelyportStandardDelivery({
                            totalAmount: "",
                            show: false,
                            deliveryETA: "",
                            originalAmount: "",
                            error: false,
                            type: "",
                            shipmentTax: 0,
                          });
                          setBusinessDelivery({});

                          setOndcDelivery({
                            totalAmount: "",
                            show: false,
                            deliveryETA: "",
                            originalAmount: "",
                            error: false,
                            type: "",
                            shipmentTax: 0,
                          });
                          ondcDeliveryCheck = false;
                          b2cDelhiveryCheck = false;
                          b2bDelhiveryCheck = false;
                          businessDeliveryCheck = false;
                          telyportDeliveryCheck = false;
                        }}
                      />
                    </>
                  ) : null}
                  <Box sx={styles.cartContainer} mb={{ md: 1, xs: 1 }}>
                    {!isInstorePick && addressDeliveryDisplay ? (
                      <>
                        <GSTDetialsSection
                          gstChecked={gstChecked}
                          setGstChecked={setGstChecked}
                          selectedGst={gstSelected}
                          setGstSelected={setGstSelected}
                          gstNumber={gstNumber}
                          setGstNumber={setGstNumber}
                        />
                      </>
                    ) : null}
                  </Box>
                  <Box sx={styles.cartContainer} mb={1}>
                    {!isInstorePick && addressDeliveryDisplay ? (
                      <>
                        <DeliveryMethodSection
                          deliveryMethod={deliveryMethod}
                          errorMessage={errorMessage}
                          noAddressError={noAddressError}
                          seletedAddress={seletedAddress}
                          costboPremier={costboPremier}
                          businessDelivery={businessDelivery}
                          costboPremierHeavy={costboPremierHeavy}
                          telyportStandardDelivery={telyportStandardDelivery}
                          telyportRushDelivery={telyportRushDelivery}
                          telyportExpressDelivery={telyportExpressDelivery}
                          isFreeShipping={isFreeShipping}
                          costboPremierDeliverETA={costboPremierDeliverETA}
                          additionalDiscount={additionalDiscount}
                          costboPremierDeliverETAHeavy={
                            costboPremierDeliverETAHeavy
                          }
                          ondcDelivery={ondcDelivery}
                          onDeliveryMethodChange={(value) => {
                            console.log("DELIVERY_SELECTED", value);
                            shipmentValueCod = value.amount;
                            selectDeliveryMethod(value.type);
                            setShipmentAmount(value.amount);
                            setShipmentEta(value.eta);
                            if (
                              value.discountType !== "none" &&
                              value.discountType !== ""
                            ) {
                              setSelectedDiscountType({
                                discountType: value.discountType,
                                discountAmount: (
                                  value.originalAmount - value.amount
                                )?.toFixed(2),
                              });
                            }
                            setShipmentTaxValue(value?.shipmentTax || 0);
                          }}
                          totalCartWeightKg={totalCartWeightKg}
                        />
                        {telyportRushDelivery?.show ||
                        telyportExpressDelivery?.show ||
                        telyportStandardDelivery?.show ? (
                          <Box
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                          >
                            <Typography
                              width={"77%"}
                              variant="subtitle1"
                              component="p"
                              fontSize={{ xs: 12, ms: 13 }}
                              color={{ md: minOrderTextColor, xs: blackColor }}
                              display="inline"
                              sx={{ lineHeight: "20px" }}
                            >
                              *Rush and Express delivery available only during
                              business open hours
                            </Typography>
                            <Typography
                              width={"30%"}
                              variant="subtitle1"
                              component="p"
                              fontSize={{ xs: 12, ms: 13 }}
                              color={{ md: orangeColor01, xs: "#F58220" }}
                              sx={{
                                lineHeight: "20px",
                                cursor: "pointer",
                                textDecoration: "underline",
                                textAlign: "end",
                              }}
                              onClick={() => setWorkingHourPopup(true)}
                            >
                              Working Hours
                            </Typography>
                          </Box>
                        ) : null}
                      </>
                    ) : null}
                  </Box>
                </>
              )}

              {matches && (
                <DiscountHeader
                  topmargin="15px"
                  title={"Special Instructions To Seller"}
                  subtitle="(Ex: Please wrap it individually)"
                />
              )}
              <Box sx={styles.cartContainer} mb={1}>
                <Box>
                  {!matches && (
                    <DiscountHeader
                      icon={
                        matches ? (
                          <Lightbulb
                            sx={{
                              fontSize: { xs: "19px", md: "20px" },
                              color: headingColor,
                              mb: "-5px",
                            }}
                          />
                        ) : (
                          <TipsAndUpdatesIcon
                            sx={{
                              fontSize: { xs: "22px", md: "20px" },
                              color: headingColor,
                              mb: "-5px",
                              mr: 0.5,
                            }}
                          />
                        )
                      }
                      title={"Special Instructions To Seller"}
                      subtitle="(Ex: Please wrap it individually)"
                      specialInstructions
                      collapseInstructions={collapseInstructions}
                      onToggleInstructions={() => {
                        setToggleCollapseInstructions(!collapseInstructions);
                      }}
                    />
                  )}

                  <Box
                    sx={{
                      display: "block",
                      // display: collapseInstructions ? 'none' : 'block',
                    }}
                    width={{ xs: "80vw", md: "97%" }}
                  >
                    <Typography
                      fontSize={14}
                      fontWeight={600}
                      color={blackColor}
                    >
                      Description
                    </Typography>
                    <TextareaAutosize
                      minRows={3}
                      placeholder="(Character limit 500)"
                      aria-label="maximum height"
                      style={styles.inputBox}
                      value={consumerNotes}
                      maxLength={500}
                      onChange={({ target }) => {
                        const finalValue = allowOnlyEnglish(target.value);
                        setConsumerNotes(finalValue);
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={styles.cartContainer}
              mt={{ xs: -3, md: 2 }}
              mb={2}
              ref={unicomErrRef}
            >
              <DiscountHeader
                icon={
                  <ShoppingBasketRoundedIcon
                    sx={{
                      margin: "0 6px -3px 0",
                      fontSize: { md: "18px", xs: "22px" },
                      // height:"15px",
                      //width:"22px"
                    }}
                  />
                }
                title={
                  matches
                    ? `${
                        cartDetails?.productList?.reduce(
                          (accumulator, product) => {
                            return accumulator + product.currentQuantity;
                          },
                          0
                        ) || 0
                        // Number(
                        //   cartDetails?.productList?.[0]?.currentQuantity +
                        //     cartDetails?.productList?.[1]?.currentQuantity
                        // )
                      }  Items in your cart`
                    : `
                  ${
                    cartDetails?.productList?.reduce((accumulator, product) => {
                      return accumulator + product.currentQuantity;
                    }, 0) || 0
                    // Number(
                    //   cartDetails?.productList?.[0]?.currentQuantity +
                    //     cartDetails?.productList?.[1]?.currentQuantity
                    // )
                  } 
                    Items in Cart`
                }
                items
                collapseCartTabel={collapseCartTabel}
                onToggleCartItems={() => {
                  setToggleCollapseCartTabel(!collapseCartTabel);
                }}
              />
              {/* <Grid
              container
              py={1.5}
              display={{ xs: 'none', md: 'inline-flex' }}
            >
              <Grid item xs={5}>
                <Typography
                  variant='subtitle1'
                  component='h4'
                  fontSize='14px'
                  color={delectIconInactiveColor}
                  ml={1.8}
                >
                  PRODUCT
                </Typography>
              </Grid>
              <Grid item xs={2.5}>
                <Typography
                  variant='subtitle1'
                  component='h4'
                  fontSize='14px'
                  color={delectIconInactiveColor}
                >
                  UNIT PRICE
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  variant='subtitle1'
                  component='h4'
                  fontSize='14px'
                  color={delectIconInactiveColor}
                >
                  QUANTITY
                </Typography>
              </Grid>
              <Grid item xs={1.3}>
                <Typography
                  variant='subtitle1'
                  component='h4'
                  fontSize='14px'
                  color={delectIconInactiveColor}
                  ml={0.6}
                >
                  PRICE
                </Typography>
              </Grid>
              <Grid item xs={1.2}>
                <Typography
                  variant='subtitle1'
                  component='h4'
                  fontSize='14px'
                  color={delectIconInactiveColor}
                >
                  SAVINGS
                </Typography>
              </Grid>
            </Grid> */}

              <Box
                sx={{
                  display: {
                    xs: collapseCartTabel ? "none" : "block",
                    md: "block",
                  },
                }}
              >
                {!cartDetails ? (
                  <>{matches && <CartItemsLoaderMinimal />}</>
                ) : (
                  <>
                    {cartDetails?.productList?.map((item, index) => {
                      const savings =
                        Number(item?.productOriginalPrice) *
                          Number(item?.currentQuantity) -
                        Number(item?.productSalePrice) *
                          Number(item?.currentQuantity);
                      totalQty = totalQty + item.currentQuantity;
                      totalSavings = totalSavings + savings;
                      // settotalQty(totalQty1)

                      const errorMsg = getPartnerInventoryError(
                        catalogPartnerValidationData,
                        item,
                      );

                      return (
                        <TableProductCard
                          key={"cart_product_" + index}
                          image={item?.productPicURL}
                          productName={item?.productName}
                          oldUnitPrice={item?.productOriginalPrice}
                          unitprice={item?.productSalePrice}
                          discount={item?.percentageOff}
                          price={item?.productTotalPrice}
                          saving={savings}
                          quantity={item.currentQuantity}
                          isOutofStock={item.isOutOfStock}
                          cartData={cartDetails?.productList}
                          cartId={cartDetails?.cartId}
                          product={item}
                          readOnly={true}
                          errorMsg={errorMsg}
                        />
                      );
                    })}
                  </>
                )}

                <Box
                  display={{ xs: "flex", md: "none" }}
                  alignItems="center"
                  justifyContent="center"
                  my={1}
                  borderTop={`1px solid ${borderLineColor}`}
                  py={"10px"}
                  width={"100%"}
                >
                  <Button
                    variant="outlined"
                    sx={styles.actionBtn}
                    // size='small'
                    // component={Link}
                    // to={`/${businessName}/cart`}
                    onClick={()=>{
                      navigateTo(navigate, `cart`)
                    }}
                  >
                    Modify Cart
                  </Button>
                </Box>
              </Box>
            </Box>
            {/* <Box
            sx={{
              display: currentPage === 'address-page' ? 'block' : 'none',
            }}
          >
            <Box display={{ xs: 'block', md: 'none' }}>
              <Box display={'flex'} justifyContent={'space-between'} pb={1}>
                <Typography
                  sx={{
                    display: 'block',
                    fontSize: '14px',
                    marginBlockStart: '0.83em',
                    marginBlockEnd: '0.83em',
                    marginInlineStart: '0px',
                    marginInlineEnd: '0px',
                    fontWeight: 600,
                    unicodeBidi: 'isolate',
                    color: blackColor,
                    mb: 1,
                  }}
                >
                  Delivery Address
                </Typography>
                {!matches && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      color: '#F58220',
                      fontSize: '13px',
                      fontWeight: 500,
                      textDecoration: 'underline',
                      marginLeft: '15px',
                      mt: 1.5,
                    }}
                  >
                    <Box
                      component='img'
                      
                      // src='https://storage.googleapis.com/bodefaults/shopweb/discounts_wallet.svg'
                      alt={' CostBo'}
                      width={{ xs: '13px', md: '46px' }}
                      height={{ xs: '13px', md: '30px' }}
                      sx={{ color: '#F58220', mt: 0.5 }}
                    />
                    <Typography
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        color: '#F58220',
                        fontSize: '13px',
                        fontWeight: 500,
                        textDecoration: 'underline',
                        marginLeft: '5px',
                      }}
                    >
                      Search
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Box> */}
            {/* Instore Pickup Section */}
            {/* {instoreDisplay ? (
            <>
              <DiscountHeader
                icon={
                  <StoreMallDirectory
                    sx={{
                      fontSize: { xs: '22px', md: '26px' },
                      color: headingColor,
                    }}
                  />
                }
                title='INSTORE PICKUP'
                showInstorePick
                isInstorePick={isInstorePick}
                onInstorePickClick={() => {
                  console.log('Instore Pick')
                  setIsInstorePick(!isInstorePick)
                  setIsDeliverySelected(!isInstorePick)
                }}
              />
            </>
          ) : null} */}
          </Grid>

          <Grid item xs={12} md={3.95} mt={2} ml={1}>
            {currentPage === "address-page" ? (
              <>
                <Typography
                  variant="h5"
                  component="h3"
                  fontSize={{ xs: 14, md: 15 }}
                  color={{ md: headingColor, xs: blackColor }}
                  // borderBottom={{ md: '1px solid ' + borderLineColor, xs: 'none' }}
                  textAlign={{ md: "left", xs: "left" }}
                  pb={1}
                >
                  Choose Payment Mode
                </Typography>
                <Box
                  sx={{
                    background: orangeColor04,
                    border: "1px solid " + orangeColor05,
                    borderRadius: "10px",
                    marginTop: "0px",
                    // padding: 1,
                    marginBottom: "15px",
                  }}
                >
                  <Box
                    sx={styles.radioBtn}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <Radio
                      checked={selectedOption === "payNow"}
                      onChange={() => handleSelectMethod("payNow")}
                      sx={{
                        color: blackColor, // default color
                        // ml: "-10px",
                        "&.Mui-checked": {
                          color: blackColor, // color when checked
                        },
                      }}
                      value="a"
                      size="small"
                      name="radio-buttons"
                      inputProps={{ "aria-label": "A" }}
                      // disabled={!((price || price === 0) && price !== '')}
                    />
                    <Typography
                      sx={{
                        color: blackColor,
                        fontSize: 13,
                        fontWeight: 600,
                        // ml: 1,
                        // pl: 1,
                      }}
                    >
                      {codAvailable ? "Pay Now" : "Confirm & Pay"}
                    </Typography>
                  </Box>
                  {activeBusinessData?.viewBusiness?.owner?.profileId ===
                    userProfileId ||
                  activeBusinessData?.viewBusiness?.delegates?.some(
                    (contact) => contact.profileId === userProfileId
                  ) ? (
                    <Box
                      sx={styles.radioBtn}
                      display={"flex"}
                      alignItems={"center"}
                    >
                      <Radio
                        checked={selectedOption === "generateLink"}
                        onChange={() => handleSelectMethod("generateLink")}
                        sx={{
                          color: blackColor, // default color
                          "&.Mui-checked": {
                            color: blackColor, // color when checked
                          },
                        }}
                        value="a"
                        size="small"
                        name="radio-buttons"
                        inputProps={{ "aria-label": "A" }}
                        // disabled={!((price || price === 0) && price !== '')}
                      />
                      <Typography
                        sx={{
                          color: blackColor,
                          fontSize: 13,
                          fontWeight: 600,
                          // ml: 1,
                          // pl: 1,
                        }}
                      >
                        GENERATE EASY CART LINK
                      </Typography>
                    </Box>
                  ) : null}
                  {codAvailable ? (
                    <Box
                      sx={styles.radioBtn}
                      display={"flex"}
                      alignItems={"center"}
                    >
                      <Radio
                        checked={selectedOption === "cod"}
                        onChange={() => handleSelectMethod("cod")}
                        sx={{
                          color: blackColor, // default color
                          "&.Mui-checked": {
                            color: blackColor, // color when checked
                          },
                        }}
                        value="a"
                        size="small"
                        name="radio-buttons"
                        inputProps={{ "aria-label": "A" }}
                        // disabled={!((price || price === 0) && price !== '')}
                      />
                      <Typography
                        sx={{
                          color: blackColor,
                          fontSize: 13,
                          fontWeight: 600,
                          // ml: 1,
                          // pl: 1,
                        }}
                      >
                        Cash on delivery
                        {codChargeTo === "customer"
                          ? `(₹
                ${(
                  Number(
                    calculateOrderTotalCost(
                      cartDetails?.orderTotalProductCost,
                      discountAmount,
                      shipmentAmount
                    )
                  ) + Number(codCost)
                ).toFixed(2)}
                )`
                          : ""}
                        {codChargeTo === "customer" ? (
                          <Typography
                            component="span"
                            fontSize={{ xs: 13, md: 12 }}
                            fontWeight={500}
                            color={orangeColor03}
                            textAlign="center"
                            display={{ xs: "none", md: "inline" }}
                          >
                            (COD additional charge ₹{codCost})
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Typography>
                    </Box>
                  ) : null}
                </Box>
              </>
            ) : null}
            {/* {currentPage === 'discount' ? (
            <Button
              sx={styles.checkoutBtn(isBusinessClosed || isCouponValidating)}
              variant='contained'
              disableElevation
              fullWidth
              onClick={() => {
                setCurrentPage('address-page')
              }}
              disabled={isBusinessClosed || isCouponValidating}
            >
              Continue Checkout
            </Button>
          ) : null} */}

            {/* {currentPage === 'address-page' ? (
            <>
              <Button
                sx={{
                  ...styles.checkoutBtn(isBusinessClosed),
                  border: confirmPayLoader
                    ? 'none'
                    : `1px solid ${loginPrimary}`,
                }}
                variant='contained'
                disableElevation
                fullWidth
                onClick={() => onConfirm()}
                disabled={isBusinessClosed || confirmPayLoader}
              >
                {confirmPayLoader ? (
                  <CircularProgress
                    sx={{ color: loginPrimary, mr: 1 }}
                    size={16}
                  />
                ) : null}{' '}
                {codAvailable ? 'PAY NOW' : 'Confirm & Pay'}
              </Button>

              {activeBusinessData?.viewBusiness?.owner?.profileId ===
                userProfileId ||
              activeBusinessData?.viewBusiness?.delegates?.some(
                (contact) => contact.profileId === userProfileId
              ) ? (
                <>
                  {matches && (
                    <Typography
                      variant='body1'
                      fontSize={13}
                      color={greyShade7}
                      textAlign='center'
                    >
                      OR
                    </Typography>
                  )}
                  <Button
                    sx={{
                      ...styles.paycartLinkBtn(isBusinessClosed),
                      border: paycartLoading
                        ? 'none'
                        : `1px solid ${blackShade8}`,
                    }}
                    variant='contained'
                    disableElevation
                    fullWidth
                    onClick={() => {
                      confirmPaycartOrder()
                      // navigate(`/${businessName}/paycartOrders`)
                    }}
                    disabled={isBusinessClosed || paycartLoading}
                    startIcon={
                      paycartLoading ? (
                        <CircularProgress
                          size={16}
                          sx={{ color: blackShade8 }}
                        />
                      ) : null
                    }
                  >
                    GENERATE EASY CART LINK
                  </Button>
                </>
              ) : null}
            </>
          ) : null} */}

            {/* {codAvailable && matches ? (
            <Typography
              variant='body1'
              fontSize={13}
              color={greyShade7}
              textAlign='center'
            >
              OR
            </Typography>
          ) : null}

          {codAvailable ? (
            <>
              <Button
                sx={styles.checkoutCodBtn}
                variant='contained'
                disableElevation
                fullWidth
                onClick={handleCodClick}
              >
                {codLoading ? (
                  <CircularProgress
                    size={14}
                    sx={{ color: whiteColor, mr: '8px' }}
                  />
                ) : null}{' '}
                Cash on delivery
                {codChargeTo === 'customer'
                  ? `(₹
                ${(
                  Number(
                    calculateOrderTotalCost(
                      cartDetails?.orderTotalProductCost,
                      discountAmount,
                      shipmentAmount
                    )
                  ) + Number(codCost)
                ).toFixed(2)}
                )`
                  : ''}
              </Button>

              {codChargeTo === 'customer' ? (
                <Typography
                  variant='body1'
                  fontSize={{ xs: 13, md: 13 }}
                  fontWeight={500}
                  color={orangeColor01}
                  textAlign='center'
                  display={{ xs: 'none', md: 'block' }}
                >
                  (COD additional charge ₹{codCost})
                </Typography>
              ) : (
                ''
              )}
            </>
          ) : null} */}

            {currentPage === "address-page" &&
            commercialDetails?.info?.fintech?.active &&
            commercialDetails?.info?.fintech?.partners?.[0]?.name ===
              "finbox" &&
            (isFinvervAvailable === "notRegisteredFinbox" ||
              isFinvervAvailable === "APPLY" ||
              isFinvervAvailable === "ACTIVE" ||
              isFinvervAvailable === "INCOMPLETE" ||
              isFinvervAvailable === "APPROVED" ||
              isFinvervAvailable === "DUE") &&
            cartDetails?.orderTotalProductCost >
              commercialDetails?.info?.fintech?.partners?.[0]?.creditLimit ? (
              <Typography
                variant="body1"
                fontSize={13}
                color={greyShade7}
                textAlign="center"
                sx={{ display: { md: "block", xs: "none" } }}
              >
                OR
              </Typography>
            ) : null}
            {currentPage === "address-page" &&
            commercialDetails?.info?.fintech?.active &&
            commercialDetails?.info?.fintech?.partners?.[0]?.name ===
              "finbox" &&
            (isFinvervAvailable === "notRegisteredFinbox" ||
              isFinvervAvailable === "APPLY" ||
              isFinvervAvailable === "ACTIVE" ||
              isFinvervAvailable === "INCOMPLETE" ||
              isFinvervAvailable === "APPROVED" ||
              isFinvervAvailable === "DUE") &&
            cartDetails?.orderTotalProductCost >
              commercialDetails?.info?.fintech?.partners?.[0]?.creditLimit ? (
              <>
                {isFinvervAvailable === "notRegisteredFinbox" ? (
                  <Box
                    sx={{
                      color: whiteColor,
                      my: 0.5,
                      backgroundColor: topbarColor,
                      borderRadius: 1,
                      py: 1,
                      cursor: "pointer",
                      border: "2px solid " + topbarColor,
                      "&:hover": {
                        backgroundColor: whiteColor,
                        color: topbarColor,
                      },
                      textTransform: "uppercase",
                    }}
                    onClick={() => {
                      // setFinvervModal({
                      //   show: true,
                      //   // url: resp?.redirect_url,
                      // })
                      // const phoneNumber = getPhoneNumber()
                      // console.log('Fintech', phoneNumber?.replace('+91', ''))
                      // registeFinvervUser({
                      //   mobile: phoneNumber?.replace('+91', ''),
                      // }).then((res) => {
                      //   getFinvervUrl().then((resp) => {
                      //     if (resp?.redirect_url) {
                      //       setFinvervModal({
                      //         show: true,
                      //         url: resp?.redirect_url,
                      //       })
                      //       // window.open(resp?.redirect_url, '_blank')
                      //     }
                      //   })
                      // })
                      const phoneNumber = getPhoneNumber();
                      const customerID = getProfileId();
                      const name = accountDetails?.account?.name;
                      const email = accountDetails?.account?.email;
                      console.log("Fintech", phoneNumber?.replace("+91", ""));
                      registeFinboxUser({
                        customerID,
                        mobile: phoneNumber?.replace("+91", ""),
                      }).then((res) => {
                        finboxPartnerPush({ customerID, name, email }).then(
                          () => {
                            getFinboxEligibility().then(() => {
                              getFinboxUrl({
                                redirectURL: window.location.href,
                              }).then((resp) => {
                                console.log("resprespresp", resp);
                                if (resp?.data?.url) {
                                  setFinvervModal({
                                    show: true,
                                    url: resp?.data?.url,
                                  });
                                }
                              });
                            });
                          }
                        );
                      });
                    }}
                  >
                    <Typography
                      textAlign="center"
                      fontSize={14}
                      fontWeight={600}
                    >
                      Apply for Credit line
                    </Typography>
                    {/* <Typography textAlign='center' fontSize={12} fontWeight={400}>
                    (Available above{' '}
                    {
                      commercialDetails?.info?.fintech?.partners?.[0]
                        ?.creditLimit
                    }
                    )
                  </Typography> */}
                  </Box>
                ) : null}
                {isFinvervAvailable === "APPLY" ? (
                  <Box
                    sx={{
                      color: whiteColor,
                      my: 0.5,
                      backgroundColor: topbarColor,
                      borderRadius: 1,
                      py: 1,
                      cursor: "pointer",
                      border: "2px solid " + topbarColor,
                      "&:hover": {
                        backgroundColor: whiteColor,
                        color: topbarColor,
                      },
                      display: { md: "block", xs: "none" },
                      textTransform: "uppercase",
                    }}
                    onClick={() => {
                      getFinboxUrl({
                        redirectURL: window.location.href,
                      }).then((resp) => {
                        console.log("resprespresp", resp);
                        if (resp?.data?.url) {
                          setFinvervModal({
                            show: true,
                            url: resp?.data?.url,
                          });
                        }
                      });
                    }}
                  >
                    <Typography
                      textAlign="center"
                      fontSize={14}
                      fontWeight={600}
                    >
                      Apply for Credit line
                    </Typography>
                  </Box>
                ) : null}
                {isFinvervAvailable === "INCOMPLETE" ||
                isFinvervAvailable === "APPROVED" ? (
                  <Box
                    sx={{
                      color: whiteColor,
                      my: 0.5,
                      backgroundColor: topbarColor,
                      borderRadius: 1,
                      py: 1,
                      cursor: "pointer",
                      border: "2px solid " + topbarColor,
                      "&:hover": {
                        backgroundColor: whiteColor,
                        color: topbarColor,
                      },
                      display: { md: "block", xs: "none" },
                      textTransform: "uppercase",
                    }}
                    onClick={() => {
                      getFinboxUrl({
                        redirectURL: window.location.href,
                      }).then((resp) => {
                        console.log("resprespresp", resp);
                        if (resp?.data?.url) {
                          setFinvervModal({
                            show: true,
                            url: resp?.data?.url,
                          });
                        }
                      });
                    }}
                  >
                    <Typography
                      textAlign="center"
                      fontSize={14}
                      fontWeight={600}
                    >
                      Complete your credit application
                    </Typography>
                  </Box>
                ) : null}
                {isFinvervAvailable === "ACTIVE" ||
                (isFinvervAvailable === "DUE" &&
                  finboxAvlAmt >
                    calculateOrderTotalCost(
                      cartDetails?.orderTotalProductCost,
                      discountAmount,
                      shipmentAmount
                    ).toFixed(2)) ||
                (isFinvervAvailable === "OVERDUE" &&
                  finboxAvlAmt >
                    calculateOrderTotalCost(
                      cartDetails?.orderTotalProductCost,
                      discountAmount,
                      shipmentAmount
                    ).toFixed(2)) ? (
                  <Box
                    sx={{
                      color: whiteColor,
                      my: 0.5,
                      backgroundColor: topbarColor,
                      borderRadius: 1,
                      py: 1,
                      cursor: "pointer",
                      border: "2px solid " + topbarColor,
                      "&:hover": {
                        backgroundColor: whiteColor,
                        color: topbarColor,
                      },
                      textTransform: "uppercase",
                    }}
                    onClick={handleFinboxClick}
                    loading={finboxLoading}
                  >
                    <Typography
                      textAlign="center"
                      fontSize={14}
                      fontWeight={600}
                    >
                      PAY WITH CREDIT LINE
                    </Typography>
                  </Box>
                ) : null}

                {isFinvervAvailable === "notRegisteredFinbox" ||
                isFinvervAvailable === "APPLY" ? (
                  <Box
                    sx={{
                      backgroundColor: backgroundColor,
                      px: 2,
                      py: 1,
                      borderRadius: 2,
                      my: 2,
                    }}
                  >
                    {fintechApplyData.map((item, index) => {
                      return (
                        <ListItem sx={{ py: 0 }}>
                          <ListItemText
                            sx={{
                              display: "list-item",
                            }}
                            key={index + "main_product"}
                            primary={
                              <Typography
                                variant="subtitle1"
                                fontSize="13px"
                                color={couponColor}
                              >
                                {item}
                              </Typography>
                            }
                          />
                        </ListItem>
                      );
                    })}
                  </Box>
                ) : null}

                {isFinvervAvailable === "INCOMPLETE" ? (
                  <Box
                    sx={{
                      backgroundColor: backgroundColor,
                      px: 2,
                      py: 1,
                      borderRadius: 2,
                      my: 2,
                    }}
                  >
                    <ListItem sx={{ py: 0 }}>
                      <ListItemText
                        sx={{
                          display: "list-item",
                        }}
                        key={"main_product"}
                        primary={
                          <Typography
                            variant="subtitle1"
                            fontSize="13px"
                            color={couponColor}
                          >
                            You are just few steps away from receiving working
                            capital
                          </Typography>
                        }
                      />
                    </ListItem>
                  </Box>
                ) : null}
                {isFinvervAvailable === "APPROVED" ? (
                  <Box
                    sx={{
                      backgroundColor: backgroundColor,
                      px: 2,
                      py: 1,
                      borderRadius: 2,
                      my: 2,
                    }}
                  >
                    <ListItem sx={{ py: 0 }}>
                      <ListItemText
                        sx={{
                          display: "list-item",
                        }}
                        key={"main_product"}
                        primary={
                          <Typography
                            variant="subtitle1"
                            fontSize="13px"
                            color={couponColor}
                          >
                            Just complete last few steps to avail the funds
                          </Typography>
                        }
                      />
                    </ListItem>
                  </Box>
                ) : null}

                {isFinvervAvailable === "ACTIVE" ||
                (isFinvervAvailable === "DUE" &&
                  finboxAvlAmt >
                    calculateOrderTotalCost(
                      cartDetails?.orderTotalProductCost,
                      discountAmount,
                      shipmentAmount
                    ).toFixed(2)) ? (
                  <Box
                    sx={{
                      backgroundColor: backgroundColor,
                      px: 2,
                      py: 1,
                      borderRadius: 2,
                      my: 2,
                    }}
                  >
                    <ListItem sx={{ py: 0 }}>
                      <ListItemText
                        sx={{
                          display: "list-item",
                        }}
                        key={1}
                        primary={
                          <Typography
                            variant="subtitle1"
                            fontSize="13px"
                            color={couponColor}
                          >
                            Available credit: ₹{finboxAvlAmt}
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem sx={{ py: 0 }}>
                      <ListItemText
                        sx={{
                          display: "list-item",
                        }}
                        key={2}
                        primary={
                          <Typography
                            variant="subtitle1"
                            fontSize="13px"
                            color={couponColor}
                          >
                            You will receive an OTP in your registered phone
                            number
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem sx={{ py: 0 }}>
                      <ListItemText
                        sx={{
                          display: "list-item",
                        }}
                        key={3}
                        primary={
                          <Typography
                            variant="subtitle1"
                            fontSize="13px"
                            color={couponColor}
                          >
                            Verify and agree to the terms to use the credit line
                          </Typography>
                        }
                      />
                    </ListItem>
                  </Box>
                ) : null}
                {/* {fintechData.map((item, index) => {
                  return (
                    <ListItem sx={{ py: 0 }}>
                      <ListItemText
                        sx={{
                          display: 'list-item',
                        }}
                        key={index + 'main_product'}
                        primary={
                          <Typography
                            variant='subtitle1'
                            fontSize='13px'
                            color={couponColor}
                          >
                            {item}
                          </Typography>
                        }
                      />
                    </ListItem>
                  )
                })} */}
              </>
            ) : null}

            {currentPage === "address-page" &&
            commercialDetails?.info?.fintech?.active &&
            commercialDetails?.info?.fintech?.partners?.[0]?.name ===
              "finverv" &&
            isFinvervAvailable &&
            cartDetails?.orderTotalProductCost >
              commercialDetails?.info?.fintech?.partners?.[0]?.creditLimit ? (
              <Typography
                variant="body1"
                fontSize={13}
                color={greyShade7}
                textAlign="center"
                sx={{ display: { md: "block", xs: "none" } }}
              >
                OR
              </Typography>
            ) : null}

            {currentPage === "address-page" &&
            commercialDetails?.info?.fintech?.active &&
            cartDetails?.orderTotalProductCost >
              commercialDetails?.info?.fintech?.partners?.[0]?.creditLimit ? (
              <>
                {isFinvervAvailable === "notRegistered" ? (
                  <Box
                    sx={{
                      color: whiteColor,
                      my: 0.5,
                      backgroundColor: topbarColor,
                      borderRadius: 1,
                      py: 1,
                      cursor: "pointer",
                      border: "2px solid " + topbarColor,
                      "&:hover": {
                        backgroundColor: whiteColor,
                        color: topbarColor,
                      },
                    }}
                    onClick={() => {
                      const phoneNumber = getPhoneNumber();
                      console.log("Fintech", phoneNumber?.replace("+91", ""));
                      registeFinvervUser({
                        mobile: phoneNumber?.replace("+91", ""),
                      }).then((res) => {
                        getFinvervUrl().then((resp) => {
                          if (resp?.redirect_url) {
                            setFinvervModal1({
                              show: true,
                              url: resp?.redirect_url,
                            });
                          }
                        });
                      });
                    }}
                  >
                    <Typography
                      textAlign="center"
                      fontSize={14}
                      fontWeight={600}
                      textTransform="uppercase"
                    >
                      Apply for Credit line
                    </Typography>
                  </Box>
                ) : null}
                {isFinvervAvailable === "notCompleted" ? (
                  <Box
                    sx={{
                      color: whiteColor,
                      my: 0.5,
                      backgroundColor: topbarColor,
                      borderRadius: 1,
                      py: 1,
                      cursor: "pointer",
                      border: "2px solid " + topbarColor,
                      "&:hover": {
                        backgroundColor: whiteColor,
                        color: topbarColor,
                      },
                    }}
                    onClick={() => {
                      getFinvervUrl().then((resp) => {
                        if (resp?.redirect_url) {
                          setFinvervModal1({
                            show: true,
                            url: resp?.redirect_url,
                          });
                        }
                      });
                    }}
                  >
                    <Typography
                      textAlign="center"
                      fontSize={14}
                      fontWeight={600}
                      textTransform="uppercase"
                    >
                      Complete your credit application
                    </Typography>
                  </Box>
                ) : null}
                {/* {isFinvervAvailable === 'pendingApproval' ? (
                <Box
                  sx={{
                    color: whiteColor,
                    my: 0.5,
                    backgroundColor: topbarColor,
                    borderRadius: 1,
                    py: 1,
                    cursor: 'pointer',
                    border: '2px solid ' + topbarColor,
                    '&:hover': {
                      backgroundColor: whiteColor,
                      color: topbarColor,
                    },
                  }}
                  component='a'
                  href='https://mail.google.com/mail/u/0/?fs=1&to=credits@costbo.com&tf=cm'
                  target={'_blank'}
                >
                  <Typography textAlign='center' fontSize={14} fontWeight={600}>
                    Your credit application is pending approval
                  </Typography>
                  <Typography textAlign='center' fontSize={12} fontWeight={400}>
                    Contact us at credits@costbo.com
                  </Typography>
                </Box>
              ) : null} */}
                {/* {isFinvervAvailable === 'rejected' ? (
                <Box
                  sx={{
                    color: whiteColor,
                    my: 0.5,
                    backgroundColor: topbarColor,
                    borderRadius: 1,
                    py: 1,
                    cursor: 'pointer',
                    border: '2px solid ' + topbarColor,
                    '&:hover': {
                      backgroundColor: whiteColor,
                      color: topbarColor,
                    },
                  }}
                  component='a'
                  href='https://mail.google.com/mail/u/0/?fs=1&to=credits@costbo.com&tf=cm'
                  target={'_blank'}
                >
                  <Typography textAlign='center' fontSize={14} fontWeight={600}>
                    Your credit application is rejected
                  </Typography>
                  <Typography textAlign='center' fontSize={12} fontWeight={400}>
                    Contact us at credits@costbo.com
                  </Typography>
                </Box>
              ) : null} */}
                {isFinvervAvailable === "balanceAvailable" ? (
                  <Box
                    sx={{
                      color: whiteColor,
                      my: 0.5,
                      backgroundColor: topbarColor,
                      borderRadius: 1,
                      py: 1,
                      cursor: "pointer",
                      border: "2px solid " + topbarColor,
                      "&:hover": {
                        backgroundColor: whiteColor,
                        color: topbarColor,
                      },
                    }}
                    onClick={() => {
                      handleFinvervClick();
                    }}
                  >
                    <Typography
                      textAlign="center"
                      fontSize={14}
                      fontWeight={600}
                      textTransform="uppercase"
                    >
                      {finvervLoading ? (
                        <CircularProgress
                          sx={{ color: whiteColor, mr: 1 }}
                          size={16}
                        />
                      ) : null}{" "}
                      Pay with Credit line
                    </Typography>
                  </Box>
                ) : null}
                {/* {isFinvervAvailable === 'noBalanceAvailable' ? (
                <Box
                  sx={{
                    color: whiteColor,
                    my: 0.5,
                    backgroundColor: topbarColor,
                    borderRadius: 1,
                    py: 1,
                    cursor: 'pointer',
                    border: '2px solid ' + topbarColor,
                    '&:hover': {
                      backgroundColor: whiteColor,
                      color: topbarColor,
                    },
                  }}
                >
                  <Typography textAlign='center' fontSize={14} fontWeight={600}>
                    Available credit is less than order amount
                  </Typography>
                  <Typography textAlign='center' fontSize={12} fontWeight={400}>
                    Please repay your earlier loans and try again
                  </Typography>
                </Box>
              ) : null}
              {isFinvervAvailable === 'exceeded' ? (
                <Box
                  sx={{
                    color: whiteColor,
                    my: 0.5,
                    backgroundColor: topbarColor,
                    borderRadius: 1,
                    py: 1,
                    cursor: 'pointer',
                    border: '2px solid ' + topbarColor,
                    '&:hover': {
                      backgroundColor: whiteColor,
                      color: topbarColor,
                    },
                  }}
                >
                  <Typography textAlign='center' fontSize={14} fontWeight={600}>
                    Your credit limit is ₹ {finAvailValue?.available_amount}
                  </Typography>
                  <Typography textAlign='center' fontSize={12} fontWeight={400}>
                    Limit your purchase
                  </Typography>
                  <Typography textAlign='center' fontSize={12} fontWeight={400}>
                    or use CONFIRM & PAY option
                  </Typography>
                </Box>
              ) : null} */}

                {isFinvervAvailable === "notRegistered" ? (
                  <Box
                    sx={{
                      backgroundColor: backgroundColor,
                      px: 2,
                      py: 1,
                      borderRadius: 2,
                      my: 2,
                    }}
                  >
                    {fintechApplyData.map((item, index) => {
                      return (
                        <ListItem sx={{ py: 0 }}>
                          <ListItemText
                            sx={{
                              display: "list-item",
                            }}
                            key={index + "main_product"}
                            primary={
                              <Typography
                                variant="subtitle1"
                                fontSize="13px"
                                color={couponColor}
                              >
                                {item}
                              </Typography>
                            }
                          />
                        </ListItem>
                      );
                    })}
                  </Box>
                ) : null}
                {isFinvervAvailable === "notCompleted" ? (
                  <Box
                    sx={{
                      backgroundColor: backgroundColor,
                      px: 2,
                      py: 1,
                      borderRadius: 2,
                      my: 2,
                    }}
                  >
                    <ListItem sx={{ py: 0 }}>
                      <ListItemText
                        sx={{
                          display: "list-item",
                        }}
                        key={"main_product"}
                        primary={
                          <Typography
                            variant="subtitle1"
                            fontSize="13px"
                            color={couponColor}
                          >
                            Just complete last few steps to avail the funds
                          </Typography>
                        }
                      />
                    </ListItem>
                  </Box>
                ) : null}

                {isFinvervAvailable === "balanceAvailable" ? (
                  <Box
                    sx={{
                      backgroundColor: backgroundColor,
                      px: 2,
                      py: 1,
                      borderRadius: 2,
                      my: 2,
                    }}
                  >
                    <ListItem sx={{ py: 0 }}>
                      <ListItemText
                        sx={{
                          display: "list-item",
                        }}
                        key={1}
                        primary={
                          <Typography
                            variant="subtitle1"
                            fontSize="13px"
                            color={couponColor}
                          >
                            Available credit: ₹{finAvailValue?.available_amount}
                          </Typography>
                        }
                      />
                    </ListItem>
                    {/* <ListItem sx={{ py: 0 }}>
                    <ListItemText
                      sx={{
                        display: 'list-item',
                      }}
                      key={2}
                      primary={
                        <Typography
                          variant='subtitle1'
                          fontSize='13px'
                          color={couponColor}
                        >
                          You will receive an OTP in your registered phone
                          number
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem sx={{ py: 0 }}>
                    <ListItemText
                      sx={{
                        display: 'list-item',
                      }}
                      key={3}
                      primary={
                        <Typography
                          variant='subtitle1'
                          fontSize='13px'
                          color={couponColor}
                        >
                          Verify and agree to the terms to use the credit line
                        </Typography>
                      }
                    />
                  </ListItem> */}
                  </Box>
                ) : null}

                {/* {isFinvervAvailable !== 'balanceAvailable' ? (
                <Box
                  sx={{
                    backgroundColor: backgroundColor,
                    px: 2,
                    py: 1,
                    borderRadius: 2,
                    my: 2,
                  }}
                >
                  {fintechData.map((item, index) => {
                    return (
                      <ListItem sx={{ py: 0 }}>
                        <ListItemText
                          sx={{
                            display: 'list-item',
                          }}
                          key={index + 'main_product'}
                          primary={
                            <Typography
                              variant='subtitle1'
                              fontSize='13px'
                              color={couponColor}
                            >
                              {item}
                            </Typography>
                          }
                        />
                      </ListItem>
                    )
                  })}
                </Box>
              ) : null} */}
              </>
            ) : null}

            <Box sx={{ display: { md: "block", xs: "none" } }}>
              {(
                (cartDetails?.orderTotalProductCost?.toFixed(2) * 100) /
                commercialDetails?.info?.immediateDiscount
              ).toFixed(2) > 75 &&
              commercialDetails?.info?.immediateDiscount != 0 ? (
                <FreeShippingContentCard
                  amt={
                    commercialDetails?.info?.immediateDiscount -
                    cartDetails?.orderTotalProductCost?.toFixed(2)
                  }
                />
              ) : null}
            </Box>
            {/* {matches && <CartCount count={totalQty} />} */}
            <CartBottomTab
              amount={calculateOrderTotalCost(
                cartDetails?.orderTotalProductCost,
                discountAmount,
                shipmentAmount
              )}
              // finboxStatus={'APPLY'}
              finboxAvlAmt={finboxAvlAmt}
              finvervAmt={finAvailValue?.available_amount}
              onModalFinboxUrl={getModalFinboxUrl}
              handleFinboxClick={handleFinboxClick}
              handleNotCompleted={() => {
                getFinvervUrl().then((resp) => {
                  if (resp?.redirect_url) {
                    setFinvervModal1({
                      show: true,
                      url: resp?.redirect_url,
                    });
                  }
                });
              }}
              handleNotRegistered={() => {
                const phoneNumber = getPhoneNumber();
                console.log("Fintech", phoneNumber?.replace("+91", ""));
                registeFinvervUser({
                  mobile: phoneNumber?.replace("+91", ""),
                }).then((res) => {
                  getFinvervUrl().then((resp) => {
                    if (resp?.redirect_url) {
                      setFinvervModal1({
                        show: true,
                        url: resp?.redirect_url,
                      });
                    }
                  });
                });
              }}
              handleFinvervClick={handleFinvervClick}
              page={currentPage}
              finboxStatus={isFinvervAvailable}
              // btnText={
              //   currentPage === 'discount'
              //     ? 'CONTINUE'
              //     : codAvailable
              //     ? 'PAY NOW'
              //     : 'CONFIRM & PAY'
              // }
              // onBtnClicked={() => {
              //   onConfirm()
              // }}
              totalQty={totalQty}
              isDisabled={isBusinessClosed || isCouponValidating}
              codAmt={
                Number(
                  calculateOrderTotalCost(
                    cartDetails?.orderTotalProductCost,
                    discountAmount,
                    shipmentAmount
                  ).toFixed(2)
                ) + Number(codCost)
              }
              onCodClicked={handleCodClick}
              showCodBtn={codAvailable}
              codCost={codCost}
              confirmPayLoader={confirmPayLoader}
              freeAlert={
                (
                  (cartDetails?.orderTotalProductCost?.toFixed(2) * 100) /
                  commercialDetails?.info?.immediateDiscount
                ).toFixed(2) > 75 &&
                commercialDetails?.info?.immediateDiscount != 0
              }
              freeAmt={
                commercialDetails?.info?.immediateDiscount -
                cartDetails?.orderTotalProductCost?.toFixed(2)
              }
              // Pay cart
              showEasyCartBtn={
                activeBusinessData?.viewBusiness?.owner?.profileId ===
                  userProfileId ||
                activeBusinessData?.viewBusiness?.delegates?.some(
                  (contact) => contact.profileId === userProfileId
                )
              }
              confirmPaycartOrder={confirmPaycartOrder}
              paycartLoading={paycartLoading}
            />

            <Box
              // border={{ md: '1px solid ' + borderLineColor, xs: 'none' }}
              borderRadius={2.2}
              p={{ md: 0, xs: 0 }}
              mt={{ md: 1.5, xs: 1 }}
            >
              <Typography
                variant="h5"
                component="h3"
                fontSize={{ xs: 14, md: 15 }}
                color={{ md: headingColor, xs: blackColor }}
                // borderBottom={{ md: '1px solid ' + borderLineColor, xs: 'none' }}
                textAlign={{ md: "left", xs: "left" }}
              >
                Summary
              </Typography>

              {matches ? (
                <Box
                  sx={{
                    padding: "6px 10px",
                    backgroundColor: whiteColor,
                    borderRadius: "10px",
                    marginTop: "7px",
                    border: "1px solid #e5e5ed",
                  }}
                >
                  <SummaryDataRow
                    label="Subtotal:"
                    value={`₹${cartDetails?.orderTotalProductCost?.toFixed(2)}`}
                  />
                  <SummaryDataRow label="Total Quantity:" value={totalQty} />
                  <SummaryDataRow
                    label="Taxes:"
                    value="Included"
                    isValueGreen
                  />
                  <SummaryDataRow
                    label="Shipping & Handling:"
                    value={
                      shipmentAmount || shipmentAmount == 0
                        ? `₹${Number(shipmentAmount)?.toFixed(2)}`
                        : "-"
                    }
                  />

                  {/* {codAvailable ? (
                <SummaryDataRow
                  label='COD Cost:'
                  value={Number(codCost).toFixed(2)}
                />
              ) : null} */}

                  <SummaryDataRow
                    label="Discount:"
                    value={
                      discountAmount
                        ? `₹${Number(discountAmount)?.toFixed(2)}`
                        : "-"
                    }
                    isValueGreen
                  />
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    pt={0.7}
                    mt={1}
                    borderTop={"1px solid " + borderLineColor}
                  >
                    <Grid item>
                      <Typography
                        variant="h5"
                        component="p"
                        fontSize={14}
                        color={blackColor}
                        display="inline"
                      >
                        Total
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography
                        variant="h5"
                        component="p"
                        fontSize={14}
                        color={greenColor04}
                        display="inline"
                      >
                        {/* ₹{cartDetails?.orderTotalProductCost?.toFixed(2)} */}
                        ₹
                        {calculateOrderTotalCost(
                          cartDetails?.orderTotalProductCost,
                          discountAmount,
                          shipmentAmount
                        ).toFixed(2)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <Box
                  sx={{
                    padding: "10px",
                    backgroundColor: whiteColor,
                    borderRadius: "10px",
                    marginTop: "7px",
                    border: "1px solid #e5e5ed",
                  }}
                >
                  <SummaryDataRow
                    label="Subtotal:"
                    value={`₹${cartDetails?.orderTotalProductCost?.toFixed(2)}`}
                  />
                  <SummaryDataRow label="Total Quantity:" value={totalQty} />
                  <SummaryDataRow
                    label="Taxes:"
                    value="Included"
                    isValueGreen
                  />
                  <SummaryDataRow
                    label="Shipping & Handling:"
                    value={
                      shipmentAmount || shipmentAmount == 0
                        ? `₹${Number(shipmentAmount)?.toFixed(2)}`
                        : "-"
                    }
                  />
                  <SummaryDataRow
                    label="Discount:"
                    value={
                      discountAmount
                        ? `₹${Number(discountAmount)?.toFixed(2)}`
                        : "-"
                    }
                  />
                  <Box border={`1px solid ${backgroundColor}`} mt={1} />
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    pt={0.7}
                  >
                    <Grid item>
                      <Typography
                        variant="h5"
                        component="p"
                        fontSize={{ md: 14, xs: 13 }}
                        color={{ md: headingColor, xs: blackColor }}
                        display="inline"
                      >
                        Total
                      </Typography>
                    </Grid>

                    <Grid item>
                      <Typography
                        variant="h5"
                        component="p"
                        fontSize={14}
                        color={{ md: orangeColor01, xs: blackColor }}
                        display="inline"
                      >
                        ₹
                        {calculateOrderTotalCost(
                          cartDetails?.orderTotalProductCost,
                          discountAmount,
                          shipmentAmount
                        ).toFixed(2)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              )}

              {/* {matches && (
              <Grid
                container
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                pt={0.7}
                borderTop={'1px solid ' + borderLineColor}
              >
                <Grid item>
                  <Typography
                    variant='h5'
                    component='p'
                    fontSize={14}
                    color={headingColor}
                    display='inline'
                  >
                    Total
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography
                    variant='h5'
                    component='p'
                    fontSize={14}
                    color={orangeColor01}
                    display='inline'
                  >
                    ₹
                    {calculateOrderTotalCost(
                      cartDetails?.orderTotalProductCost,
                      discountAmount,
                      shipmentAmount
                    ).toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            )} */}
            </Box>
            <AdditionalSavingCard
              cashback={commercialDetails?.info?.rewardPointPercent}
              savingAmount={totalSavings}
              orderTotal={cartDetails?.orderTotalProductCost}
              cashbackData={rewardpoints}
            />
            {/* <TotalSavingCard savingAmount='2306.00' /> */}
            {/* 
          <AdditionalSavingCard cashback='3%' amount='₹77.70' />

          <DeliveryETA /> */}
            {/* {currentPage === 'discount' ? (
            <Button
              sx={styles.checkoutBtn(isBusinessClosed || isCouponValidating)}
              variant='contained'
              disableElevation
              fullWidth
              onClick={() => setCurrentPage('address-page')}
              disabled={isBusinessClosed || isCouponValidating}
            >
              Continue Checkout
            </Button>
          ) : null} */}

            {/* {currentPage === 'address-page' ? (
            <>
            <Button
                sx={{
                  ...styles.checkoutBtn(isBusinessClosed),
                  border: confirmPayLoader
                    ? 'none'
                    : `1px solid ${loginPrimary}`,
                }}
                variant='contained'
                disableElevation
                fullWidth
                onClick={() => onConfirm()}
                disabled={isBusinessClosed || confirmPayLoader}
              >
                {confirmPayLoader ? (
                  <CircularProgress
                    sx={{ color: loginPrimary, mr: 1 }}
                    size={16}
                  />
                ) : null}{' '}
                {codAvailable ? 'PAY NOW' : 'Confirm & Pay'}
              </Button> 

              {activeBusinessData?.viewBusiness?.owner?.profileId ===
                userProfileId ||
              activeBusinessData?.viewBusiness?.delegates?.some(
                (contact) => contact.profileId === userProfileId
              ) ? (
                <>
                  {matches && (
                    <Typography
                      variant='body1'
                      fontSize={13}
                      color={greyShade7}
                      textAlign='center'
                    >
                      OR
                    </Typography>
                  )}
                  <Button
                    sx={{
                      ...styles.paycartLinkBtn(isBusinessClosed),
                      border: paycartLoading
                        ? 'none'
                        : `1px solid ${blackShade8}`,
                    }}
                    variant='contained'
                    disableElevation
                    fullWidth
                    onClick={() => {
                      confirmPaycartOrder()
                      // navigate(`/${businessName}/paycartOrders`)
                    }}
                    disabled={isBusinessClosed || paycartLoading}
                    startIcon={
                      paycartLoading ? (
                        <CircularProgress
                          size={16}
                          sx={{ color: blackShade8 }}
                        />
                      ) : null
                    }
                  >
                    GENERATE EASY CART LINK
                  </Button>
                </>
              ) : null}
            </>
          ) : null} */}

            {/* {codAvailable && matches ? (
            <Typography
              variant='body1'
              fontSize={13}
              color={greyShade7}
              textAlign='center'
            >
              OR
            </Typography>
          ) : null}

          {codAvailable ? (
            <>
              <Button
                sx={styles.checkoutCodBtn}
                variant='contained'
                disableElevation
                fullWidth
                onClick={handleCodClick}
              >
                {codLoading ? (
                  <CircularProgress
                    size={14}
                    sx={{ color: whiteColor, mr: '8px' }}
                  />
                ) : null}{' '}
                Cash on delivery
                {codChargeTo === 'customer'
                  ? `(₹
                ${(
                  Number(
                    calculateOrderTotalCost(
                      cartDetails?.orderTotalProductCost,
                      discountAmount,
                      shipmentAmount
                    )
                  ) + Number(codCost)
                ).toFixed(2)}
                )`
                  : ''}
              </Button>

              {codChargeTo === 'customer' ? (
                <Typography
                  variant='body1'
                  fontSize={{ xs: 13, md: 13 }}
                  fontWeight={500}
                  color={orangeColor01}
                  textAlign='center'
                  display={{ xs: 'none', md: 'block' }}
                >
                  (COD additional charge ₹{codCost})
                </Typography>
              ) : null}
            </>
          ) : null} */}
            {currentPage === "discount" ? (
              <Box
                sx={{
                  position: "sticky",
                  // right: 0,
                  bottom: 0,
                  backgroundColor: "#fff",
                  boxShadow: "0 3px 9px rgba(0, 0, 0, 0.08)",
                  width: "100%",
                  borderRadius: "10px 10px 0px 0px",
                  border: "1px solid #dcdce5",
                }}
              >
                <Box
                  sx={{
                    padding: "4px 16px",
                    minHeight: "60px",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start", // Equivalent to -webkit-align-items
                      flexDirection: "column", // Equivalent to flex-direction: column
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex", // Flexbox display
                        alignItems: "center", // Align items vertically center
                        justifyContent: "space-between", // Space between items
                        flexDirection: "row", // Horizontal flex direction
                        fontSize: "12px", // Font size
                        lineHeight: "14px", // Line height
                        fontWeight: 400, // Font weight
                        cursor: "pointer", // Cursor pointer on hover
                        position: "relative", // Relative position
                        paddingRight: "25px",
                        color: blackColor01,
                      }}
                    >
                      {" "}
                      {totalQty} items in cart
                    </Typography>
                    <Typography
                      sx={{
                        color: greenColor05,
                        fontSize: "16px",
                        lineHeight: "36px",
                        fontWeight: 600,
                      }}
                    >
                      {" "}
                      {/* ₹{cartDetails?.orderTotalProductCost?.toFixed(2)} */}₹
                      {calculateOrderTotalCost(
                        cartDetails?.orderTotalProductCost,
                        discountAmount,
                        shipmentAmount
                      ).toFixed(2)}
                    </Typography>
                  </Box>
                  <Button
                    sx={{
                      boxShadow: "none", // No box shadow
                      height: "auto", // Auto height
                      lineHeight: "24px", // Line height
                      fontWeight: 600, // Bold text
                      padding: "2px 20px", // Padding around the button
                      backgroundColor: greenColor05, // Background color
                      color: "#fff", // Text color (white)
                      borderRadius: "5px", // Rounded corners
                      border: `1px solid ${greenColor05}`, // Border with the same color as background
                      fontSize: "12px", // Font size
                      textTransform: "uppercase",
                      height: "34px",
                      "&:hover": {
                        // Optional: styling for hover state
                        backgroundColor: whiteColor,
                        color: greenColor05,
                        boxShadow: "none",
                      },
                    }}
                    onClick={() => {
                      setCurrentPage("address-page");
                    }}
                    disableElevation
                    disabled={isBusinessClosed || isCouponValidating}
                  >
                    Continue Checkout
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  position: "sticky",
                  // right: 0,
                  bottom: 0,
                  backgroundColor: "#fff",
                  boxShadow: "0 3px 9px rgba(0, 0, 0, 0.08)",
                  width: "100%",
                  borderRadius: "10px 10px 0px 0px",
                  border: "1px solid #dcdce5",
                }}
              >
                <Box
                  sx={{
                    padding: "4px 16px",
                    minHeight: "60px",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start", // Equivalent to -webkit-align-items
                      flexDirection: "column", // Equivalent to flex-direction: column
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        display: "flex", // Flexbox display
                        alignItems: "center", // Align items vertically center
                        justifyContent: "space-between", // Space between items
                        flexDirection: "row", // Horizontal flex direction
                        fontSize: "12px", // Font size
                        lineHeight: "14px", // Line height
                        fontWeight: 400, // Font weight
                        cursor: "pointer", // Cursor pointer on hover
                        position: "relative", // Relative position
                        paddingRight: "25px",
                        color: blackColor01,
                      }}
                    >
                      {" "}
                      {totalQty} items in cart
                    </Typography>
                    <Typography
                      sx={{
                        color: greenColor05,
                        fontSize: "16px",
                        lineHeight: "36px",
                        fontWeight: 600,
                      }}
                    >
                      {" "}
                      {/* ₹{cartDetails?.orderTotalProductCost?.toFixed(2)} */}₹
                      {calculateOrderTotalCost(
                        cartDetails?.orderTotalProductCost,
                        discountAmount,
                        shipmentAmount
                      ).toFixed(2)}
                    </Typography>
                  </Box>
                  <Button
                    sx={{
                      boxShadow: "none", // No box shadow
                      height: "auto", // Auto height
                      lineHeight: "24px", // Line height
                      fontWeight: 600, // Bold text
                      padding: "2px 20px", // Padding around the button
                      backgroundColor: greenColor05, // Background color
                      color: "#fff", // Text color (white)
                      borderRadius: "5px", // Rounded corners
                      border: `1px solid ${greenColor05}`, // Border with the same color as background
                      fontSize: "12px", // Font size
                      textTransform: "uppercase",
                      height: "34px",
                      "&:hover": {
                        // Optional: styling for hover state
                        backgroundColor: whiteColor,
                        color: greenColor05,
                        boxShadow: "none",
                      },
                    }}
                    onClick={() => {
                      if (selectedOption === "payNow") {
                        onConfirm(); // Call the onConfirm function for "Pay Now"
                      } else if (selectedOption === "generateLink") {
                        confirmPaycartOrder(); // Call the function to generate an easy cart link
                      } else if (selectedOption === "cod") {
                        handleCodClick(); // Call the function for handling Cash on Delivery (COD)
                      } else {
                        alert("Please select a payment option"); // Fallback in case no option is selected
                      }
                    }}
                    disableElevation
                    disabled={
                      isBusinessClosed ||
                      confirmPayLoader ||
                      paycartLoading ||
                      codLoading
                    }
                  >
                    {confirmPayLoader || paycartLoading || codLoading ? (
                      <CircularProgress
                        sx={{ color: "inherit", mr: 1 }}
                        size={16}
                      />
                    ) : null}{" "}
                    {selectedOption === "payNow"
                      ? "Place Order "
                      : selectedOption === "generateLink"
                      ? "Place Order "
                      : selectedOption === "cod"
                      ? "Place Order "
                      : ""}
                  </Button>
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
        {/* <Grid container spacing={{ xs: 1, md: 3 }}>
        <Grid item xs={12} md={8.25}>
        </Grid>

        <Grid
          item
          xs={12}
          md={3.75}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
        >
          <Box
            sx={{
              position: 'sticky !important',
              right: 0,
              bottom: 0, // Sticks to the bottom of the grid item
              backgroundColor: '#fff',
              boxShadow: '0 3px 9px rgba(0, 0, 0, 0.08)',
              width: '100%',
              borderRadius: '10px 10px 0 0',
              border: '1px solid #dcdce5',
            }}
          >
            <Box
              sx={{
                width: '100%',
                padding: '6px 16px',
                minHeight: '64px',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Typography
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    fontSize: '12px',
                    lineHeight: '14px',
                    fontWeight: 400,
                    cursor: 'pointer',
                    position: 'relative',
                    paddingRight: '25px',
                  }}
                >
                  {totalQty} items in cart
                </Typography>
                <Typography
                  sx={{
                    color: greenColor05,
                    fontSize: '21px',
                    lineHeight: '36px',
                    fontWeight: 600,
                  }}
                >
                  ₹{cartDetails?.orderTotalProductCost?.toFixed(2)}
                </Typography>
              </Box>

              <Box
                sx={{
                  boxShadow: 'none',
                  height: 'auto',
                  lineHeight: '24px',
                  fontWeight: 600,
                  padding: '6px 27px',
                  backgroundColor: greenColor05,
                  color: '#fff',
                  borderRadius: '40px',
                  border: `1px solid ${greenColor05}`,
                  fontSize: '14px',
                  '&:hover': {
                    backgroundColor: '#0e756d',
                    boxShadow: 'none',
                  },
                }}
                onClick={() => {
                  setCurrentPage('address-page')
                }}
                disabled={isBusinessClosed || isCouponValidating}
                mr={3}
              >
                Continue Checkout
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid> */}

        <ModalCard
          handleClose={() => setWorkingHourPopup(false)}
          open={workingHourPopup}
          width="24%"
        >
          <WorkingHoursCard
            title="Working Hours"
            workigHourData={overviewDetails?.businessHours}
          />
        </ModalCard>

        <ModalCard1
          handleClose={() => {
            getFinboxBannerData();
            setFinvervModal({ show: false, url: "" });
          }}
          open={finvervModal.show}
          width="30%"
          height="80vh"
        >
          <Box
            width="100%"
            minHeight="600px"
            sx={{ borderRadius: "12px", backgroundColor: whiteColor }}
          >
            {/* <Box p={1} display='flex' justifyContent={'center'}>
            <Box
              component='img'
              src={'https://storage.googleapis.com/bodefaults/shopweb/logo_v3.png'}
              alt='CostBo'
              height='40px'
            />
          </Box> */}

            <iframe
              src={finvervModal?.url?.slice(0, -5) + "true"}
              style={{
                border: "none",
                borderRadius: "12px",
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                width: "100%",
                height: "100%",
              }}
            />
            {/* <iframe  src={finvervModal.url} width={'100%'} height={'550px'} /> */}
          </Box>
        </ModalCard1>

        <ModalCard1
          handleClose={() => {
            getFinvervLinesData();
            setFinvervModal1({ show: false, url: "" });
          }}
          open={finvervModal1.show}
          width="30%"
          height="80vh"
        >
          <Box
            width="100%"
            minHeight="600px"
            sx={{ borderRadius: "12px", backgroundColor: whiteColor }}
          >
            <iframe
              src={finvervModal1.url}
              style={{
                border: "none",
                borderRadius: "12px",
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                width: "100%",
                height: "100%",
              }}
            />
          </Box>
        </ModalCard1>

        <ModalCard
          handleClose={() => {
            setOtpModal(false);
            setFinboxLoading(false);
          }}
          open={otpModal}
          width="27%"
        >
          <OtpCard
            phoneNumber={getPhoneNumber()}
            onSuccess={(token) => {
              setOtpModal(false);
              placeFinboxOrder(token);
            }}
          />
        </ModalCard>

        <AlertModal
          open={alertOpen}
          content={alertMsg}
          btnName="OK"
          handleConfirm={() => {
            setAlertOpen(false);
            if (alertMsg == unicomInventoryErrMsg) {
              scrolltoCartItems();
            }
          }}
          hideCancel
          handleClose={() => {
            setAlertOpen(false);
            if (alertMsg == unicomInventoryErrMsg) {
              scrolltoCartItems();
            }
          }}
        />

        {/* <AlertDialog
          content={alertMsg}
          isOpen={alertOpen}
          onClose={() => {
            setAlertOpen(false);
            if (alertMsg == unicomInventoryErrMsg) {
              scrolltoCartItems();
            }
          }}
        /> */}

        <AlertModal
          open={openConfirmAlert?.isOpen}
          content={openConfirmAlert.message}
          handleConfirm={() => {
            onPaycartPaymentLink();
          }}
          handleClose={() => {
            setOpenConfirmAlert({ isOpen: false, message: "" });
          }}
        />
        <AlertModal
          open={codConfirmAlert}
          content="Cash to pay upon delivery of your items"
          handleConfirm={() => {
            if (codLoading) return;
            placeCODOrder();
          }}
          handleClose={() => {
            setCodConfirmAlert(false);
          }}
          amt={(
            Number(
              calculateOrderTotalCost(
                cartDetails?.orderTotalProductCost,
                discountAmount,
                shipmentAmount
              )
            ) + Number(codChargeTo === "customer" ? codCost : 0)
          ).toFixed(2)}
          msg={
            codChargeTo === "customer"
              ? `COD additional charge ₹${codCost}`
              : ""
          }
        />

        {/* <ConfirmAlert
          isOpen={openConfirmAlert?.isOpen}
          content={openConfirmAlert.message}
          onCancel={() => {
            setOpenConfirmAlert({ isOpen: false, message: "" });
          }}
          onConfirm={() => {
            onPaycartPaymentLink();
          }}
        /> */}

        {/* <CODConfirmationAlert
          content="Cash to pay upon delivery of your items"
          isOpen={codConfirmAlert}
          onClose={() => {
            setCodConfirmAlert(false);
          }}
          onConfirm={() => {
            if (codLoading) return;
            placeCODOrder();
          }}
          codCost={codCost}
          orderCost={(
            Number(
              calculateOrderTotalCost(
                cartDetails?.orderTotalProductCost,
                discountAmount,
                shipmentAmount
              )
            ) + Number(codChargeTo === "customer" ? codCost : 0)
          ).toFixed(2)}
        /> */}
      </Container>
    </>
  ) : (
    <Container sx={{ maxWidth: "1300px !important", mt: 0.5, pb: "250px" }}>
      {/* <BreadcrumbsSection
        links={[
          { label: 'Cart', to: `/Cart` },
          { label: 'Discount/Delivery', to: `/DiscountDelivery` },
        ]}
      />

      <Container sx={{ maxWidth: '900px !important' }}>
        <CartStepper  
          steps={[
            {
              label: 'Cart',
              route: `/${businessName}/cart`,
            },
            {
              label: 'Discount/Delivery',
              route: `#`,
            },
            {
              label: 'Confirmation',
              route: `#`,
            },
          ]}
          activeStep={1}
        />
      </Container> */}
      <div id="sign-in-button"></div>
      <Grid container spacing={{ xs: 1, md: 3.5 }}>
        <Grid item xs={12} md={9}>
          <Box
            sx={{
              display: currentPage === "discount" ? "block" : "none",
            }}
          >
            <Box display={{ xs: "block", md: "none" }}>
              <Typography
                sx={{
                  display: "block",
                  fontSize: "13.5px",
                  marginBlockStart: "0.83em",
                  marginBlockEnd: "0.83em",
                  marginInlineStart: "0px",
                  marginInlineEnd: "0px",
                  fontWeight: 600,
                  unicodeBidi: "isolate",
                  color: blackColor,
                  pb: 0.5,
                }}
              >
                Discounts/Coupons (Optional)
              </Typography>
            </Box>

            {!hideDiscountSection ? (
              isDiscountSection ? (
                matches ? (
                  <Box sx={styles.cartContainer}>
                    {/* Availabel Discount Section */}
                    <>
                      <AvailableDiscount
                        rewardpoints={rewardpoints}
                        dealsCouponsDetails={dealsCouponsDetails}
                        commercialDetails={commercialDetails}
                        cartDetails={cartDetails}
                        firstTimeDiscountData={firstTimeDiscountData}
                        discountAmount={discountAmount}
                        setDiscountAmount={(data) => setDiscountAmount(data)}
                        appliedDiscountDetails={appliedDiscountDetails}
                        setAppliedDiscountDetails={(data) =>
                          setAppliedDiscountDetails(data)
                        }
                        checkResellerData={checkResellerData}
                        instantDiscounts={instantDiscounts}
                        isCouponValidating={isCouponValidating}
                        setIsCouponValidating={setIsCouponValidating}
                      />
                    </>
                  </Box>
                ) : (
                  <Box mb={1.5}>
                    <AvailableDiscount
                      rewardpoints={rewardpoints}
                      dealsCouponsDetails={dealsCouponsDetails}
                      commercialDetails={commercialDetails}
                      cartDetails={cartDetails}
                      firstTimeDiscountData={firstTimeDiscountData}
                      discountAmount={discountAmount}
                      setDiscountAmount={(data) => setDiscountAmount(data)}
                      appliedDiscountDetails={appliedDiscountDetails}
                      setAppliedDiscountDetails={(data) =>
                        setAppliedDiscountDetails(data)
                      }
                      checkResellerData={checkResellerData}
                      instantDiscounts={instantDiscounts}
                      isCouponValidating={isCouponValidating}
                      setIsCouponValidating={setIsCouponValidating}
                    />
                  </Box>
                )
              ) : (
                <Box mt={1.5}>
                  <EmptyCard
                    iconWidth="57px"
                    iconHeignt="56px"
                    message="No discounts available currently. Please proceed to Checkout"
                    description="Please check this section for any Limited time offers, Cashback redemption, Instant cash discounts during next order."
                    // description={
                    //   <>
                    //     <Typography
                    //       variant='body1'
                    //       fontSize={{ xs: 13.5, md: 14 }}
                    //       color={{ md: blackColor, xs: greyColor9 }}
                    //       lineHeight={{ xs: '20px', md: '23px' }}
                    //       mt={0.7}
                    //       textAlign={'center'}
                    //     >
                    //       No discounts available currently. Please proceed to
                    //       Checkout
                    //     </Typography>
                    //     <Typography
                    //       variant='body1'
                    //       fontSize={{ xs: 13.5, md: 14 }}
                    //       color={{ md: blackColor, xs: greyColor9 }}
                    //       lineHeight={{ xs: '20px', md: '23px' }}
                    //       mt={0.7}
                    //       textAlign={'center'}
                    //     >
                    //       'Please check this section for any Limited time
                    //       offers, Cashback redemption, Instant cash discounts
                    //       during next order.'
                    //     </Typography>
                    //   </>
                    // }
                    // imageUrl={dealsEmptyImgUrl}
                    imageUrl={
                      matches
                        ? "https://storage.googleapis.com/bodefaults/shopweb/discountsAndAddress/discount-empty.svg"
                        : "https://storage.googleapis.com/bodefaults/shopweb/discountsAndAddress/discount-empty.svg"
                    }
                    imgpadding={"0 0 0px 0"}
                    cardbottom={2}
                  />
                </Box>
              )
            ) : (
              <CounponCardsMobileLoader />
            )}
          </Box>{" "}
          {/* <Box
            sx={{
              display: currentPage === 'address-page' ? 'block' : 'none',
            }}
          >
            <Box display={{ xs: 'block', md: 'none' }}>
              <Box display={'flex'} justifyContent={'space-between'} pb={1}>
                <Typography
                  sx={{
                    display: 'block',
                    fontSize: '14px',
                    marginBlockStart: '0.83em',
                    marginBlockEnd: '0.83em',
                    marginInlineStart: '0px',
                    marginInlineEnd: '0px',
                    fontWeight: 600,
                    unicodeBidi: 'isolate',
                    color: blackColor,
                    mb: 1,
                  }}
                >
                  Delivery Address
                </Typography>
                {!matches && (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      color: '#F58220',
                      fontSize: '13px',
                      fontWeight: 500,
                      textDecoration: 'underline',
                      marginLeft: '15px',
                      mt: 1.5,
                    }}
                  >
                    <Box
                      component='img'
                      
                      // src='https://storage.googleapis.com/bodefaults/shopweb/discounts_wallet.svg'
                      alt={' CostBo'}
                      width={{ xs: '13px', md: '46px' }}
                      height={{ xs: '13px', md: '30px' }}
                      sx={{ color: '#F58220', mt: 0.5 }}
                    />
                    <Typography
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        color: '#F58220',
                        fontSize: '13px',
                        fontWeight: 500,
                        textDecoration: 'underline',
                        marginLeft: '5px',
                      }}
                    >
                      Search
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Box> */}
          {/* Instore Pickup Section */}
          {/* {instoreDisplay ? (
            <>
              <DiscountHeader
                icon={
                  <StoreMallDirectory
                    sx={{
                      fontSize: { xs: '22px', md: '26px' },
                      color: headingColor,
                    }}
                  />
                }
                title='INSTORE PICKUP'
                showInstorePick
                isInstorePick={isInstorePick}
                onInstorePickClick={() => {
                  console.log('Instore Pick')
                  setIsInstorePick(!isInstorePick)
                  setIsDeliverySelected(!isInstorePick)
                }}
              />
            </>
          ) : null} */}
          <Box
            sx={{
              display: currentPage === "address-page" ? "block" : "none",
            }}
          >
            {matches ? (
              <Box sx={styles.cartContainer}>
                {!isInstorePick && addressDeliveryDisplay ? (
                  <>
                    {/* Address Section */}
                    <AddressSection
                      openAddAddress={openAddAddress}
                      addressLoader={addressLoader}
                      setOpenAddAddress={setOpenAddAddress}
                      openUpdateAddress={openUpdateAddress}
                      setOpenUpdateAddress={setOpenUpdateAddress}
                      currentSlideIndex={currentSlideIndex}
                      setCurrentSlideIndex={setCurrentSlideIndex}
                      isAdd={isAdd}
                      setIsAdd={setIsAdd}
                      editAddress={editAddress}
                      setEditAddress={setEditAddress}
                      makeDefault={makeDefault}
                      setMakeDefault={setMakeDefault}
                      addressDetails={addressDetails}
                      accountDetails={accountDetails}
                      seletedAddress={seletedAddress}
                      setSeletedAddress={setSeletedAddress}
                      setErrorMessage={setErrorMessage}
                      setNoAddressError={setNoAddressError}
                      resetDelivery={() => {
                        // Clearing previously selected delivery method
                        setActiveDeliveryType(null);
                        setDeliveryMethod("");

                        setCostboPremier({
                          totalAmount: "",
                          originalAmount: "",
                          show: false,
                          error: false,
                          type: "",
                          shipmentTax: 0,
                        });
                        setCostboPremierDeliverETA("");
                        setCostboPremierHeavy({
                          totalAmount: "",
                          originalAmount: "",
                          show: false,
                          error: false,
                          type: "",
                          shipmentTax: 0,
                        });
                        setCostboPremierDeliverETAHeavy("");
                        setTelyportRushDelivery({
                          totalAmount: "",
                          show: false,
                          deliveryETA: "",
                          originalAmount: "",
                          error: false,
                          type: "",
                          shipmentTax: 0,
                        });
                        setTelyportExpressDelivery({
                          totalAmount: "",
                          show: false,
                          deliveryETA: "",
                          originalAmount: "",
                          error: false,
                          type: "",
                          shipmentTax: 0,
                        });
                        setTelyportStandardDelivery({
                          totalAmount: "",
                          show: false,
                          deliveryETA: "",
                          originalAmount: "",
                          error: false,
                          type: "",
                          shipmentTax: 0,
                        });
                        setBusinessDelivery({});
                        setOndcDelivery({
                          totalAmount: "",
                          show: false,
                          deliveryETA: "",
                          originalAmount: "",
                          error: false,
                          type: "",
                          shipmentTax: 0,
                        });
                        ondcDeliveryCheck = false;
                        b2cDelhiveryCheck = false;
                        b2bDelhiveryCheck = false;
                        businessDeliveryCheck = false;
                        telyportDeliveryCheck = false;
                      }}
                    />

                    <Divider light />
                    <GSTDetialsSection
                      gstChecked={gstChecked}
                      setGstChecked={setGstChecked}
                      selectedGst={gstSelected}
                      setGstSelected={setGstSelected}
                      gstNumber={gstNumber}
                      setGstNumber={setGstNumber}
                    />
                    <Divider light />
                    {/* Delivery Method Section */}
                    <DeliveryMethodSection
                      deliveryMethod={deliveryMethod}
                      errorMessage={errorMessage}
                      noAddressError={noAddressError}
                      seletedAddress={seletedAddress}
                      costboPremier={costboPremier}
                      businessDelivery={businessDelivery}
                      costboPremierHeavy={costboPremierHeavy}
                      telyportStandardDelivery={telyportStandardDelivery}
                      telyportRushDelivery={telyportRushDelivery}
                      telyportExpressDelivery={telyportExpressDelivery}
                      isFreeShipping={isFreeShipping}
                      costboPremierDeliverETA={costboPremierDeliverETA}
                      additionalDiscount={additionalDiscount}
                      costboPremierDeliverETAHeavy={
                        costboPremierDeliverETAHeavy
                      }
                      ondcDelivery={ondcDelivery}
                      onDeliveryMethodChange={(value) => {
                        console.log("DELIVERY_SELECTED", value);
                        shipmentValueCod = value.amount;
                        selectDeliveryMethod(value.type);
                        setShipmentAmount(value.amount);
                        setShipmentEta(value.eta);
                        if (
                          value.discountType !== "none" &&
                          value.discountType !== ""
                        ) {
                          setSelectedDiscountType({
                            discountType: value.discountType,
                            discountAmount: (
                              value.originalAmount - value.amount
                            )?.toFixed(2),
                          });
                        }
                        setShipmentTaxValue(value?.shipmentTax || 0);
                      }}
                      totalCartWeightKg={totalCartWeightKg}
                    />
                    {telyportRushDelivery?.show ||
                    telyportExpressDelivery?.show ||
                    telyportStandardDelivery?.show ? (
                      <Box>
                        <Typography
                          variant="subtitle1"
                          component="p"
                          fontSize={{ xs: 12, ms: 13 }}
                          color={minOrderTextColor}
                          display="inline"
                          sx={{ lineHeight: "20px" }}
                        >
                          *Rush and Express delivery available only during
                          business{" "}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          component="p"
                          fontSize={{ xs: 12, ms: 13 }}
                          color={orangeColor01}
                          display="inline"
                          sx={{ lineHeight: "20px", cursor: "pointer" }}
                          onClick={() => setWorkingHourPopup(true)}
                        >
                          open hours
                        </Typography>
                      </Box>
                    ) : null}
                  </>
                ) : null}
              </Box>
            ) : (
              <>
                {!isInstorePick && addressDeliveryDisplay ? (
                  <>
                    {/* Address Section */}
                    <AddressSection
                      openAddAddress={openAddAddress}
                      addressLoader={addressLoader}
                      setOpenAddAddress={setOpenAddAddress}
                      openUpdateAddress={openUpdateAddress}
                      setOpenUpdateAddress={setOpenUpdateAddress}
                      currentSlideIndex={currentSlideIndex}
                      setCurrentSlideIndex={setCurrentSlideIndex}
                      isAdd={isAdd}
                      setIsAdd={setIsAdd}
                      editAddress={editAddress}
                      setEditAddress={setEditAddress}
                      makeDefault={makeDefault}
                      setMakeDefault={setMakeDefault}
                      addressDetails={addressDetails}
                      accountDetails={accountDetails}
                      seletedAddress={seletedAddress}
                      setSeletedAddress={setSeletedAddress}
                      setErrorMessage={setErrorMessage}
                      setNoAddressError={setNoAddressError}
                      resetDelivery={() => {
                        // Clearing previously selected delivery method
                        setActiveDeliveryType(null);
                        setDeliveryMethod("");

                        setCostboPremier({
                          totalAmount: "",
                          originalAmount: "",
                          show: false,
                          error: false,
                          type: "",
                          shipmentTax: 0,
                        });
                        setCostboPremierDeliverETA("");
                        setCostboPremierHeavy({
                          totalAmount: "",
                          originalAmount: "",
                          show: false,
                          error: false,
                          type: "",
                          shipmentTax: 0,
                        });
                        setCostboPremierDeliverETAHeavy("");
                        setTelyportRushDelivery({
                          totalAmount: "",
                          show: false,
                          deliveryETA: "",
                          originalAmount: "",
                          error: false,
                          type: "",
                          shipmentTax: 0,
                        });
                        setTelyportExpressDelivery({
                          totalAmount: "",
                          show: false,
                          deliveryETA: "",
                          originalAmount: "",
                          error: false,
                          type: "",
                          shipmentTax: 0,
                        });
                        setTelyportStandardDelivery({
                          totalAmount: "",
                          show: false,
                          deliveryETA: "",
                          originalAmount: "",
                          error: false,
                          type: "",
                          shipmentTax: 0,
                        });
                        setBusinessDelivery({});
                        setOndcDelivery({
                          totalAmount: "",
                          show: false,
                          deliveryETA: "",
                          originalAmount: "",
                          error: false,
                          type: "",
                          shipmentTax: 0,
                        });
                        ondcDeliveryCheck = false;
                        b2cDelhiveryCheck = false;
                        b2bDelhiveryCheck = false;
                        businessDeliveryCheck = false;
                        telyportDeliveryCheck = false;
                      }}
                    />
                  </>
                ) : null}
                <Box sx={styles.cartContainer} mb={{ md: 1, xs: "14px" }}>
                  {!isInstorePick && addressDeliveryDisplay ? (
                    <>
                      <GSTDetialsSection
                        gstChecked={gstChecked}
                        setGstChecked={setGstChecked}
                        selectedGst={gstSelected}
                        setGstSelected={setGstSelected}
                        gstNumber={gstNumber}
                        setGstNumber={setGstNumber}
                      />
                    </>
                  ) : null}
                </Box>
                <Box sx={styles.cartContainer} mb={{ md: 1, xs: "14px" }}>
                  {!isInstorePick && addressDeliveryDisplay ? (
                    <>
                      <DeliveryMethodSection
                        deliveryMethodLoader={deliveryMethodLoader}
                        deliveryMethod={deliveryMethod}
                        errorMessage={errorMessage}
                        noAddressError={noAddressError}
                        seletedAddress={seletedAddress}
                        costboPremier={costboPremier}
                        businessDelivery={businessDelivery}
                        costboPremierHeavy={costboPremierHeavy}
                        telyportStandardDelivery={telyportStandardDelivery}
                        telyportRushDelivery={telyportRushDelivery}
                        telyportExpressDelivery={telyportExpressDelivery}
                        isFreeShipping={isFreeShipping}
                        costboPremierDeliverETA={costboPremierDeliverETA}
                        additionalDiscount={additionalDiscount}
                        costboPremierDeliverETAHeavy={
                          costboPremierDeliverETAHeavy
                        }
                        ondcDelivery={ondcDelivery}
                        onDeliveryMethodChange={(value) => {
                          console.log("DELIVERY_SELECTED", value);
                          shipmentValueCod = value.amount;
                          selectDeliveryMethod(value.type);
                          setShipmentAmount(value.amount);
                          setShipmentEta(value.eta);
                          if (
                            value.discountType !== "none" &&
                            value.discountType !== ""
                          ) {
                            setSelectedDiscountType({
                              discountType: value.discountType,
                              discountAmount: (
                                value.originalAmount - value.amount
                              )?.toFixed(2),
                            });
                          }
                          setShipmentTaxValue(value?.shipmentTax || 0);
                        }}
                        totalCartWeightKg={totalCartWeightKg}
                      />
                      {telyportRushDelivery?.show ||
                      telyportExpressDelivery?.show ||
                      telyportStandardDelivery?.show ? (
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Typography
                            width={"77%"}
                            variant="subtitle1"
                            component="p"
                            fontSize={{ xs: 12, ms: 13 }}
                            color={{ md: minOrderTextColor, xs: blackColor }}
                            display="inline"
                            sx={{ lineHeight: "20px" }}
                          >
                            *Rush and Express delivery available only during
                            business open hours
                          </Typography>
                          <Typography
                            width={"30%"}
                            variant="subtitle1"
                            component="p"
                            fontSize={{ xs: 12, ms: 13 }}
                            color={{ md: orangeColor01, xs: "#F58220" }}
                            sx={{
                              lineHeight: "20px",
                              cursor: "pointer",
                              textDecoration: "underline",
                              textAlign: "end",
                            }}
                            onClick={() => setWorkingHourPopup(true)}
                          >
                            Working Hours
                          </Typography>
                        </Box>
                      ) : null}
                    </>
                  ) : null}
                </Box>
              </>
            )}

            <Box sx={styles.cartContainer} mt={2} mb={{ md: 4, xs: 5 }}>
              {/* Special Instructions */}
              <Box my={0.5}>
                <DiscountHeader
                  icon={
                    matches ? (
                      <Lightbulb
                        sx={{
                          fontSize: { xs: "19px", md: "20px" },
                          color: headingColor,
                          mb: "-5px",
                        }}
                      />
                    ) : (
                      <TipsAndUpdatesIcon
                        sx={{
                          fontSize: { xs: "22px", md: "20px" },
                          color: headingColor,
                          mb: "-5px",
                          mr: 0.5,
                        }}
                      />
                    )
                  }
                  title={
                    matches
                      ? "SPECIAL INSTRUCTIONS TO SELLER"
                      : "Special Instructions To Seller"
                  }
                  subtitle="(Ex: Please wrap it individually)"
                  specialInstructions
                  collapseInstructions={collapseInstructions}
                  onToggleInstructions={() => {
                    setToggleCollapseInstructions(!collapseInstructions);
                  }}
                />

                <Box
                  sx={{
                    display: collapseInstructions ? "none" : "block",
                  }}
                  width={{ xs: "80vw", md: "100%" }}
                  mt="6px"
                >
                  <TextareaAutosize
                    minRows={3}
                    placeholder="(Character limit 500)"
                    aria-label="maximum height"
                    style={styles.inputBox}
                    value={consumerNotes}
                    maxLength={500}
                    onChange={({ target }) => {
                      const finalValue = allowOnlyEnglish(target.value);
                      setConsumerNotes(finalValue);
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              px: 1.5,
              py: { md: 0.5, xs: 0 },
              borderRadius: 2.2,
              border: "1px solid" + borderLineColor,
              backgroundColor: { md: whiteColor, xs: whiteColor },
            }}
            ref={unicomErrRef}
          >
            <DiscountHeader
              icon={
                <ShoppingBasketRoundedIcon
                  sx={{
                    margin: "0 6px -3px 0",
                    fontSize: { md: "18px", xs: "22px" },
                    // height:"15px",
                    //width:"22px"
                  }}
                />
              }
              title={
                matches
                  ? `ITEMS IN CART`
                  : `
                  ${
                    cartDetails?.productList?.reduce((accumulator, product) => {
                      return accumulator + product.currentQuantity;
                    }, 0) || 0
                    // Number(
                    //   cartDetails?.productList?.[0]?.currentQuantity +
                    //     cartDetails?.productList?.[1]?.currentQuantity
                    // )
                  } 
                    Items in Cart`
              }
              items
              collapseCartTabel={collapseCartTabel}
              onToggleCartItems={() => {
                setToggleCollapseCartTabel(!collapseCartTabel);
              }}
            />
            <Grid
              container
              py={1.5}
              display={{ xs: "none", md: "inline-flex" }}
            >
              <Grid item xs={5}>
                <Typography
                  variant="subtitle1"
                  component="h4"
                  fontSize="14px"
                  color={delectIconInactiveColor}
                  ml={1.8}
                >
                  PRODUCT
                </Typography>
              </Grid>
              <Grid item xs={2.5}>
                <Typography
                  variant="subtitle1"
                  component="h4"
                  fontSize="14px"
                  color={delectIconInactiveColor}
                >
                  UNIT PRICE
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  variant="subtitle1"
                  component="h4"
                  fontSize="14px"
                  color={delectIconInactiveColor}
                >
                  QUANTITY
                </Typography>
              </Grid>
              <Grid item xs={1.3}>
                <Typography
                  variant="subtitle1"
                  component="h4"
                  fontSize="14px"
                  color={delectIconInactiveColor}
                  ml={0.6}
                >
                  PRICE
                </Typography>
              </Grid>
              <Grid item xs={1.2}>
                <Typography
                  variant="subtitle1"
                  component="h4"
                  fontSize="14px"
                  color={delectIconInactiveColor}
                >
                  SAVINGS
                </Typography>
              </Grid>
            </Grid>

            <Box
              sx={{
                display: {
                  xs: collapseCartTabel ? "none" : "block",
                  md: "block",
                },
              }}
            >
              {cartDetails?.productList?.map((item, index) => {
                const savings =
                  Number(item?.productOriginalPrice) *
                    Number(item?.currentQuantity) -
                  Number(item?.productSalePrice) *
                    Number(item?.currentQuantity);
                totalQty = totalQty + item.currentQuantity;
                totalSavings = totalSavings + savings;

                const errorMsg = getPartnerInventoryError(catalogPartnerValidationData, item);
                return (
                  <TableProductCard
                    key={"cart_product_" + index}
                    image={item?.productPicURL}
                    productName={item?.productName}
                    oldUnitPrice={item?.productOriginalPrice}
                    unitprice={item?.productSalePrice}
                    discount={item?.percentageOff}
                    price={item?.productTotalPrice}
                    saving={savings}
                    quantity={item.currentQuantity}
                    isOutofStock={item.isOutOfStock}
                    cartData={cartDetails?.productList}
                    cartId={cartDetails?.cartId}
                    product={item}
                    readOnly={true}
                    errorMsg={errorMsg}
                  />
                );
              })}

              <Box
                display={{ xs: "flex", md: "none" }}
                alignItems="center"
                justifyContent="center"
                my={1}
                borderTop={`1px solid ${borderLineColor}`}
                py={1}
                width={"100%"}
              >
                <Button
                  variant="outlined"
                  sx={styles.actionBtn}
                  // size='small'
                  // component={Link}
                  // to={`/${businessName}/cart`}
                  onClick={()=>{
                    navigateTo(navigate, `cart`)
                  }}
                >
                  Modify Cart
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={3}>
          {currentPage === "discount" ? (
            <Button
              sx={styles.checkoutBtn(isBusinessClosed || isCouponValidating)}
              variant="contained"
              disableElevation
              fullWidth
              onClick={() => {
                setCurrentPage("address-page");
              }}
              disabled={isBusinessClosed || isCouponValidating}
            >
              Continue Checkout
            </Button>
          ) : null}

          {currentPage === "address-page" ? (
            <>
              <Button
                sx={{
                  ...styles.checkoutBtn(isBusinessClosed),
                  border: confirmPayLoader
                    ? "none"
                    : `1px solid ${loginPrimary}`,
                }}
                variant="contained"
                disableElevation
                fullWidth
                onClick={() => onConfirm()}
                disabled={isBusinessClosed || confirmPayLoader}
              >
                {confirmPayLoader ? (
                  <CircularProgress
                    sx={{ color: loginPrimary, mr: 1 }}
                    size={16}
                  />
                ) : null}{" "}
                {codAvailable ? "Pay Now" : "Confirm & Pay"}
              </Button>

              {activeBusinessData?.viewBusiness?.owner?.profileId ===
                userProfileId ||
              activeBusinessData?.viewBusiness?.delegates?.some(
                (contact) => contact.profileId === userProfileId
              ) ? (
                <>
                  {matches && (
                    <Typography
                      variant="body1"
                      fontSize={13}
                      color={greyShade7}
                      textAlign="center"
                    >
                      OR
                    </Typography>
                  )}
                  <Button
                    sx={{
                      ...styles.paycartLinkBtn(isBusinessClosed),
                      border: paycartLoading
                        ? "none"
                        : `1px solid ${blackShade8}`,
                    }}
                    variant="contained"
                    disableElevation
                    fullWidth
                    onClick={() => {
                      confirmPaycartOrder();
                      // navigate(`/${businessName}/paycartOrders`)
                    }}
                    disabled={isBusinessClosed || paycartLoading}
                    startIcon={
                      paycartLoading ? (
                        <CircularProgress
                          size={16}
                          sx={{ color: blackShade8 }}
                        />
                      ) : null
                    }
                  >
                    GENERATE EASY CART LINK
                  </Button>
                </>
              ) : null}
            </>
          ) : null}

          {codAvailable && matches ? (
            <Typography
              variant="body1"
              fontSize={13}
              color={greyShade7}
              textAlign="center"
            >
              OR
            </Typography>
          ) : null}

          {codAvailable ? (
            <>
              <Button
                sx={styles.checkoutCodBtn}
                variant="contained"
                disableElevation
                fullWidth
                onClick={handleCodClick}
              >
                {codLoading ? (
                  <CircularProgress
                    size={14}
                    sx={{ color: whiteColor, mr: "8px" }}
                  />
                ) : null}{" "}
                Cash on delivery
                {codChargeTo === "customer"
                  ? `(₹
                ${(
                  Number(
                    calculateOrderTotalCost(
                      cartDetails?.orderTotalProductCost,
                      discountAmount,
                      shipmentAmount
                    )
                  ) + Number(codCost)
                ).toFixed(2)}
                )`
                  : ""}
              </Button>

              {codChargeTo === "customer" ? (
                <Typography
                  component="span"
                  fontSize={{ xs: 13, md: 13 }}
                  fontWeight={500}
                  color={orangeColor01}
                  textAlign="center"
                  display={{ xs: "none", md: "block" }}
                >
                  (COD additional charge ₹{codCost})
                </Typography>
              ) : (
                ""
              )}
            </>
          ) : null}

          {currentPage === "address-page" &&
          commercialDetails?.info?.fintech?.active &&
          commercialDetails?.info?.fintech?.partners?.[0]?.name === "finbox" &&
          (isFinvervAvailable === "notRegisteredFinbox" ||
            isFinvervAvailable === "APPLY" ||
            isFinvervAvailable === "ACTIVE" ||
            isFinvervAvailable === "INCOMPLETE" ||
            isFinvervAvailable === "APPROVED" ||
            isFinvervAvailable === "DUE") &&
          cartDetails?.orderTotalProductCost >
            commercialDetails?.info?.fintech?.partners?.[0]?.creditLimit ? (
            <Typography
              variant="body1"
              fontSize={13}
              color={greyShade7}
              textAlign="center"
              sx={{ display: { md: "block", xs: "none" } }}
            >
              OR
            </Typography>
          ) : null}
          {currentPage === "address-page" &&
          commercialDetails?.info?.fintech?.active &&
          commercialDetails?.info?.fintech?.partners?.[0]?.name === "finbox" &&
          (isFinvervAvailable === "notRegisteredFinbox" ||
            isFinvervAvailable === "APPLY" ||
            isFinvervAvailable === "ACTIVE" ||
            isFinvervAvailable === "INCOMPLETE" ||
            isFinvervAvailable === "APPROVED" ||
            isFinvervAvailable === "DUE") &&
          cartDetails?.orderTotalProductCost >
            commercialDetails?.info?.fintech?.partners?.[0]?.creditLimit ? (
            <>
              {isFinvervAvailable === "notRegisteredFinbox" ? (
                <Box
                  sx={{
                    color: whiteColor,
                    my: 0.5,
                    backgroundColor: topbarColor,
                    borderRadius: 1,
                    py: 1,
                    cursor: "pointer",
                    border: "2px solid " + topbarColor,
                    "&:hover": {
                      backgroundColor: whiteColor,
                      color: topbarColor,
                    },
                    textTransform: "uppercase",
                  }}
                  onClick={() => {
                    // setFinvervModal({
                    //   show: true,
                    //   // url: resp?.redirect_url,
                    // })
                    // const phoneNumber = getPhoneNumber()
                    // console.log('Fintech', phoneNumber?.replace('+91', ''))
                    // registeFinvervUser({
                    //   mobile: phoneNumber?.replace('+91', ''),
                    // }).then((res) => {
                    //   getFinvervUrl().then((resp) => {
                    //     if (resp?.redirect_url) {
                    //       setFinvervModal({
                    //         show: true,
                    //         url: resp?.redirect_url,
                    //       })
                    //       // window.open(resp?.redirect_url, '_blank')
                    //     }
                    //   })
                    // })
                    const phoneNumber = getPhoneNumber();
                    const customerID = getProfileId();
                    const name = accountDetails?.account?.name;
                    const email = accountDetails?.account?.email;
                    console.log("Fintech", phoneNumber?.replace("+91", ""));
                    registeFinboxUser({
                      customerID,
                      mobile: phoneNumber?.replace("+91", ""),
                    }).then((res) => {
                      finboxPartnerPush({ customerID, name, email }).then(
                        () => {
                          getFinboxEligibility().then(() => {
                            getFinboxUrl({
                              redirectURL: window.location.href,
                            }).then((resp) => {
                              console.log("resprespresp", resp);
                              if (resp?.data?.url) {
                                setFinvervModal({
                                  show: true,
                                  url: resp?.data?.url,
                                });
                              }
                            });
                          });
                        }
                      );
                    });
                  }}
                >
                  <Typography textAlign="center" fontSize={14} fontWeight={600}>
                    Apply for Credit line
                  </Typography>
                  {/* <Typography textAlign='center' fontSize={12} fontWeight={400}>
                    (Available above{' '}
                    {
                      commercialDetails?.info?.fintech?.partners?.[0]
                        ?.creditLimit
                    }
                    )
                  </Typography> */}
                </Box>
              ) : null}
              {isFinvervAvailable === "APPLY" ? (
                <Box
                  sx={{
                    color: whiteColor,
                    my: 0.5,
                    backgroundColor: topbarColor,
                    borderRadius: 1,
                    py: 1,
                    cursor: "pointer",
                    border: "2px solid " + topbarColor,
                    "&:hover": {
                      backgroundColor: whiteColor,
                      color: topbarColor,
                    },
                    display: { md: "block", xs: "none" },
                    textTransform: "uppercase",
                  }}
                  onClick={() => {
                    getFinboxUrl({
                      redirectURL: window.location.href,
                    }).then((resp) => {
                      console.log("resprespresp", resp);
                      if (resp?.data?.url) {
                        setFinvervModal({
                          show: true,
                          url: resp?.data?.url,
                        });
                      }
                    });
                  }}
                >
                  <Typography textAlign="center" fontSize={14} fontWeight={600}>
                    Apply for Credit line
                  </Typography>
                </Box>
              ) : null}
              {isFinvervAvailable === "INCOMPLETE" ||
              isFinvervAvailable === "APPROVED" ? (
                <Box
                  sx={{
                    color: whiteColor,
                    my: 0.5,
                    backgroundColor: topbarColor,
                    borderRadius: 1,
                    py: 1,
                    cursor: "pointer",
                    border: "2px solid " + topbarColor,
                    "&:hover": {
                      backgroundColor: whiteColor,
                      color: topbarColor,
                    },
                    display: { md: "block", xs: "none" },
                    textTransform: "uppercase",
                  }}
                  onClick={() => {
                    getFinboxUrl({
                      redirectURL: window.location.href,
                    }).then((resp) => {
                      console.log("resprespresp", resp);
                      if (resp?.data?.url) {
                        setFinvervModal({
                          show: true,
                          url: resp?.data?.url,
                        });
                      }
                    });
                  }}
                >
                  <Typography textAlign="center" fontSize={14} fontWeight={600}>
                    Complete your credit application
                  </Typography>
                </Box>
              ) : null}
              {isFinvervAvailable === "ACTIVE" ||
              (isFinvervAvailable === "DUE" &&
                finboxAvlAmt >
                  calculateOrderTotalCost(
                    cartDetails?.orderTotalProductCost,
                    discountAmount,
                    shipmentAmount
                  ).toFixed(2)) ||
              (isFinvervAvailable === "OVERDUE" &&
                finboxAvlAmt >
                  calculateOrderTotalCost(
                    cartDetails?.orderTotalProductCost,
                    discountAmount,
                    shipmentAmount
                  ).toFixed(2)) ? (
                <Box
                  sx={{
                    color: whiteColor,
                    my: 0.5,
                    backgroundColor: topbarColor,
                    borderRadius: 1,
                    py: 1,
                    cursor: "pointer",
                    border: "2px solid " + topbarColor,
                    "&:hover": {
                      backgroundColor: whiteColor,
                      color: topbarColor,
                    },
                    textTransform: "uppercase",
                  }}
                  onClick={handleFinboxClick}
                  loading={finboxLoading}
                >
                  <Typography textAlign="center" fontSize={14} fontWeight={600}>
                    PAY WITH CREDIT LINE
                  </Typography>
                </Box>
              ) : null}

              {isFinvervAvailable === "notRegisteredFinbox" ||
              isFinvervAvailable === "APPLY" ? (
                <Box
                  sx={{
                    backgroundColor: backgroundColor,
                    px: 2,
                    py: 1,
                    borderRadius: 2,
                    my: 2,
                  }}
                >
                  {fintechApplyData.map((item, index) => {
                    return (
                      <ListItem sx={{ py: 0 }}>
                        <ListItemText
                          sx={{
                            display: "list-item",
                          }}
                          key={index + "main_product"}
                          primary={
                            <Typography
                              variant="subtitle1"
                              fontSize="13px"
                              color={couponColor}
                            >
                              {item}
                            </Typography>
                          }
                        />
                      </ListItem>
                    );
                  })}
                </Box>
              ) : null}

              {isFinvervAvailable === "INCOMPLETE" ? (
                <Box
                  sx={{
                    backgroundColor: backgroundColor,
                    px: 2,
                    py: 1,
                    borderRadius: 2,
                    my: 2,
                  }}
                >
                  <ListItem sx={{ py: 0 }}>
                    <ListItemText
                      sx={{
                        display: "list-item",
                      }}
                      key={"main_product"}
                      primary={
                        <Typography
                          variant="subtitle1"
                          fontSize="13px"
                          color={couponColor}
                        >
                          You are just few steps away from receiving working
                          capital
                        </Typography>
                      }
                    />
                  </ListItem>
                </Box>
              ) : null}
              {isFinvervAvailable === "APPROVED" ? (
                <Box
                  sx={{
                    backgroundColor: backgroundColor,
                    px: 2,
                    py: 1,
                    borderRadius: 2,
                    my: 2,
                  }}
                >
                  <ListItem sx={{ py: 0 }}>
                    <ListItemText
                      sx={{
                        display: "list-item",
                      }}
                      key={"main_product"}
                      primary={
                        <Typography
                          variant="subtitle1"
                          fontSize="13px"
                          color={couponColor}
                        >
                          Just complete last few steps to avail the funds
                        </Typography>
                      }
                    />
                  </ListItem>
                </Box>
              ) : null}

              {isFinvervAvailable === "ACTIVE" ||
              (isFinvervAvailable === "DUE" &&
                finboxAvlAmt >
                  calculateOrderTotalCost(
                    cartDetails?.orderTotalProductCost,
                    discountAmount,
                    shipmentAmount
                  ).toFixed(2)) ? (
                <Box
                  sx={{
                    backgroundColor: backgroundColor,
                    px: 2,
                    py: 1,
                    borderRadius: 2,
                    my: 2,
                  }}
                >
                  <ListItem sx={{ py: 0 }}>
                    <ListItemText
                      sx={{
                        display: "list-item",
                      }}
                      key={1}
                      primary={
                        <Typography
                          variant="subtitle1"
                          fontSize="13px"
                          color={couponColor}
                        >
                          Available credit: ₹{finboxAvlAmt}
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem sx={{ py: 0 }}>
                    <ListItemText
                      sx={{
                        display: "list-item",
                      }}
                      key={2}
                      primary={
                        <Typography
                          variant="subtitle1"
                          fontSize="13px"
                          color={couponColor}
                        >
                          You will receive an OTP in your registered phone
                          number
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem sx={{ py: 0 }}>
                    <ListItemText
                      sx={{
                        display: "list-item",
                      }}
                      key={3}
                      primary={
                        <Typography
                          variant="subtitle1"
                          fontSize="13px"
                          color={couponColor}
                        >
                          Verify and agree to the terms to use the credit line
                        </Typography>
                      }
                    />
                  </ListItem>
                </Box>
              ) : null}
              {/* {fintechData.map((item, index) => {
                  return (
                    <ListItem sx={{ py: 0 }}>
                      <ListItemText
                        sx={{
                          display: 'list-item',
                        }}
                        key={index + 'main_product'}
                        primary={
                          <Typography
                            variant='subtitle1'
                            fontSize='13px'
                            color={couponColor}
                          >
                            {item}
                          </Typography>
                        }
                      />
                    </ListItem>
                  )
                })} */}
            </>
          ) : null}

          {currentPage === "address-page" &&
          commercialDetails?.info?.fintech?.active &&
          commercialDetails?.info?.fintech?.partners?.[0]?.name === "finverv" &&
          isFinvervAvailable &&
          cartDetails?.orderTotalProductCost >
            commercialDetails?.info?.fintech?.partners?.[0]?.creditLimit ? (
            <Typography
              variant="body1"
              fontSize={13}
              color={greyShade7}
              textAlign="center"
              sx={{ display: { md: "block", xs: "none" } }}
            >
              OR
            </Typography>
          ) : null}

          {currentPage === "address-page" &&
          commercialDetails?.info?.fintech?.active &&
          cartDetails?.orderTotalProductCost >
            commercialDetails?.info?.fintech?.partners?.[0]?.creditLimit ? (
            <>
              {isFinvervAvailable === "notRegistered" ? (
                <Box
                  sx={{
                    color: whiteColor,
                    my: 0.5,
                    backgroundColor: topbarColor,
                    borderRadius: 1,
                    py: 1,
                    cursor: "pointer",
                    border: "2px solid " + topbarColor,
                    "&:hover": {
                      backgroundColor: whiteColor,
                      color: topbarColor,
                    },
                  }}
                  onClick={() => {
                    const phoneNumber = getPhoneNumber();
                    console.log("Fintech", phoneNumber?.replace("+91", ""));
                    registeFinvervUser({
                      mobile: phoneNumber?.replace("+91", ""),
                    }).then((res) => {
                      getFinvervUrl().then((resp) => {
                        if (resp?.redirect_url) {
                          setFinvervModal1({
                            show: true,
                            url: resp?.redirect_url,
                          });
                        }
                      });
                    });
                  }}
                >
                  <Typography
                    textAlign="center"
                    fontSize={14}
                    fontWeight={600}
                    textTransform="uppercase"
                  >
                    Apply for Credit line
                  </Typography>
                </Box>
              ) : null}
              {isFinvervAvailable === "notCompleted" ? (
                <Box
                  sx={{
                    color: whiteColor,
                    my: 0.5,
                    backgroundColor: topbarColor,
                    borderRadius: 1,
                    py: 1,
                    cursor: "pointer",
                    border: "2px solid " + topbarColor,
                    "&:hover": {
                      backgroundColor: whiteColor,
                      color: topbarColor,
                    },
                  }}
                  onClick={() => {
                    getFinvervUrl().then((resp) => {
                      if (resp?.redirect_url) {
                        setFinvervModal1({
                          show: true,
                          url: resp?.redirect_url,
                        });
                      }
                    });
                  }}
                >
                  <Typography
                    textAlign="center"
                    fontSize={14}
                    fontWeight={600}
                    textTransform="uppercase"
                  >
                    Complete your credit application
                  </Typography>
                </Box>
              ) : null}
              {/* {isFinvervAvailable === 'pendingApproval' ? (
                <Box
                  sx={{
                    color: whiteColor,
                    my: 0.5,
                    backgroundColor: topbarColor,
                    borderRadius: 1,
                    py: 1,
                    cursor: 'pointer',
                    border: '2px solid ' + topbarColor,
                    '&:hover': {
                      backgroundColor: whiteColor,
                      color: topbarColor,
                    },
                  }}
                  component='a'
                  href='https://mail.google.com/mail/u/0/?fs=1&to=credits@costbo.com&tf=cm'
                  target={'_blank'}
                >
                  <Typography textAlign='center' fontSize={14} fontWeight={600}>
                    Your credit application is pending approval
                  </Typography>
                  <Typography textAlign='center' fontSize={12} fontWeight={400}>
                    Contact us at credits@costbo.com
                  </Typography>
                </Box>
              ) : null} */}
              {/* {isFinvervAvailable === 'rejected' ? (
                <Box
                  sx={{
                    color: whiteColor,
                    my: 0.5,
                    backgroundColor: topbarColor,
                    borderRadius: 1,
                    py: 1,
                    cursor: 'pointer',
                    border: '2px solid ' + topbarColor,
                    '&:hover': {
                      backgroundColor: whiteColor,
                      color: topbarColor,
                    },
                  }}
                  component='a'
                  href='https://mail.google.com/mail/u/0/?fs=1&to=credits@costbo.com&tf=cm'
                  target={'_blank'}
                >
                  <Typography textAlign='center' fontSize={14} fontWeight={600}>
                    Your credit application is rejected
                  </Typography>
                  <Typography textAlign='center' fontSize={12} fontWeight={400}>
                    Contact us at credits@costbo.com
                  </Typography>
                </Box>
              ) : null} */}
              {isFinvervAvailable === "balanceAvailable" ? (
                <Box
                  sx={{
                    color: whiteColor,
                    my: 0.5,
                    backgroundColor: topbarColor,
                    borderRadius: 1,
                    py: 1,
                    cursor: "pointer",
                    border: "2px solid " + topbarColor,
                    "&:hover": {
                      backgroundColor: whiteColor,
                      color: topbarColor,
                    },
                  }}
                  onClick={() => {
                    handleFinvervClick();
                  }}
                >
                  <Typography
                    textAlign="center"
                    fontSize={14}
                    fontWeight={600}
                    textTransform="uppercase"
                  >
                    {finvervLoading ? (
                      <CircularProgress
                        sx={{ color: whiteColor, mr: 1 }}
                        size={16}
                      />
                    ) : null}{" "}
                    Pay with Credit line
                  </Typography>
                </Box>
              ) : null}
              {/* {isFinvervAvailable === 'noBalanceAvailable' ? (
                <Box
                  sx={{
                    color: whiteColor,
                    my: 0.5,
                    backgroundColor: topbarColor,
                    borderRadius: 1,
                    py: 1,
                    cursor: 'pointer',
                    border: '2px solid ' + topbarColor,
                    '&:hover': {
                      backgroundColor: whiteColor,
                      color: topbarColor,
                    },
                  }}
                >
                  <Typography textAlign='center' fontSize={14} fontWeight={600}>
                    Available credit is less than order amount
                  </Typography>
                  <Typography textAlign='center' fontSize={12} fontWeight={400}>
                    Please repay your earlier loans and try again
                  </Typography>
                </Box>
              ) : null}
              {isFinvervAvailable === 'exceeded' ? (
                <Box
                  sx={{
                    color: whiteColor,
                    my: 0.5,
                    backgroundColor: topbarColor,
                    borderRadius: 1,
                    py: 1,
                    cursor: 'pointer',
                    border: '2px solid ' + topbarColor,
                    '&:hover': {
                      backgroundColor: whiteColor,
                      color: topbarColor,
                    },
                  }}
                >
                  <Typography textAlign='center' fontSize={14} fontWeight={600}>
                    Your credit limit is ₹ {finAvailValue?.available_amount}
                  </Typography>
                  <Typography textAlign='center' fontSize={12} fontWeight={400}>
                    Limit your purchase
                  </Typography>
                  <Typography textAlign='center' fontSize={12} fontWeight={400}>
                    or use CONFIRM & PAY option
                  </Typography>
                </Box>
              ) : null} */}

              {isFinvervAvailable === "notRegistered" ? (
                <Box
                  sx={{
                    backgroundColor: backgroundColor,
                    px: 2,
                    py: 1,
                    borderRadius: 2,
                    my: 2,
                  }}
                >
                  {fintechApplyData.map((item, index) => {
                    return (
                      <ListItem sx={{ py: 0 }}>
                        <ListItemText
                          sx={{
                            display: "list-item",
                          }}
                          key={index + "main_product"}
                          primary={
                            <Typography
                              variant="subtitle1"
                              fontSize="13px"
                              color={couponColor}
                            >
                              {item}
                            </Typography>
                          }
                        />
                      </ListItem>
                    );
                  })}
                </Box>
              ) : null}
              {isFinvervAvailable === "notCompleted" ? (
                <Box
                  sx={{
                    backgroundColor: backgroundColor,
                    px: 2,
                    py: 1,
                    borderRadius: 2,
                    my: 2,
                  }}
                >
                  <ListItem sx={{ py: 0 }}>
                    <ListItemText
                      sx={{
                        display: "list-item",
                      }}
                      key={"main_product"}
                      primary={
                        <Typography
                          variant="subtitle1"
                          fontSize="13px"
                          color={couponColor}
                        >
                          Just complete last few steps to avail the funds
                        </Typography>
                      }
                    />
                  </ListItem>
                </Box>
              ) : null}

              {isFinvervAvailable === "balanceAvailable" ? (
                <Box
                  sx={{
                    backgroundColor: backgroundColor,
                    px: 2,
                    py: 1,
                    borderRadius: 2,
                    my: 2,
                  }}
                >
                  <ListItem sx={{ py: 0 }}>
                    <ListItemText
                      sx={{
                        display: "list-item",
                      }}
                      key={1}
                      primary={
                        <Typography
                          variant="subtitle1"
                          fontSize="13px"
                          color={couponColor}
                        >
                          Available credit: ₹{finAvailValue?.available_amount}
                        </Typography>
                      }
                    />
                  </ListItem>
                  {/* <ListItem sx={{ py: 0 }}>
                    <ListItemText
                      sx={{
                        display: 'list-item',
                      }}
                      key={2}
                      primary={
                        <Typography
                          variant='subtitle1'
                          fontSize='13px'
                          color={couponColor}
                        >
                          You will receive an OTP in your registered phone
                          number
                        </Typography>
                      }
                    />
                  </ListItem>
                  <ListItem sx={{ py: 0 }}>
                    <ListItemText
                      sx={{
                        display: 'list-item',
                      }}
                      key={3}
                      primary={
                        <Typography
                          variant='subtitle1'
                          fontSize='13px'
                          color={couponColor}
                        >
                          Verify and agree to the terms to use the credit line
                        </Typography>
                      }
                    />
                  </ListItem> */}
                </Box>
              ) : null}

              {/* {isFinvervAvailable !== 'balanceAvailable' ? (
                <Box
                  sx={{
                    backgroundColor: backgroundColor,
                    px: 2,
                    py: 1,
                    borderRadius: 2,
                    my: 2,
                  }}
                >
                  {fintechData.map((item, index) => {
                    return (
                      <ListItem sx={{ py: 0 }}>
                        <ListItemText
                          sx={{
                            display: 'list-item',
                          }}
                          key={index + 'main_product'}
                          primary={
                            <Typography
                              variant='subtitle1'
                              fontSize='13px'
                              color={couponColor}
                            >
                              {item}
                            </Typography>
                          }
                        />
                      </ListItem>
                    )
                  })}
                </Box>
              ) : null} */}
            </>
          ) : null}

          <Box sx={{ display: { md: "block", xs: "none" } }}>
            {(
              (cartDetails?.orderTotalProductCost?.toFixed(2) * 100) /
              commercialDetails?.info?.immediateDiscount
            ).toFixed(2) > 75 &&
            commercialDetails?.info?.immediateDiscount != 0 ? (
              <FreeShippingContentCard
                amt={
                  commercialDetails?.info?.immediateDiscount -
                  cartDetails?.orderTotalProductCost?.toFixed(2)
                }
              />
            ) : null}
          </Box>
          {matches && <CartCount count={totalQty} />}
          <CartBottomTab
            amount={calculateOrderTotalCost(
              cartDetails?.orderTotalProductCost,
              discountAmount,
              shipmentAmount
            )}
            // finboxStatus={'APPLY'}
            finboxAvlAmt={finboxAvlAmt}
            finvervAmt={finAvailValue?.available_amount}
            onModalFinboxUrl={getModalFinboxUrl}
            handleFinboxClick={handleFinboxClick}
            handleNotCompleted={() => {
              getFinvervUrl().then((resp) => {
                if (resp?.redirect_url) {
                  setFinvervModal1({
                    show: true,
                    url: resp?.redirect_url,
                  });
                }
              });
            }}
            handleNotRegistered={() => {
              const phoneNumber = getPhoneNumber();
              console.log("Fintech", phoneNumber?.replace("+91", ""));
              registeFinvervUser({
                mobile: phoneNumber?.replace("+91", ""),
              }).then((res) => {
                getFinvervUrl().then((resp) => {
                  if (resp?.redirect_url) {
                    setFinvervModal1({
                      show: true,
                      url: resp?.redirect_url,
                    });
                  }
                });
              });
            }}
            handleFinvervClick={handleFinvervClick}
            page={currentPage}
            finboxStatus={isFinvervAvailable}
            btnText={
              currentPage === "discount"
                ? "CONTINUE"
                : codAvailable
                ? "PAY NOW"
                : "CONFIRM & PAY"
            }
            onBtnClicked={() => {
              onConfirm();
            }}
            totalQty={totalQty}
            isDisabled={isBusinessClosed || isCouponValidating}
            codAmt={
              Number(
                calculateOrderTotalCost(
                  cartDetails?.orderTotalProductCost,
                  discountAmount,
                  shipmentAmount
                ).toFixed(2)
              ) + Number(codCost)
            }
            onCodClicked={handleCodClick}
            showCodBtn={codAvailable}
            codCost={codCost}
            confirmPayLoader={confirmPayLoader}
            freeAlert={
              (
                (cartDetails?.orderTotalProductCost?.toFixed(2) * 100) /
                commercialDetails?.info?.immediateDiscount
              ).toFixed(2) > 75 &&
              commercialDetails?.info?.immediateDiscount != 0
            }
            freeAmt={
              commercialDetails?.info?.immediateDiscount -
              cartDetails?.orderTotalProductCost?.toFixed(2)
            }
            // Pay cart
            showEasyCartBtn={
              activeBusinessData?.viewBusiness?.owner?.profileId ===
                userProfileId ||
              activeBusinessData?.viewBusiness?.delegates?.some(
                (contact) => contact.profileId === userProfileId
              )
            }
            confirmPaycartOrder={confirmPaycartOrder}
            paycartLoading={paycartLoading}
          />

          <Box
            border={{ md: "1px solid " + borderLineColor, xs: "none" }}
            borderRadius={2.2}
            p={{ md: 1.85, xs: 0 }}
            mt={{ md: 3, xs: 1 }}
          >
            <Typography
              variant="h5"
              component="h3"
              fontSize={{ xs: 14, md: 15 }}
              color={{ md: headingColor, xs: blackColor }}
              pb={{ md: 1.5, xs: 0 }}
              borderBottom={{ md: "1px solid " + borderLineColor, xs: "none" }}
              textAlign={{ md: "right", xs: "left" }}
            >
              Summary
            </Typography>

            {matches ? (
              <Box py={1}>
                <SummaryDataRow
                  label="Subtotal:"
                  value={`₹${cartDetails?.orderTotalProductCost?.toFixed(2)}`}
                />
                <SummaryDataRow label="Total Quantity:" value={totalQty} />
                <SummaryDataRow label="Taxes:" value="Included" isValueGreen />
                <SummaryDataRow
                  label="Shipping & Handling:"
                  value={
                    shipmentAmount || shipmentAmount == 0
                      ? `₹${Number(shipmentAmount)?.toFixed(2)}`
                      : "-"
                  }
                />

                {/* {codAvailable ? (
                <SummaryDataRow
                  label='COD Cost:'
                  value={Number(codCost).toFixed(2)}
                />
              ) : null} */}

                <SummaryDataRow
                  label="Discount:"
                  value={
                    discountAmount
                      ? `₹${Number(discountAmount)?.toFixed(2)}`
                      : "-"
                  }
                  isValueGreen
                />
              </Box>
            ) : (
              <Box
                sx={{
                  padding: "10px",
                  backgroundColor: whiteColor,
                  borderRadius: "10px",
                  marginTop: "7px",
                  border: "1px solid #e5e5ed",
                }}
              >
                <SummaryDataRow
                  label="Subtotal:"
                  value={`₹${cartDetails?.orderTotalProductCost?.toFixed(2)}`}
                />
                <SummaryDataRow label="Total Quantity:" value={totalQty} />
                <SummaryDataRow label="Taxes:" value="Included" isValueGreen />
                <SummaryDataRow
                  label="Shipping & Handling:"
                  value={
                    shipmentAmount || shipmentAmount == 0
                      ? `₹${Number(shipmentAmount)?.toFixed(2)}`
                      : "-"
                  }
                />
                <SummaryDataRow
                  label="Discount:"
                  value={
                    discountAmount
                      ? `₹${Number(discountAmount)?.toFixed(2)}`
                      : "-"
                  }
                />
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  pt={0.7}
                  mt={1}
                  borderTop={"1px solid " + borderLineColor}
                >
                  <Grid item>
                    <Typography
                      variant="h5"
                      component="p"
                      fontSize={{ md: 14, xs: 13 }}
                      color={{ md: headingColor, xs: blackColor }}
                      display="inline"
                    >
                      Total
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Typography
                      variant="h5"
                      component="p"
                      fontSize={14}
                      color={{ md: orangeColor01, xs: blackColor }}
                      display="inline"
                    >
                      ₹
                      {calculateOrderTotalCost(
                        cartDetails?.orderTotalProductCost,
                        discountAmount,
                        shipmentAmount
                      ).toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )}

            {matches && (
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                pt={0.7}
                borderTop={"1px solid " + borderLineColor}
              >
                <Grid item>
                  <Typography
                    variant="h5"
                    component="p"
                    fontSize={14}
                    color={headingColor}
                    display="inline"
                  >
                    Total
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography
                    variant="h5"
                    component="p"
                    fontSize={14}
                    color={orangeColor01}
                    display="inline"
                  >
                    ₹
                    {calculateOrderTotalCost(
                      cartDetails?.orderTotalProductCost,
                      discountAmount,
                      shipmentAmount
                    ).toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Box>
          <AdditionalSavingCard
            cashback={commercialDetails?.info?.rewardPointPercent}
            savingAmount={totalSavings}
            orderTotal={cartDetails?.orderTotalProductCost}
            cashbackData={rewardpoints}
          />
          {/* <TotalSavingCard savingAmount='2306.00' /> */}
          {/* 
          <AdditionalSavingCard cashback='3%' amount='₹77.70' />

          <DeliveryETA /> */}
          {currentPage === "discount" ? (
            <Button
              sx={styles.checkoutBtn(isBusinessClosed || isCouponValidating)}
              variant="contained"
              disableElevation
              fullWidth
              onClick={() => setCurrentPage("address-page")}
              disabled={isBusinessClosed || isCouponValidating}
            >
              Continue Checkout
            </Button>
          ) : null}

          {currentPage === "address-page" ? (
            <>
              <Button
                sx={{
                  ...styles.checkoutBtn(isBusinessClosed),
                  border: confirmPayLoader
                    ? "none"
                    : `1px solid ${loginPrimary}`,
                }}
                variant="contained"
                disableElevation
                fullWidth
                onClick={() => onConfirm()}
                disabled={isBusinessClosed || confirmPayLoader}
              >
                {confirmPayLoader ? (
                  <CircularProgress
                    sx={{ color: loginPrimary, mr: 1 }}
                    size={16}
                  />
                ) : null}{" "}
                {codAvailable ? "PAY NOW" : "Confirm & Pay"}
              </Button>

              {activeBusinessData?.viewBusiness?.owner?.profileId ===
                userProfileId ||
              activeBusinessData?.viewBusiness?.delegates?.some(
                (contact) => contact.profileId === userProfileId
              ) ? (
                <>
                  {matches && (
                    <Typography
                      variant="body1"
                      fontSize={13}
                      color={greyShade7}
                      textAlign="center"
                    >
                      OR
                    </Typography>
                  )}
                  <Button
                    sx={{
                      ...styles.paycartLinkBtn(isBusinessClosed),
                      border: paycartLoading
                        ? "none"
                        : `1px solid ${blackShade8}`,
                    }}
                    variant="contained"
                    disableElevation
                    fullWidth
                    onClick={() => {
                      confirmPaycartOrder();
                      // navigate(`/${businessName}/paycartOrders`)
                    }}
                    disabled={isBusinessClosed || paycartLoading}
                    startIcon={
                      paycartLoading ? (
                        <CircularProgress
                          size={16}
                          sx={{ color: blackShade8 }}
                        />
                      ) : null
                    }
                  >
                    GENERATE EASY CART LINK
                  </Button>
                </>
              ) : null}
            </>
          ) : null}

          {codAvailable && matches ? (
            <Typography
              variant="body1"
              fontSize={13}
              color={greyShade7}
              textAlign="center"
            >
              OR
            </Typography>
          ) : null}

          {codAvailable ? (
            <>
              <Button
                sx={styles.checkoutCodBtn}
                variant="contained"
                disableElevation
                fullWidth
                onClick={handleCodClick}
              >
                {codLoading ? (
                  <CircularProgress
                    size={14}
                    sx={{ color: whiteColor, mr: "8px" }}
                  />
                ) : null}{" "}
                Cash on delivery
                {codChargeTo === "customer"
                  ? `(₹
                ${(
                  Number(
                    calculateOrderTotalCost(
                      cartDetails?.orderTotalProductCost,
                      discountAmount,
                      shipmentAmount
                    )
                  ) + Number(codCost)
                ).toFixed(2)}
                )`
                  : ""}
              </Button>

              {codChargeTo === "customer" ? (
                <Typography
                  component="span"
                  fontSize={{ xs: 13, md: 13 }}
                  fontWeight={500}
                  color={orangeColor01}
                  textAlign="center"
                  display={{ xs: "none", md: "block" }}
                >
                  (COD additional charge ₹{codCost})
                </Typography>
              ) : null}
            </>
          ) : null}
        </Grid>
      </Grid>

      <ModalCard
        handleClose={() => setWorkingHourPopup(false)}
        open={workingHourPopup}
        width="28%"
      >
        <WorkingHoursCard
          title="Working Hours"
          workigHourData={overviewDetails?.businessHours}
        />
      </ModalCard>

      <ModalCard1
        handleClose={() => {
          getFinboxBannerData();
          setFinvervModal({ show: false, url: "" });
        }}
        open={finvervModal.show}
        width="30%"
        height="80vh"
      >
        <Box
          width="100%"
          minHeight="600px"
          sx={{ borderRadius: "12px", backgroundColor: whiteColor }}
        >
          {/* <Box p={1} display='flex' justifyContent={'center'}>
            <Box
              component='img'
              src={'https://storage.googleapis.com/bodefaults/shopweb/logo_v3.png'}
              alt='CostBo'
              height='40px'
            />
          </Box> */}

          <iframe
            src={finvervModal?.url?.slice(0, -5) + "true"}
            style={{
              border: "none",
              borderRadius: "12px",
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              width: "100%",
              height: "100%",
            }}
          />
          {/* <iframe  src={finvervModal.url} width={'100%'} height={'550px'} /> */}
        </Box>
      </ModalCard1>

      <ModalCard1
        handleClose={() => {
          getFinvervLinesData();
          setFinvervModal1({ show: false, url: "" });
        }}
        open={finvervModal1.show}
        width="30%"
        height="80vh"
      >
        <Box
          width="100%"
          minHeight="600px"
          sx={{ borderRadius: "12px", backgroundColor: whiteColor }}
        >
          <iframe
            src={finvervModal1.url}
            style={{
              border: "none",
              borderRadius: "12px",
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              width: "100%",
              height: "100%",
            }}
          />
        </Box>
      </ModalCard1>

      <ModalCard
        handleClose={() => {
          setOtpModal(false);
          setFinboxLoading(false);
        }}
        open={otpModal}
        width="27%"
      >
        <OtpCard
          phoneNumber={getPhoneNumber()}
          onSuccess={(token) => {
            setOtpModal(false);
            placeFinboxOrder(token);
          }}
        />
      </ModalCard>

      {/* <AlertDialog
        content={alertMsg}
        isOpen={alertOpen}
        onClose={() => {
          setAlertOpen(false);
          if (alertMsg == unicomInventoryErrMsg) {
            scrolltoCartItems();
            console.log("unicom_scroll", { unicomErrRef });
            // unicomErrRef?.current?.scrollIntoView({ behavior: 'smooth' });
          }
        }}
      /> */}

      <AlertModal
        open={alertOpen}
        content={alertMsg}
        btnName="OK"
        handleConfirm={() => {
          setAlertOpen(false);
          if (alertMsg == unicomInventoryErrMsg) {
            scrolltoCartItems();
          }
        }}
        hideCancel
        handleClose={() => {
          setAlertOpen(false);
          if (alertMsg == unicomInventoryErrMsg) {
            scrolltoCartItems();
          }
        }}
      />

      <AlertModal
        open={openConfirmAlert?.isOpen}
        content={openConfirmAlert.message}
        handleConfirm={() => {
          onPaycartPaymentLink();
        }}
        handleClose={() => {
          setOpenConfirmAlert({ isOpen: false, message: "" });
        }}
      />
      <AlertModal
        open={codConfirmAlert}
        content="Cash to pay upon delivery of your items"
        handleConfirm={() => {
          if (codLoading) return;
          placeCODOrder();
        }}
        handleClose={() => {
          setCodConfirmAlert(false);
        }}
        amt={(
          Number(
            calculateOrderTotalCost(
              cartDetails?.orderTotalProductCost,
              discountAmount,
              shipmentAmount
            )
          ) + Number(codChargeTo === "customer" ? codCost : 0)
        ).toFixed(2)}
        msg={
          codChargeTo === "customer" ? `COD additional charge ₹${codCost}` : ""
        }
      />

      {/* <ConfirmAlert
        isOpen={openConfirmAlert?.isOpen}
        content={openConfirmAlert.message}
        onCancel={() => {
          setOpenConfirmAlert({ isOpen: false, message: "" });
        }}
        onConfirm={() => {
          onPaycartPaymentLink();
        }}
      />

      <CODConfirmationAlert
        content="Cash to pay upon delivery of your items"
        isOpen={codConfirmAlert}
        onClose={() => {
          setCodConfirmAlert(false);
        }}
        onConfirm={() => {
          if (codLoading) return;
          placeCODOrder();
        }}
        codCost={codCost}
        orderCost={(
          Number(
            calculateOrderTotalCost(
              cartDetails?.orderTotalProductCost,
              discountAmount,
              shipmentAmount
            )
          ) + Number(codChargeTo === "customer" ? codCost : 0)
        ).toFixed(2)}
      /> */}
    </Container>
  );
}

export default DiscountDeliveryContent;

const SummaryDataRow = ({ label, value, isValueGreen }) => {
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      py={{ md: 0.2, xs: 0 }}
    >
      <Grid item>
        <Typography
          variant="subtitle1"
          component="p"
          fontSize={{ md: "12.5px", xs: 12 }}
          lineHeight={1.5}
          fontWeight={500}
          color={{ md: blackColor, xs: blackColor }}
          display="inline"
        >
          {label}
        </Typography>
      </Grid>

      <Grid item>
        <Typography
          variant="h6"
          component="p"
          fontSize={{ md: "12.5px", xs: 12 }}
          lineHeight={1.5}
          fontWeight={600}
          color={
            isValueGreen ? { md: greenColor04, xs: lightGreen03 } : blackColor
          }
          display="inline"
        >
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};

const styles = {
  cartContainer: {
    // boxShadow: '0 4px 8px rgb(0 0 0 / 6%)',
    px: 1.5,
    py: { md: 1, xs: 0 },
    borderRadius: 2.2,
    border: "1px solid" + borderLineColor,
    backgroundColor: { md: whiteColor, xs: whiteColor },
    mb: "15px",
    mt: "6px",
  },

  checkoutBtn: (disabled) => ({
    fontSize: "14px",
    fontWeight: 600,
    color: whiteColor,
    my: 0.5,
    display: { xs: "none !important", md: "inline-flex !important" },
    border: disabled
      ? "0px solid " + orangeColor01
      : "2px solid " + orangeColor01,
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: whiteColor,
      color: orangeColor01,
    },
  }),

  checkoutCodBtn: {
    display: { xs: "none", md: "block" },
    fontSize: "14px",
    fontWeight: 600,
    color: whiteColor,
    backgroundColor: secondaryColor,
    my: 0.5,
    border: "2px solid " + secondaryColor,
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: whiteColor,
      color: secondaryColor,
    },
  },

  inputBox: {
    border: borderTextbox,
    borderRadius: "6px",
    fontSize: "13px",
    color: blackColor,
    background: whiteColor,
    padding: "8px 10px",
    width: "100%",
    marginTop: "4px",
    fontFamily: "inherit",
  },

  paycartLinkBtn: (disabled) => ({
    fontSize: "14px",
    fontWeight: 600,
    color: whiteColor,
    backgroundColor: blackShade8,
    my: 0.5,
    display: { xs: "none !important", md: "inline-flex !important" },
    border: disabled ? "0px solid " + blackShade8 : "2px solid " + blackShade8,
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: whiteColor,
      color: blackShade8,
    },
  }),
  actionBtn: {
    backgroundColor: whiteColor,
    color: blackColor,
    borderRadius: "6px",
    fontWeight: 600,
    fontSize: "12px",
    border: `1px solid ${blackColor}`,
    width: "40%",
    marginTop: "0px",
    padding: "4px 12px",
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: blackColor,
      color: whiteColor,
    },
  },
};

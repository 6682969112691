import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAccountDetails,
  getAddress,
  getCashbackAvailabel,
  getOrdersCount,
} from "redux/dashboard/dashboardSlice";
import FooterMui from "../components/FooterMui";
import DashboardContent from "./Dashboard/DashboardContent";
import { useNavigate, useParams } from "react-router-dom";
import { getUserName } from "utils/authHelpers";
import TopComponent from "./TopComponent";
import { Box, Container, useMediaQuery } from "@mui/material";
import BreadcrumbsSection from "components/Breadcrumbs";
import {
  backgroundColor01,
  greenColor01,
  whiteColor,
} from "configs/styles/muiThemes";
import { getBusinessIdDetails } from "redux/rootSlice/RootSlice";
import { getCartDetails } from "redux/addToCart/AddToCartSlice";
import { getCommercialDetails } from "redux/productDescription/ProductDescriptionSlice";
import HeaderLoader from "components/skeletonLoaders/HeaderLoader";
import DashboardMobileLoader from "components/skeletonLoaders/DashboardMobileLoader";
import { navigateTo, resolveDomainUrl } from 'utils/urlHelper'

function Dashboard({isDomainUrl}) {
  const { businessName } = useParams();

  const businessIdData = sessionStorage.getItem("businessId");

  const [selectedAddress, setSelectedAddress] = useState(null);
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const dispatch = useDispatch();
  const {
    accountDetails,
    orderDetails,
    openOrderDetails,
    cashbackData,
    allAddress,
    ordersCountData,
    loading,
  } = useSelector((state) => state.dashboard);

  let navigate = useNavigate();

  const { businessId } = useSelector((state) => state.rootSlice);
  const [isLoggedIn, setIsLoggedIn] = useState(
    getUserName().length === 0 ? false : true
  );

  const { commercialDetails } = useSelector((state) => state.product);
  const { cartDetails } = useSelector((state) => state.cart);

  useEffect(() => {
    if (businessId) {
      if (isLoggedIn) {
        if (!accountDetails) {
          dispatch(getAccountDetails());
        }
        dispatch(getOrdersCount(businessId?.businessId));
        // dispatch(getAllOrderDetails(businessId))
        // dispatch(getAllOpenOrderDetails(businessId))
        dispatch(getCashbackAvailabel(businessId?.businessId));
        dispatch(getAddress());
      } else {
        navigateTo(navigate, '')
      }
    }
  }, [businessId]);

  useEffect(() => {
    allAddress?.details?.forEach((item, index) => {
      if (item.selected) {
        setSelectedAddress(item);
      }
    });
  }, [allAddress]);

  const getBusinessData = () => {
    const localBusinessName = sessionStorage.getItem("businessName");
    if (businessName !== localBusinessName) {
      sessionStorage.setItem("cartData", "null");
    }

    if (!businessId || businessName !== localBusinessName)
      dispatch(getBusinessIdDetails(businessName));
    sessionStorage.setItem("businessName", businessName);
  };

  useEffect(() => {
    if (isDomainUrl) {
      resolveDomainUrl(dispatch);
      sessionStorage.setItem("businessName", businessName);
      return;
    }
    getBusinessData();
  }, [businessName]);

  useEffect(() => {
    if (businessIdData !== null) {
      if (!commercialDetails) {
        dispatch(getCommercialDetails());
      }
      // if (isLoggedIn) {
      //   if (!cartDetails) {
      //     dispatch(getCartDetails(businessId));
      //   }
      // }
    }
  }, [businessId]);

  return (
    <Box backgroundColor={backgroundColor01}>
      {/* <Navbar value='' /> */}
      <Box
        sx={{
          position: "sticky",
          top: 0,
          width: "100%",
          zIndex: 100,
          backgroundColor: { xs: whiteColor, md: backgroundColor01 },
          pb: { xs: 0, md: 1 },
        }}
      >
        <Box minHeight={{ xs: "auto", md: "126.7px" }} bgcolor={whiteColor}>
          {businessId !== null &&
          businessIdData !== null &&
          commercialDetails ? (
            <TopComponent value="dashboard" disableBanners />
          ) : (
            <HeaderLoader hideBanners />
          )}
        </Box>

        {matches && (
          <Container
            sx={{
              maxWidth: "1350px !important",
              mt: { xs: 0, md: "15px" },
              paddingX: "15px !important",
            }}
          >
            <BreadcrumbsSection
              links={[{ label: "Dashboard", to: `/Dashboard` }]}
            />
          </Container>
        )}
      </Box>

      {matches || commercialDetails ? (
        <Container
          sx={{
            maxWidth: "1350px !important",
            mt: { xs: 0, md: 0 },
            paddingX: { xs: "0px !important", md: "15px !important" },
          }}
        >
          <DashboardContent
            accountDetails={accountDetails}
            orderDetails={orderDetails}
            openOrderDetails={openOrderDetails}
            cashbackData={cashbackData}
            loading={loading}
            businessId={businessId}
            address={selectedAddress}
            ordersCountData={ordersCountData}
          />
        </Container>
      ) : (
        <DashboardMobileLoader />
      )}

      {matches && <FooterMui />}
    </Box>
  );
}

export default Dashboard;

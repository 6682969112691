import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import ModalCard from "components/ModalCard";
import {
  blackColor,
  discountPercentageColor,
  headingColor,
  whiteColor,
} from "configs/styles/muiThemes";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { validateCoupon } from "services/api_calls/checkout/checkoutApi";

function OfferAppliedPopupModal({ data, open, onClose }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const { businessDetails } = useSelector((state) => state.header);

  return (
    <ModalCard
      handleClose={() => {
        onClose();
      }}
      open={open}
      width={matches ? "28%" : "80%"}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: whiteColor,
          borderRadius: "15px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background:
              "url(https://storage.googleapis.com/bodefaults/shopweb/special-offer-wave-bg.jpg) no-repeat",
            padding: { xs: "12px", md: "15px" },
            borderRadius: "15px 15px 0 0",
          }}
        >
          <Box
            component="img"
            src={businessDetails?.businessInfo?.[0]?.logoURL}
            alt="business logo"
            style={styles.brandLogo}
          />
          <Typography
            variant="h4"
            component="h4"
            fontSize={{ xs: 14, md: 16 }}
            color={headingColor}
          >
            Congrats!
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            p: { xs: "6px !important", md: "15px" },
          }}
        >
          <Typography
            variant="h4"
            component="h2"
            fontSize={{ xs: 16, md: 16 }}
            fontWeight="600"
            color={headingColor}
            lineHeight="32px"
            margin={{ xs: "3px 0 0 !important", md: "4px !important" }}
          >
            You saved ₹{Number(data?.discountAmt).toFixed(2)}
          </Typography>

          <Typography
            variant="body1"
            component="p"
            fontSize={{ xs: 13, md: 14 }}
            fontWeight="500"
            lineHeight="25px"
            color="#ff6e3b"
            sx={{ textDecoration: "underline" }}
          >
            on this order!
          </Typography>

          <Typography
            variant="h4"
            component="h6"
            fontSize={{ xs: 13, md: 14 }}
            fontWeight="600"
            color={headingColor}
            m={{ xs: "3px 0 0px 0 !important", md: "4px 0 0px 0 !important" }}
            textAlign="center"
            lineHeight={{xs:'28px', md:"32px"}}
          >
            Special discount of{" "}
            <span style={{ fontSize: "13px", color: discountPercentageColor }}>
              {data?.discountPercentage}% applied
            </span>
          </Typography>

          {/* <Typography
            variant='h4'
            component='h5'
            fontSize={{ xs: 13, md: 13.5 }}
            color='#909090'
            fontWeight='500'
            lineHeight='20px'
            textAlign='center'
            m='0 0 5px 0 !important'
          >
            Offer promoted by our star affiliate{' '}
            <span style={{fontWeight:'600'}}>{data?.resellerName?.slice(0, 1)?.toUpperCase() +
              data?.resellerName?.slice(1)}</span>
          </Typography> */}

          <Button
            variant="contained"
            disableElevation
            sx={{
              m: "15px 0 15px",
              width: "54%",
              fontSize:{xs:'13px', md:'14px'},
              m: matches ? "20px 0 " : "10px 0px 14px",
              backgroundColor: blackColor,
              color: whiteColor,
              border: "1px solid" + blackColor,
              padding: "6px 20px",
              textTransform: "uppercase",
              border: `1px solid ${blackColor}`,
              "&:hover": {
                backgroundColor: whiteColor,
                color: blackColor,
              },
            }}
            onClick={() => {
              onClose();
            }}
          >
            CONTINUE
          </Button>
        </Box>
      </Box>
    </ModalCard>
  );
}

export default OfferAppliedPopupModal;

const styles = {
  brandLogo: {
    width: "55px",
    height: "auto",
    borderRadius: "8px",
    border: "1px solid #e7eaef",
    marginBottom: "10px",
  },

  couponCode: {
    border: "1.5px dashed #01a4b5",
    borderRadius: "5px",
    background: "#f9feff",
    padding: "3px 12px",
    color: "#01a4b5",
    fontSize: "13px",
    fontWeight: "600",
    margin: "0px 8px",
  },
};

import {
  Box,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ModalCard from "components/ModalCard";
import {
  backgroundColor,
  blackColor,
  headingColor,
  orangeColor01,
  whiteColor,
} from "configs/styles/muiThemes";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  validateCoupon,
  validateCouponAtag,
} from "services/api_calls/checkout/checkoutApi";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CloseIcon from "@mui/icons-material/Close";

function OfferPopupModal({ couponCode }) {
  const matches = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const { businessDetails } = useSelector((state) => state.header);

  const [couponData, setCouponData] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    validateCouponCode();
    return () => {};
  }, []);

  const validateCouponCode = async () => {
    const response = await validateCouponAtag(couponCode?.toLowerCase());

    if (response?.reseller) {
      sessionStorage.setItem(
        "specialOfferData",
        JSON.stringify(response?.reseller)
      );
      setCouponData(response?.reseller);
      setShowPopup(true);
    }
  };

  return (
    <ModalCard
      // handleClose={() => {
      //   setShowPopup(false);
      // }}
      open={showPopup}
      width={matches ? "35%" : "80%"}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: whiteColor,
          borderRadius: "15px",
          position: "relative",
        }}
      >
        <Box sx={{ width: "100%", height: "100%", position: "relative" }}>
          <IconButton
            sx={{
              // background: whiteColor,
              position: "absolute",
              top: "10px",
              right: "10px",
              width: "30px",
              height: "30px",
              opacity: 1,
              borderRadius: "50px",
              zIndex: "50 !important",
              // "&:hover": {
              //   background: whiteColor,
              // },
            }}
            onClick={() => {
              setShowPopup(false);
            }}
          >
            <CloseIcon sx={{ fontSize: 18, color: blackColor }} />
          </IconButton>
          {/* {props.children} */}
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background:
              "url(https://storage.googleapis.com/bodefaults/shopweb/special-offer-wave-bg.jpg) no-repeat",
            padding: "15px",
            borderRadius: "15px 15px 0 0",
          }}
        >
          <Box
            component="img"
            src={businessDetails?.businessInfo?.[0]?.logoURL}
            alt="business logo"
            // style={styles.brandLogo}
            // style={styles.brandLogo}
            sx={{
              width: { md: "58px", xs: "44px" },
              height: { md: "58px", xs: "44px" },
              borderRadius: "8px",
              border: "1px solid #e7eaef",
              marginBottom: "10px",
              padding: { md: "5px", xs: 0 },
              backgroundColor: whiteColor,
            }}
          />
          <Typography
            variant="h4"
            component="h4"
            fontSize={{ xs: 15, md: 16 }}
            color={headingColor}
            lineHeight={{ md: "28px", xs: "18px" }}
          >
            {couponData?.discountPercentage === 0
              ? "Buy directly from us!"
              : "Special offer exclusively for you!"}
          </Typography>
        </Box>

        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: matches ? "10px 18px" : `2px 8px`,
            // padding: {md:"10px 18px",xs:`2px 8px`}
          }}
        >
          {couponData?.discountPercentage !== 0 ? (
            <>
              <Typography
                variant="h4"
                component="h2"
                fontSize={{ xs: 16, md: 22 }}
                fontWeight="700"
                color={blackColor}
                lineHeight={{ md: "32px", xs: "28px" }}
                marginY={{ md: "12px !important", xs: "8px !important" }}
              >
                GET {couponData?.discountPercentage}% OFF
              </Typography>

              <Typography
                variant="body1"
                component="p"
                fontSize={{ xs: 13.5, md: 15.5 }}
                fontWeight="500"
                lineHeight="25px"
                color={orangeColor01}
                sx={{ textDecoration: "underline" }}
              >
                on this order!
              </Typography>
              <Box
                display={"flex"}
                m={{
                  md: "20px 0 10px 0 !important",
                  xs: "10px 0 5px 0 !important",
                }}
                lineHeight={matches ? "32px" : "28px"}
              >
                <Typography
                  variant="h4"
                  component="h6"
                  fontSize={{ xs: 13, md: 15 }}
                  color={headingColor}
                  textAlign="center"
                  lineHeight={matches ? "32px" : "28px"}
                >
                  Use coupon code{" "}
                  {/* <span
                    style={{
                      borderRadius: "7px",
                      padding: "4px 15px",
                      color: "#10847e",
                      fontSize: { md: "13.5px !important", xs: 12 },
                      fontWeight: 600,
                      margin: "0px 4px",
                      // display: "flex", // Add this if using justifyContent
                      // justifyContent: "center",
                      background: "#e5f9f9",
                      border: "1px dashed #00B293",
                    }}
                  >
                    {couponData?.resellerCode}
                  </span>{" "}
                  on checkout */}
                </Typography>
                <Typography
                  sx={{
                    borderRadius: "7px",
                    padding: matches ? "4px 15px" : "2px 10px",
                    color: "#10847e",
                    fontSize: { md: "13.5px !important", xs: 12 },
                    fontWeight: 600,
                    margin: "0px 4px",
                    // display: "flex", // Add this if using justifyContent
                    // justifyContent: "center",
                    background: "#e5f9f9",
                    border: "1px dashed #00B293",
                  }}
                  textAlign="center"
                  // lineHeight="32px"
                >
                  {couponData?.resellerCode}
                  {/* <ContentCopyIcon
                    // onClick={() => handleCopyLink()}
                    sx={{
                      cursor: "pointer",
                      fontSize: { md: 18, xs: 16 },
                    }}
                  /> */}
                </Typography>
                <Typography
                  variant="h4"
                  component="h6"
                  fontSize={{ xs: 13, md: 15 }}
                  color={headingColor}
                  textAlign="center"
                  lineHeight="32px"
                >
                  on checkout
                </Typography>
              </Box>

              <Typography
                variant="h4"
                component="h5"
                fontSize={{ xs: 12.5, md: 13.5 }}
                color={blackColor}
                fontWeight="500"
                lineHeight="20px"
                textAlign="center"
                m={{ md: "0 0 5px 0 !important", xs: 0 }}
              >
                Offer promoted by our star affiliate{" "}
                <span style={{ color: orangeColor01, fontWeight: 600 }}>
                  {couponData?.resellerName?.slice(0, 1)?.toUpperCase() +
                    couponData?.resellerName?.slice(1)}
                </span>
              </Typography>

              <Button
                // variant="contained"
                // disableElevation
                onClick={() => {
                  setShowPopup(false);
                }}
                sx={{
                  m: "15px 0 15px",
                  width: "54%",
                  fontSize:{xs:'13px', md:'14px'},
                  m: matches ? "20px 0 " : "10px 0px 14px",
                  backgroundColor: blackColor,
                  color: whiteColor,
                  border: "1px solid" + blackColor,
                  padding: "6px 20px",
                  textTransform: "uppercase",
                  border: `1px solid ${blackColor}`,
                  "&:hover": {
                    backgroundColor: whiteColor,
                    color: blackColor,
                  },
                }}
              >
                Grab This Deal
              </Button>
            </>
          ) : (
            <>
              <Typography
                variant="h4"
                component="h2"
                fontSize={22}
                fontWeight="700"
                color={headingColor}
                lineHeight="32px"
                margin="14px 14px 10px 14px !important"
              >
                ENJOY LOWEST PRICES!
              </Typography>

              <Typography
                variant="body1"
                component="p"
                fontSize={14}
                fontWeight="500"
                lineHeight="24px"
                color="#ff6e3b"
                sx={{ textDecoration: "underline" }}
              >
                Great savings! On time shipping
              </Typography>

              <Button
                variant="contained"
                disableElevation
                color="secondary"
                sx={{ m: "20px 0 15px" }}
                onClick={() => {
                  setShowPopup(false);
                }}
                textTransform={"uppercase"}
              >
                CONTINUE SHOPPING
              </Button>
            </>
          )}
        </Box>
      </Box>
    </ModalCard>
  );
}

export default OfferPopupModal;

const styles = {
  brandLogo: {
    width: { md: "58px", xs: "38px" },
    height: "auto",
    borderRadius: "8px",
    border: "1px solid #e7eaef",
    marginBottom: "10px",
    padding: "5px",
    backgroundColor: whiteColor,
  },

  couponCode: {
    // border: '1.5px dashed #01a4b5',
    // borderRadius: '5px',
    // background: '#f9feff',
    // padding: '3px 12px',
    // color: '#01a4b5',
    // fontSize: '13px',
    // fontWeight: '600',
    // margin: '0px 8px',
    // position: "relative",
    borderRadius: "7px",
    padding: "4px 15px",
    color: "#10847e",
    fontSize: { md: "13.5px !important", xs: 12 },
    fontWeight: 600,
    margin: "0px 4px",
    // display: "flex", // Add this if using justifyContent
    // justifyContent: "center",
    background: "#e5f9f9",
    border: "1px dashed #00B293",
  },
};
